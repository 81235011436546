import { GameState, PlayerData, RaceTurnStates, ScoreUpdateCategory, TurnState } from '../../Types';
import { CtxWithEffects as Ctx } from '../../effects-config';
import { logAndDisplay } from '../../Game';
import { addScore } from '../../utils';

export function setLogCarEffect(G: GameState, ctx: Ctx) {
  const { currentPlayer: currentPlayerID } = ctx;
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const currentPlayer: PlayerData = playersData[currentPlayerID];

  const playerIdToLifeMap = {};

  for (const playerData of playersData) {
    if (playerData.playerID !== Number(currentPlayerID)) {
      playerIdToLifeMap[playerData.playerID.toString()] = playerData.life;
    }
  }
  currentPlayer.actionAttributes.logCar = playerIdToLifeMap;
  currentPlayer.turnState.pendingActions.push({
    type: RaceTurnStates.OnTurnEnd,
    name: 'applyLogCarReward',
    priority: 1,
  });
}

export function applyLogCarReward(G: GameState, ctx: Ctx) {
  const { currentPlayer: currentPlayerID } = ctx;
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const currentPlayer = playersData[currentPlayerID];
  const playerIdToLifeMap = currentPlayer.actionAttributes.logCar;

  let totalDamage = 0;
  for (const playerData of playersData) {
    const playerId: string = playerData.playerID.toString();

    if (playerId !== currentPlayerID) {
      totalDamage += playerIdToLifeMap[playerId] - playersData[playerId].life;
    }
  }
  if (totalDamage > 0) {
    logAndDisplay(ctx, `Log car rewarded with $${totalDamage}`);
    addScore(G, ctx, currentPlayerID, totalDamage, ScoreUpdateCategory.Card, { cardId: 'log_car' });
  }
  currentPlayer.actionAttributes.logCar = {};
}
