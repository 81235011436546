import { DraftItemDriverType, DraftItemHatType, DraftItemType, DraftItemVehicleType } from './Types';

const HATS_CARDS_ARRAY_PROTO: DraftItemHatType[] = [
  {
    Type: 'Hat',
    Name: 'Funny Candles',
    ID: 'funny_candles',
    Rarity: 2,
    Power: 'If wrecked: 10 damage to racers in your square, and 5 to neighboring spaces.',
    Power_Unity: 'If wrecked: 10<style=damage> to racers in your space, and 5<style=damage>to neighbors.',
    Actions: [],
  },
  {
    Type: 'Hat',
    Name: 'Fuzzy Dice',
    ID: 'fuzzy_dice',
    Rarity: 2,
    Power: '3,4: May lose 1 life to move +- 1 space.',
    Power_Unity: '<style=dice> 3,4:  May lose 1 <style=life> to move +-1 space.',
    Actions: [{ name: 'fuzzyDiceEffect', type: 'beforeMoving' }],
  },
  {
    Type: 'Hat',
    Name: 'Golden Backpack',
    ID: 'golden_backpack',
    Rarity: 1,
    Power: 'Gain 3$. speed -1 movement.',
    Power_Unity: 'Gain 3<style=coin>. Permenant-1<style=movement> to race.',
    Actions: [
      { name: 'goldenBackpackGoldEffect', type: 'onRoundBegin' },
      { name: 'goldenBackpackMovementEffect', type: 'afterRoll' },
    ],
  },
  // {
  //   Type: 'Hat',
  //   Name: 'Spotlight',
  //   ID: 'spotlight',
  //   Rarity: 2,
  //   Power: 'Your space has  Bump +1 for others.',
  //   Power_Unity: 'Your space has Bump +1 for others.',
  //   Actions: [
  //     { name: 'setSpotlight', type: 'onTurnEnd' },
  //     { name: 'removeSpotlight', type: 'onTurnBegin' },
  //   ],
  // },
  {
    Type: 'Hat',
    Name: 'Outlaw Bandanna',
    ID: 'outlaw_bandanna',
    Rarity: 3,
    Power: 'Deal double damage on your turn.',
    Power_Unity: 'Deal double <style=damage> on your turn.',
    Actions: [{ name: 'outlawBandannaEffect', type: 'onRoundBegin' }],
  },
  {
    Type: 'Hat',
    Name: '4 Leaf Clover',
    ID: 'leaf_clover',
    Rarity: 1,
    Power: 'Extra turn when you roll your lucky number!',
    Power_Unity: 'Extra turn when you roll your lucky number!',
    Actions: [{ name: 'pickALuckyNumber', type: 'onRoundBegin' }],
  },
  {
    Type: 'Hat',
    Name: 'Plague Doctor Hat',
    ID: 'plague_doctor_hat',
    Rarity: 2,
    Power: 'Players you attack get plagued (-1 life per turn).',
    Power_Unity: 'Players you attack get plagued(-1<style=life> per turn).',
    Actions: [{ name: 'infect', type: 'onLand' }],
  },
  {
    Type: 'Hat',
    Name: 'Freeze Ray',
    Rarity: 2,
    ID: 'freeze_ray',
    Power: 'When you attack someone they are stunned.',
    Power_Unity: 'Stun racers you attack.',
    Actions: [{ name: 'freeze', type: 'attackAction' }],
  },
  {
    Type: 'Hat',
    Name: 'Map',
    ID: 'map',
    Rarity: 3,
    Power: '1,2: You may lose 2 life to change your roll to 6. Can be used once per game.',
    Power_Unity:
      '<style=dice> 1,2: You may lose 2<style=life> to change your roll to 6<style=dice>. Can be used once per game.',
    Actions: [{ name: 'shortcut', type: 'beforeMoving', priority: 1 }],
  },
  {
    Type: 'Hat',
    Name: 'Propeller Hat',
    ID: 'propeller_hat',
    Rarity: 3,
    Power: 'Pass over occupied squares at no movement cost.',
    Power_Unity: 'Pass over occupied squares at no <style=movement> cost.',
    Actions: [{ name: 'propellerPassOver', type: 'passOver' }],
  },
  {
    Type: 'Hat',
    Name: 'Pet Cat',
    ID: 'pet_cat',
    Rarity: 1,
    Power: 'If at no more than half health, +2 movement.',
    Power_Unity: 'If at no more than half health, +2<style=movement>.',
    Actions: [{ name: 'scaredyCat', type: 'afterRoll' }],
  },

  {
    Type: 'Hat',
    Name: 'Bow-Tie',
    ID: 'bow_tie',
    Rarity: 1,
    Power: 'Gain 2$ each time you begin your turn in the lead.',
    Power_Unity: 'Gain +2<style=coin> each time you begin your turn in the lead.',
    Actions: [{ name: 'bowTieEffect', type: 'onTurnBegin' }],
  },
  {
    Type: 'Hat',
    Name: 'Keychain',
    ID: 'keychain',
    Rarity: 1,
    Power: 'Gain 5$ for each successful bet.',
    Power_Unity: 'Gain 5<style=coin> for each successful bet.',
    Actions: [{ name: 'pokerVisorCheck', type: 'onRoundEnd' }],
  },

  {
    Type: 'Hat',
    Name: 'Rose bouquet',
    ID: 'rose_bouquet',
    Rarity: 1,
    Power: '6$ if you win, 3$ if you get second.',
    Power_Unity: 'Gain 6<style=coin> if you win, 3<style=coin> if you get second',
    Actions: [{ name: 'bouquet', type: 'onRoundEnd' }],
  },
  {
    Type: 'Hat',
    Name: 'Bindle',
    ID: 'bindle',
    Rarity: 2,
    Power: 'Gain $1 each time you begin your turn in last place.',
    Power_Unity: 'Gain 1<style=coin>each time you begin your turn in last place.',
    Actions: [{ name: 'fallingBehind', type: 'onTurnBegin' }],
  },
  {
    Type: 'Hat',
    Name: 'Red Scarf',
    ID: 'red_scarf',
    Rarity: 2,
    Power: 'Gain +1 each time you begin your turn in last.',
    Power_Unity: 'Gain 1<style=movement> each time you begin your turn in last place.',
    Actions: [{ name: 'redScarfEffect', type: 'onTurnBegin' }],
  },
  {
    Type: 'Hat',
    Name: 'Shovel',
    ID: 'shovel',
    Rarity: 2,
    Power: 'Gain 4$ when an other racer is wrecked.',
    Power_Unity: 'Gain 4<style=coin> when a racer is wrecked.',
    Actions: [{ name: 'moneyFromTheGraves', type: 'onRoundEnd' }],
  },
  {
    Type: 'Hat',
    Name: 'Crystal Ball',
    ID: 'crystal_ball',
    Rarity: 2,
    Power: 'When the race starts, choose a place you will finish (including not finishing). If you are correct, +10$.',
    Power_Unity: 'Predict race outcome (incl. not finishing). If correct, win 10<style=coin>.',
    Actions: [{ name: 'pickAHuntSuperstition', type: 'onRoundBegin' }],
  },
];

const VEHICLES_CARDS_ARRAY_PROTO: DraftItemVehicleType[] = [
  {
    Type: 'Vehicle',
    Name: 'Log Car',
    ID: 'log_car',
    Life: 14,
    Rarity: 3,
    Power: 'Gain 1$ for each racer you attack.',
    Power_Unity: 'Gain 1<style=coin> for each racer you attack.',
    Actions: [
      { name: 'setLogCarEffect', type: 'onTurnBegin' },
      { name: 'setLogCarEffect', type: 'onRoundBegin' },
    ],
  },
  {
    Type: 'Vehicle',
    Name: 'Aircraft',
    ID: 'aircraft',
    Rarity: 2,
    Life: 9,
    Power: 'Drop a trap before moving. The trap  does 2 damage if landed on.',
    Power_Unity: 'Drop a trap before moving. Trap= 2<style=damage> if landed on.',
    Actions: [{ name: 'setTrapLayer', type: 'onTurnBegin' }],
  },
  {
    Type: 'Vehicle',
    Name: 'HoverBoard',
    ID: 'hover_board',
    Rarity: 2,
    Life: 5,
    Power: '+2 movement.',
    Power_Unity: '+2<style=movement> for the race.',
    Actions: [
      { name: 'hoverboardEffect', type: 'onTurnBegin' },
      { name: 'hoverboardEffect', type: 'onRoundBegin' },
    ],
  },
  {
    Type: 'Vehicle',
    Rarity: 2,
    Name: 'Ambulance',
    ID: 'ambulance',
    Life: 11,
    Power: 'For each wreck +4 movement.',
    Power_Unity: 'For each wreck +4<style=movement>.',
    Actions: [{ name: 'panic', type: 'afterRoll' }],
  },
  {
    Type: 'Vehicle',
    Rarity: 2,
    Name: 'Jet Car',
    ID: 'jet_car',
    Life: 11,
    Power: '5,6: May move triple. (Usable Once). After use, Speed -1.',
    Power_Unity: '<style=dice> 5,6: Move triple, but Speed -1 after use (1x)',
    Actions: [{ name: 'jetCarEffect', type: 'beforeMoving', priority: 0 }],
  },
  {
    Type: 'Vehicle',
    Name: 'UFO',
    ID: 'ufo',
    Life: 10,
    Rarity: 1,
    Power: ' Racers in front of you -1, behind you have +1.',
    Power_Unity: 'Racers in front of you -1<style=movement>. Behind you +1<style=movement>.',
    Actions: [{ name: 'magnet', type: 'onRoundBegin' }],
  },
  {
    Type: 'Vehicle',
    Name: 'Electric Car',
    ID: 'electric_car',
    Life: 9,
    Rarity: 3,
    Power: 'Racers you pass are stunned.',
    Power_Unity: 'Stun racers you pass.',
    Actions: [{ name: 'stunByTheGuitar', type: 'passOver' }],
  },
  {
    Type: 'Vehicle',
    Name: 'Mecha',
    ID: 'mecha',
    Life: 12,
    Rarity: 2,
    Power: ' Racers you attack are pushed back.',
    Power_Unity: 'Push back racers you attack.',
    Actions: [{ name: 'soBig', type: 'attackAction' }],
  },
  {
    Type: 'Vehicle',
    Name: 'Turtle Mount',
    ID: 'turtle_mount',
    Life: 14,
    Rarity: 2,
    Power: '-1 speed. +1$ when you pass another racer.',
    Power_Unity: '-1<style=movement>. +1<style=coin> when you pass a racer.',
    Actions: [
      { name: 'stepByStep', type: 'afterRoll' },
      { name: 'patienceIsAVirtue', type: 'passOver' },
    ],
  },
  {
    Type: 'Vehicle',
    Name: 'Elephant',
    ID: 'elephant',
    Life: 7,
    Rarity: 4,
    Power: 'May lose 1 life to deal attack damage and stun player you pass over.',
    Power_Unity: 'May lose 1<style=life> to deal <style=damage> and stun player you passs.',
    Actions: [{ name: 'elephantEffect', type: 'passOver' }],
  },
  {
    Type: 'Vehicle',
    Name: 'Nuclear Car',
    ID: 'nuclear_car',
    Life: 10,
    Rarity: 5,
    Power: 'Atttack cars passing you.',
    Power_Unity: 'Atttack passing racers.',
    Actions: [{ name: 'addNuclearCarCheck', type: 'onRoundBegin' }],
  },
  {
    Type: 'Vehicle',
    Name: 'Mechanical Spider',
    ID: 'mechanical_spider',
    Life: 14,
    Rarity: 3,
    Power: 'May lose 1 life to permanently reduce target speed by 1.',
    Power_Unity: 'May lose 1<style=life> to permanently reduce target <style=movement> by 1.',
    Actions: [{ name: 'spiderAttack', type: 'attackAction' }],
  },
  {
    Type: 'Vehicle',
    Name: 'Dragon',
    ID: 'dragon_mount',
    Life: 9,
    Rarity: 2,
    Power: 'Deal damage in the two spaces in front of you.',
    Power_Unity: 'Deal <style=damage> two spaces ahead.',
    Actions: [{ name: 'fireBreath', type: 'onLand' }],
  },
  {
    Type: 'Vehicle',
    Name: 'Low Rider',
    ID: 'low_rider',
    Life: 10,
    Rarity: 2,
    Power: 'Double race reward',
    Power_Unity: 'Double race reward',
    Actions: [],
  },
  {
    Type: 'Vehicle',
    Name: 'Bumper Car',
    ID: 'bumper_car',
    Life: 7,
    Rarity: 3,
    Power: 'When lands on a racer, all racers get knocked 1 space. Retrigger',
    Power_Unity: 'Bump all racers by 1 space when landing on tile. Retrigger.',
    Actions: [{ name: 'bump', type: 'onLand' }],
  },
  {
    Type: 'Vehicle',
    Name: 'Red Wagon',
    ID: 'red_wagon',
    Life: 8,
    Rarity: 2,
    Power: 'You may lose 1 life to tow and stun a racer.',
    Power_Unity: 'You may lose 1<style=life> to tow and stun a racer.',
    Actions: [{ name: 'tow', type: 'beforeMoving' }],
  },
  {
    Type: 'Vehicle',
    Name: 'Tooter',
    ID: 'tooter',
    Life: 8,
    Rarity: 1,
    Power: 'Racers 1 behind you move back 1-3 spaces.',
    Power_Unity: 'Racers 1 space behind you move back 1-3 spaces.',
    Actions: [{ name: 'smoke', type: 'onTurnBegin' }],
  },

  {
    Type: 'Vehicle',
    Name: 'Porcupine',
    ID: 'porcupine',
    Life: 6,
    Rarity: 2,
    Power: 'Racers that land in your space take damage.',
    Power_Unity: 'Racers that land in your space take <style=damage>.',
    Actions: [{ name: 'addPorcupineCheck', type: 'onRoundBegin' }],
  },
];

const DRIVERS_CARDS_ARRAY_PROTO: DraftItemDriverType[] = [
  {
    Type: 'Driver',
    Name: 'Gremlin',
    ID: 'gremlin',
    Attack: 2,
    Rarity: 2,
    Power: 'When another racer rolls a 1 you move one space.',
    Power_Unity: 'When a racer rolls a 1 you move one space.',
    Actions: [
      { name: 'setGremlinEffect', type: 'warmUp' },
      { name: 'regularAttack', type: 'attackAction' },
    ],
  },
  {
    Type: 'Driver',
    Name: 'Trooper',
    ID: 'trooper',
    Attack: 2,
    Rarity: 2,
    Power: 'All others are stunned after moving 6+ spaces.',
    Power_Unity: 'All others are stunned after moving 6+ spaces.',
    Actions: [
      { name: 'setTrooperEffect', type: 'warmUp' },
      { name: 'regularAttack', type: 'attackAction' },
    ],
  },
  {
    Type: 'Driver',
    Name: 'Cheerleader',
    ID: 'cheerleader',
    Attack: 1,
    Rarity: 2,
    Power: 'If you are in last when someone wins you get second place.',
    Power_Unity: 'If in last place gets second if someone else wins..',
    Actions: [
      { name: 'setCheerleader', type: 'onRoundBegin' },
      { name: 'regularAttack', type: 'attackAction' },
    ],
  },
  // {
  //   Type: 'Driver',
  //   Name: 'Shadow',
  //   ID: 'shadow',
  //
  //   Attack: 1,
  //   Rarity: 2,
  //   Power: 'When someone leaves your square you may move to their destination with them.',
  //   Power_Unity: 'Move to the destination of someone leaving your square.',
  //   Actions: [{ name: 'setShadowEffect', type: 'onTurnBegin' },
  //    { name: 'regularAttack', type: 'attackAction' }],
  // },
  {
    Type: 'Driver',
    Name: 'Hare',
    ID: 'hare',
    Rarity: 1,
    Attack: 1,
    Power: 'When ahead -2, otherwise +2.',
    Power_Unity: 'When ahead -2<style=movement>. Otherwise +2<style=movement>.',
    Actions: [
      { name: 'overConfidence', type: 'afterRoll' },
      { name: 'regularAttack', type: 'attackAction' },
    ],
  },
  {
    Type: 'Driver',
    Name: 'Wind God',
    ID: 'wind_god',
    Rarity: 1,
    Attack: 1,
    Power: '1: Other racers pushed 4 spaces back.',
    Power_Unity: '<style=dice> 1: Other racers pushed 4 spaces back.',
    Actions: [
      { name: 'wind', type: 'beforeMoving', priority: 0 },
      { name: 'regularAttack', type: 'attackAction' },
    ],
  },
  {
    Type: 'Driver',
    Name: 'Strategist',
    ID: 'strategist',
    Attack: 2,
    Rarity: 3,
    Power: '5,6: May lose 1 life to change your roll to a smaller roll.',
    Power_Unity: '<style=dice> 5,6: Spend 1 life to roll smaller.',
    Actions: [
      { name: 'plan', type: 'beforeMoving', priority: 0 },
      { name: 'regularAttack', type: 'attackAction' },
    ],
  },
  {
    Type: 'Driver',
    Name: 'Dancer',
    ID: 'dancer',
    Rarity: 2,
    Attack: 2,
    Power: '3,4: May lose 1 life to relocate and attack to the leader.',
    Power_Unity: '<style=dice> 3,4: Spend 1<style=life> to relocate and attack leader.',
    Actions: [
      { name: 'swordDance', type: 'beforeMoving' },
      { name: 'regularAttack', type: 'attackAction' },
    ],
  },

  {
    Type: 'Driver',
    Name: 'Achilles',
    ID: 'achilles',
    Rarity: 1,
    Attack: 2,
    Power: '1,2: You may lose 1 life to change die to 5.',
    Power_Unity: '<style=dice> 1,2: May lose 1<style=life> to roll a 5.',
    Actions: [
      { name: 'fleetFooted', type: 'beforeMoving' },
      { name: 'regularAttack', type: 'attackAction' },
    ],
  },

  {
    Type: 'Driver',
    Name: 'Ghost',
    ID: 'ghost',
    Rarity: 2,
    Attack: 2,
    Power: 'Stuns racers you attack.',
    Power_Unity: 'Stuns racers you attack.',
    Actions: [{ name: 'scare', type: 'attackAction' }],
  },
  {
    Type: 'Driver',
    Name: 'Duelist',
    ID: 'duelist',
    Rarity: 2,
    Attack: 2,
    Power: '1,2: +1 attack.',
    Power_Unity: '<style=dice> 1,2: +1<style=damage> attack.',
    Actions: [
      { name: 'duel', type: 'attackAction' },
      { name: 'duelCharge', type: 'beforeMoving' },
    ],
  },
  {
    Type: 'Driver',
    Name: 'Adventurer',
    ID: 'adventurer',
    Attack: 1,
    Rarity: 2,
    Power: '1,2: You may skip your turn and gain a permanent +2 movement.',
    Power_Unity: '<style=dice> 1,2: Skip turn, gain permanent +2<style=movement>',
    Actions: [
      { name: 'experienceCharge', type: 'beforeMoving', priority: 1 },
      { name: 'experienced', type: 'afterRoll' },
      { name: 'regularAttack', type: 'attackAction' },
    ],
  },
  {
    Type: 'Driver',
    Name: 'Robot',
    ID: 'robot',
    Rarity: 2,
    Attack: 1,
    Power: '1:  Another turn, and gain +1 for the race.',
    Power_Unity: '<style=dice> 1: Another turn and gain permenant +1<style=movement>.',
    Actions: [
      { name: 'bitPower', type: 'afterRoll' },
      { name: 'regularAttack', type: 'attackAction' },
    ],
  },
  {
    Type: 'Driver',
    Name: 'Rubber Girl',
    ID: 'rubber_girl',
    Rarity: 3,
    Attack: 2,
    Power: '1,2:  May lose 1 life for a Reroll.',
    Power_Unity: '<style=dice> 1,2: lose 1<style=life> to Reroll.',
    Actions: [{ name: 'rubberGirlEffect', type: 'beforeMoving' }],
  },
  {
    Type: 'Driver',
    Name: 'Ogre',
    ID: 'Ogre',
    Rarity: 3,
    Attack: 3,
    Power: 'Attacked racers knocked back 1-3 spaces.',
    Power_Unity: 'Attacked racers knocked back 1-3 spaces.',
    Actions: [{ name: 'thump', type: 'attackAction' }],
  },

  {
    Type: 'Driver',
    Name: 'Pirate',
    ID: 'pirate',
    Attack: 2,
    Rarity: 2,
    Power: 'Steal 1$ from each attacked.',
    Power_Unity: 'Steal 1<style=coin> from attacked racers.',
    Actions: [{ name: 'plunder', type: 'attackAction' }],
  },
  {
    Type: 'Driver',
    Name: 'Master of Disguise',
    ID: 'master_of_disguise',
    Attack: 1,
    Rarity: 5,
    Power: '3,4: May lose 1 life and trade places with another racer before you roll.',
    Power_Unity: '<style=dice> 3,4: Spend 1<style=life> to swap places before rolling.',
    Actions: [
      { name: 'masterOfDisguiseEffect', type: 'beforeMoving', priority: 0 },
      { name: 'regularAttack', type: 'attackAction' },
    ],
  },
  {
    Type: 'Driver',
    Name: 'Leprechaun',
    ID: 'leprechaun',
    Attack: 1,
    Rarity: 2,
    Actions: [
      { name: 'setLeprechaunTrigger', type: 'warmUp' },
      { name: 'regularAttack', type: 'attackAction' },
    ],
    Power: 'Players may reroll 1, then you have +3 on your next turn.',
    Power_Unity: 'When racers reroll 1, gain +3<style=movement> next turn',
  },
];

const MAP1_ARRAY = Array(20).fill({ Name: 'Tile', MovementCost: 1 });

const getCardByID = (id: AllCardIds): DraftItemType => {
  return (
    DRIVERS_CARDS_ARRAY_PROTO.find((card) => card.ID === id) ||
    VEHICLES_CARDS_ARRAY_PROTO.find((card) => card.ID === id) ||
    HATS_CARDS_ARRAY_PROTO.find((card) => card.ID === id)
  );
};

const tutorialCardIdsPerPlayer: AllCardIds[][] = [
  // player 0
  ['hare', 'rubber_girl', 'elephant', 'low_rider', 'propeller_hat', 'keychain'],
  // player 1
  ['Ogre', 'strategist', 'outlaw_bandanna', 'mecha', 'tooter', 'shovel'],
  // player 2
  ['cheerleader', 'gremlin', 'turtle_mount', 'log_car', 'golden_backpack', 'crystal_ball'],
  // player 3
  ['dancer', 'duelist', 'hover_board', 'mechanical_spider', 'map', 'freeze_ray'],
  // player 4
  ['achilles', 'robot', 'low_rider', 'jet_car', 'leaf_clover', 'bow_tie'],
  // player 5
  ['ghost', 'adventurer', 'dragon_mount', 'electric_car', 'fuzzy_dice', 'shovel'],
];
export const TUTORIAL_HANDS: DraftItemType[][] = tutorialCardIdsPerPlayer.map((playerCardIds) =>
  playerCardIds.map(getCardByID)
);

function pickNfromArrayWithReplacement<P>(arr: Array<P>, n: number): Array<P> {
  return Array.from({ length: n }, () => {
    return arr[Math.floor(Math.random() * arr.length)];
  });
}

function includeAllThenPickRandom<P>(arr: Array<P>, n: number): Array<P> {
  // If we're picking more than there are, first include all members
  if (n > arr.length) {
    return [...arr, ...pickNfromArrayWithReplacement(arr, n - arr.length)];
  }
  // otherwise just take the first n -- this is for debugging right now
  return arr.slice(0, n);
}

// Allows us to limit which cards are used with CLI args
const [driversStart, driversStop, hatsStart, hatsStop, vehiclesStart, vehiclesStop] = process.argv
  .slice(2, 8)
  .map((arg) => (Number.isInteger(arg) ? +arg : undefined));
console.log({ driversStart, driversStop, hatsStart, hatsStop, vehiclesStart, vehiclesStop });

export const DRIVERS_CARDS: DraftItemDriverType[] = includeAllThenPickRandom(
  DRIVERS_CARDS_ARRAY_PROTO.slice(driversStart, driversStop),
  18
);
export const HATS_CARDS: DraftItemHatType[] = includeAllThenPickRandom(
  HATS_CARDS_ARRAY_PROTO.slice(hatsStart, hatsStop),
  18
);
export const VEHICLES_CARDS: DraftItemVehicleType[] = includeAllThenPickRandom(
  VEHICLES_CARDS_ARRAY_PROTO.slice(vehiclesStart, vehiclesStop),
  18
);

console.log({
  DRIVERS_CARDS: DRIVERS_CARDS.map((c) => c.Name),
  HATS_CARDS: HATS_CARDS.map((c) => c.Name),
  VEHICLES_CARDS: VEHICLES_CARDS.map((c) => c.Name),
});

export type AccessoryCardId =
  | 'leaf_clover'
  | 'fuzzy_dice'
  | 'funny_candles'
  | 'plague_doctor_hat'
  | 'freeze_ray'
  | 'golden_backpack'
  | 'outlaw_bandanna'
  | 'map'
  | 'propeller_hat'
  | 'pet_cat'
  | 'bow_tie'
  | 'keychain'
  | 'rose_bouquet'
  | 'bindle'
  | 'spotlight'
  | 'shovel'
  | 'crystal_ball'
  | 'red_scarf';

export type VehicleCardId =
  | 'hover_board'
  | 'log_car'
  | 'aircraft'
  | 'jet_car'
  | 'ambulance'
  | 'ufo'
  | 'electric_car'
  | 'mecha'
  | 'turtle_mount'
  | 'mechanical_spider'
  | 'dragon_mount'
  | 'low_rider'
  | 'bumper_car'
  | 'red_wagon'
  | 'elephant'
  | 'nuclear_car'
  | 'tooter'
  | 'porcupine';

export type DriverCardID =
  | 'gremlin'
  | 'cheerleader'
  | 'shadow'
  | 'hare'
  | 'rubber_girl'
  | 'wind_god'
  | 'strategist'
  | 'dancer'
  | 'achilles'
  | 'ghost'
  | 'trooper'
  | 'duelist'
  | 'adventurer'
  | 'robot'
  | 'Ogre'
  | 'pirate'
  | 'master_of_disguise'
  | 'leprechaun';

export type AllCardIds = AccessoryCardId | VehicleCardId | DriverCardID;

export const MAP1 = MAP1_ARRAY.map((tile, index) => {
  return { ...tile, id: index };
});
