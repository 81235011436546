import React, { useRef } from 'react';
import { DraftItems, DraftItemType, WrecklessBoardProps } from '@magicyard/wreckless-game/src/Types';
import styled from 'styled-components';

export const VerticalFlexItem = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  border-style: solid;
  width: 100%;
  font-size: 30px;
  border-color: ${(props) => (props.cardType === 'Driver' ? 'blue' : props.cardType === 'Hat' ? 'red' : 'green')};
`;

const FlexContainer = styled.div`
  padding: 20px;
  display: inline-flex;
`;

const ColumnTitle = styled.div`
  text-align: center;
  font-size: 30px;
`;

const VerticalFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin: 10px;
`;

const FlexItem = styled.div`
  margin-right: 10px;
  margin-left: 10px;
`;

const StyledTitle = styled.div`
  margin-top: 20px;
  margin-left: 20%;
  font-size: 40px;
  font-weight: bold;
`;

interface HandProps {
  hand: Array<DraftItemType>;
}

const CARD_ORDER: Record<DraftItems, number> = {
  Driver: 0,
  Vehicle: 1,
  Hat: 2,
};

const Hand = ({ hand }: HandProps) => {
  return (
    <VerticalFlexContainer>
      {[...hand]
        .sort((a, b) => CARD_ORDER[a.Type] - CARD_ORDER[b.Type])
        .map((card, i) => (
          <VerticalFlexItem key={`card-${i}`} cardType={card.Type}>
            {card.Name}
          </VerticalFlexItem>
        ))}
    </VerticalFlexContainer>
  );
};

const ORDINALS = {
  1: '1st',
  2: '2nd',
  3: '3rd',
  4: '4th',
  5: '5th',
  6: '6th',
};

const RaceValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 100px;
`;

const RaceValuesRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 40px;
`;

const RaceValuesTitle = styled.div`
  font-weight: bold;
  font-size: 45px;
  text-align: center;
`;

const RaceValues = (props: WrecklessBoardProps) => {
  const values = Array(props.ctx.numPlayers)
    .fill(0)
    .map((_, i) => i + 1)
    .map((i) => ({
      place: i,
      score: props.G.raceResultRewardByRound[props.G.currentRacingRound][i],
    }));

  return (
    <RaceValuesContainer>
      <RaceValuesTitle>Winnings</RaceValuesTitle>
      {values.map(({ place, score }, i) => (
        <RaceValuesRow key={`value-${i}`}>
          <div>{ORDINALS[place]}</div>
          <div>$ {score}</div>
        </RaceValuesRow>
      ))}
    </RaceValuesContainer>
  );
};

const RacerSelectPhaseBoard = (props: WrecklessBoardProps) => {
  const originalHands = useRef(props.G.hands);
  return (
    <>
      <StyledTitle>Select your racer!</StyledTitle>
      <FlexContainer>
        <RaceValues {...props} />

        {Array(props.ctx.numPlayers)
          .fill(0)
          .map((_, i) => (
            <FlexItem key={`column-${i}`}>
              <ColumnTitle>Player {i}</ColumnTitle>
              <Hand hand={originalHands.current[i]} />
            </FlexItem>
          ))}
      </FlexContainer>
    </>
  );
};

export default RacerSelectPhaseBoard;
