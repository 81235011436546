import React from 'react';
import { PlayerData, WrecklessBoardProps } from '@magicyard/wreckless-game/src/Types';
import styled from 'styled-components';

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface RacerProps {
  playerData: PlayerData;
  playerID: number;
}

const StyledRacer = styled.div`
  border-style: solid;
  padding: 10px;
  margin: 10px;
  width: 580px;
  height: 450px;
`;

const RacerHeaderItem = styled.div`
  text-align: center;
  font-size: 25px;
  margin: 10px;
  align-self: flex-end;
  margin-left: 20px;
  align-self: flex-end;
`;

const RacerHeaderItemTitle = styled.div`
  text-align: center;
  font-size: 35px;
  margin: 10px;
`;

const RacerHeaderContainer = styled.div`
  display: flex;
  width: 100%;
`;

const RacerBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RacerBodyItem = styled.div`
  margin: 5px;
  border-style: solid;
  border-width: 1px;
  padding: 4px;
`;

const RacerBodyItemTitle = styled.div`
  font-size: 25px;
`;

const RacerBodyItemDescription = styled.div`
  font-size: 20px;
`;

const Racer = ({ playerData, playerID }: RacerProps) => {
  return (
    <StyledRacer>
      <RacerHeaderContainer>
        <RacerHeaderItemTitle>Racer {playerID}</RacerHeaderItemTitle>
        <RacerHeaderItem>Life: {playerData.life}</RacerHeaderItem>
      </RacerHeaderContainer>
      <RacerBodyContainer>
        <RacerBodyItem>
          <RacerBodyItemTitle>
            {playerData.racer.driver.Name} <br />
          </RacerBodyItemTitle>
          <RacerBodyItemDescription>{playerData.racer.driver.Power}</RacerBodyItemDescription>
        </RacerBodyItem>
        <RacerBodyItem>
          <RacerBodyItemTitle>
            {playerData.racer.vehicle.Name} <br />
          </RacerBodyItemTitle>
          <RacerBodyItemDescription>{playerData.racer.vehicle.Power}</RacerBodyItemDescription>
        </RacerBodyItem>
        <RacerBodyItem>
          <RacerBodyItemTitle>
            {playerData.racer.hat.Name} <br />
          </RacerBodyItemTitle>
          <RacerBodyItemDescription>{playerData.racer.hat.Power}</RacerBodyItemDescription>
        </RacerBodyItem>
      </RacerBodyContainer>
    </StyledRacer>
  );
};

const BettingPhaseBoard = (props: WrecklessBoardProps) => {
  return (
    <>
      <FlexContainer>
        {props.G.roundPlayerData[props.G.currentRacingRound].map((playerData, i) => (
          <Racer key={`racer-${i}`} playerData={playerData} playerID={i} />
        ))}
      </FlexContainer>
    </>
  );
};

export default BettingPhaseBoard;
