import { GameState, RaceTurnStates } from '../../Types';
import { CtxWithEffects as Ctx } from '../../effects-config';
import { addPendingMove, getPlayerByDriverID, logAndDisplay, play, removePendingMove } from '../../Game';
import { move } from '../../utils';

export function setShadowEffect(G: GameState, ctx: Ctx): void {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  for (const playerData of playersData) {
    if (playerData.playerID !== Number(ctx.currentPlayer)) {
      playerData.actions.push({ name: 'shadowEffect', type: RaceTurnStates.OnLand });
    }
  }
}

export function shadowEffect(G: GameState, ctx: Ctx): void {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const currentPlayer = playersData[ctx.currentPlayer];
  const shadowPlayer = getPlayerByDriverID(playersData, 'shadow');

  if (
    shadowPlayer.isWrecked ||
    !!shadowPlayer.placeFinished ||
    shadowPlayer.locationOnMap !== currentPlayer.turnState.startingPoint
  ) {
    return;
  }

  shadowPlayer.actionAttributes.shadow = { triggeredOnPlayerID: ctx.currentPlayer };
  ctx.events.setActivePlayers([`${shadowPlayer.playerID}`]);
  addPendingMove('shadowMove', G, ctx, shadowPlayer.playerID);
}

export function shadowMove(G: GameState, ctx: Ctx, applyShadow: boolean): void {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const shadowPlayer = getPlayerByDriverID(playersData, 'shadow');
  const { triggeredOnPlayerID } = shadowPlayer.actionAttributes.shadow;

  if (applyShadow) {
    const triggeredOnPlayer = playersData[triggeredOnPlayerID];
    logAndDisplay(ctx, `Shadow driver choose to move to ${triggeredOnPlayer.locationOnMap}`);
    move(G, ctx, shadowPlayer, triggeredOnPlayer.locationOnMap);
    shadowPlayer.actionAttributes.shadow = {};
  }
  removePendingMove('shadowMove', shadowPlayer);
  ctx.events.setActivePlayers([triggeredOnPlayerID]);
  play(G, ctx);
}
