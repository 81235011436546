import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Client } from 'boardgame.io/react';
import Board from './components/Board';
import { getMultiplayerMode } from '@magicyard/utils';
import useForceScale from '@magicyard/shared/platform/hooks/useForceScale';
import { useFullscreen } from '@magicyard/shared/platform/hooks/useTryFullscreen';
import { WRECKLESS } from '@magicyard/wreckless-game/src/Game';

import { EffectsBoardWrapper } from 'bgio-effects/react';
const WrappedBoard = (matchID) =>
  EffectsBoardWrapper(
    (p) => {
      return <Board {...p} matchID={matchID} />;
    },
    { updateStateAfterEffects: true }
  );

const getDemoData = (): { matchId: string; displayId: string | null; serverURL: string | null } | null => {
  const params = new URLSearchParams(window.location.search);
  const matchId = params.get('matchID');
  const displayId = params.get('displayId');
  const serverURL = params.get('serverURL');
  if (matchId === null) {
    return null;
  }

  return { matchId: matchId, displayId: displayId, serverURL: serverURL };
};
const demoData = getDemoData();
console.log({ demoData });

if (demoData !== null) {
  const BgioClient = Client({
    //@ts-ignore TODO: Remove this and fix resulting type error
    game: WRECKLESS,
    board: WrappedBoard(demoData.matchId),
    debug: true,
    multiplayer: getMultiplayerMode(demoData.serverURL),
  });

  const DemoWrapper = () => {
    useFullscreen();
    useForceScale();

    return <BgioClient matchID={demoData.matchId} />;
  };

  ReactDOM.render(
    <React.StrictMode>
      <DemoWrapper />
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
