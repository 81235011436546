import { MoveEffect, TextEffect, ActionEffect } from './Types';
import { Ctx } from 'boardgame.io';
import { EffectsCtxMixin } from 'bgio-effects';

export const TEXT_DURATION = 0.3;
export const MOVE_DURATION = 0.1;
export const ACTION_DURATION = 0.1;

export const config = {
  effects: {
    endTurn: {},
    text: {
      create: (textEffect: TextEffect) => textEffect,
      duration: TEXT_DURATION,
    },
    move: {
      create: (moveEffect: MoveEffect) => moveEffect,
      duration: MOVE_DURATION,
    },
    action: {
      create: (actionEffect: ActionEffect) => actionEffect,
      duration: ACTION_DURATION,
    },
  },
};

export type CtxWithEffects = Ctx & EffectsCtxMixin<typeof config>;
