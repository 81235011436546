import React, { useEffect } from 'react';

import { GamePhase, WrecklessBoardProps } from '@magicyard/wreckless-game/src/Types';
import RacingPhaseBoard from './RacingPhaseBoard';
import BettingPhaseBoard from './BettingPhaseBoard';
import ScoringPhaseBoard from './ScoringPhaseBoard';
import DraftingPhaseBoard from './DraftingPhaseBoard';
import RacerSelectPhaseBoard from './RacerSelectPhaseBoard';
import MenuBoard from './MenuBoard';
import { CONFIG } from '@magicyard/shared/platform/utils/utils';

const Board = (props: WrecklessBoardProps & { matchID: string }) => {
  useEffect(() => {
    console.log('Got new state: ' + props._stateID);
    const unpauseURL = new URL(`/unpause?matchID=${props.matchID}`, `${CONFIG.GAME_START_SERVER_URL}`);
    fetch(unpauseURL)
      .then((response) => response.text())
      .then(console.log);
  }, [props._stateID]);
  return (
    <>
      {props.ctx.phase === GamePhase.Menu && <MenuBoard {...props} />}
      {props.ctx.phase === GamePhase.Drafting && <DraftingPhaseBoard {...props} />}
      {props.ctx.phase === GamePhase.Betting && <BettingPhaseBoard {...props} />}
      {props.ctx.phase === GamePhase.Racing && <RacingPhaseBoard {...props} />}
      {props.ctx.phase === GamePhase.RacerSelect && <RacerSelectPhaseBoard {...props} />}
      {props.ctx.phase === GamePhase.Scoring && <ScoringPhaseBoard {...props} />}
    </>
  );
};

export default Board;
