import React, { useEffect, useState } from 'react';
import { WrecklessBoardProps, ScoreUpdateCategory } from '@magicyard/wreckless-game/src/Types';
import styled from 'styled-components';

const ANIMATION_DELAY_IN_MS = 2000;

const sum = (a: Array<number>) => a.reduce((a, b) => a + b, 0);
const delay = (duration: number) =>
  new Promise<void>((resolve) => {
    setTimeout(() => resolve(), duration);
  });

export const StyledHeadline = styled.h1`
  margin-bottom: 1em;
  margin-left: 5em;
`;

const Headline = ({ text }) => {
  return <StyledHeadline>{text}</StyledHeadline>;
};

const StyledTitle = styled.h1`
  text-align: center;
  font-size: 70px;
`;

const ScoreBoardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 40px;
`;

const StyledTableHeaderRow = styled.tr`
  font-weight: bold;
`;

interface Score {
  [ScoreUpdateCategory.Card]: number;
  [ScoreUpdateCategory.Bet]: number;
  [ScoreUpdateCategory.Race]: number;
  total: number;
}

interface ScoreBoardProps {
  scores: Array<Score>;
}

const ScoreBoard = ({ scores }: ScoreBoardProps) => {
  const sortedScores = scores
    .map((score, playerID) => ({ ...score, playerID: `Racer ${playerID}` }))
    .sort((a, b) => b.total - a.total);
  return (
    <ScoreBoardContainer>
      <table>
        <thead>
          <StyledTableHeaderRow>
            <td>Player</td>
            <td>Bets</td>
            <td>Races</td>
            <td>Cards</td>
            <td>Total</td>
          </StyledTableHeaderRow>
        </thead>
        <tbody>
          {sortedScores.map((score, i) => (
            <tr key={`score-row-${i}`}>
              <td>{score.playerID}</td>
              <td>{score[ScoreUpdateCategory.Bet]}</td>
              <td>{score[ScoreUpdateCategory.Race]}</td>
              <td>{score[ScoreUpdateCategory.Card]}</td>
              <td>{score.total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ScoreBoardContainer>
  );
};

const ScoringPhaseBoard = (props: WrecklessBoardProps) => {
  const [scores, setScores] = useState<Array<Score>>([]);
  const [message, setMessage] = useState<string>('');
  const scoreUpdates = props.G.scoreUpdatesByRound[props.G.currentRacingRound];

  useEffect(() => {
    const initialScores = Array(props.ctx.numPlayers)
      .fill(0)
      .map((_, playerID) => {
        const previousRoundsScores: Score = {
          [ScoreUpdateCategory.Card]: 0,
          [ScoreUpdateCategory.Bet]: 0,
          [ScoreUpdateCategory.Race]: 0,
          total: 0,
        };
        for (let roundNumber = 0; roundNumber < props.G.currentRacingRound; roundNumber++) {
          const updates = props.G.scoreUpdatesByRound[roundNumber].filter((s) => Number(s.playerID) === playerID);
          const cardScore = sum(updates.filter((s) => s.category === ScoreUpdateCategory.Card).map((s) => s.score));
          const betScore = sum(updates.filter((s) => s.category === ScoreUpdateCategory.Bet).map((s) => s.score));
          const raceScore = sum(updates.filter((s) => s.category === ScoreUpdateCategory.Race).map((s) => s.score));

          previousRoundsScores[ScoreUpdateCategory.Card] += cardScore;
          previousRoundsScores[ScoreUpdateCategory.Bet] += betScore;
          previousRoundsScores[ScoreUpdateCategory.Race] += raceScore;
          previousRoundsScores.total += cardScore + betScore + raceScore;
        }

        const cardScore = sum(
          scoreUpdates
            .filter((s) => s.category === ScoreUpdateCategory.Card && Number(s.playerID) === playerID)
            .map((s) => s.score)
        );
        return {
          [ScoreUpdateCategory.Card]: cardScore + previousRoundsScores[ScoreUpdateCategory.Card],
          [ScoreUpdateCategory.Bet]: previousRoundsScores[ScoreUpdateCategory.Bet],
          [ScoreUpdateCategory.Race]: previousRoundsScores[ScoreUpdateCategory.Race],
          total: previousRoundsScores.total + cardScore,
        };
      });
    setScores(initialScores);

    // Scoreboard animation, expose updates one by one
    let promise = Promise.resolve<Array<Score> | void>(initialScores);
    const filteredUpdates = scoreUpdates.filter((update) => update.category !== ScoreUpdateCategory.Card);
    for (let i = filteredUpdates.length - 1; i >= 0; i--) {
      const update = filteredUpdates[i];
      promise = promise
        .then((prevScores) => delay(ANIMATION_DELAY_IN_MS).then(() => prevScores))
        .then((prevScores) => {
          const newScores = Object.assign([], prevScores, {
            [update.playerID]: {
              ...prevScores[update.playerID],
              [update.category]: prevScores[update.playerID][update.category] + update.score,
              total: prevScores[update.playerID].total + update.score,
            },
          });
          setScores(newScores);
          setMessage(update.reason);
          return newScores;
        });
    }
    promise = promise
      .then(() => delay(ANIMATION_DELAY_IN_MS))
      .then(() => setMessage('And the winner is:'))
      .then(() => delay(ANIMATION_DELAY_IN_MS))
      .then(() =>
        setMessage(`And the winner is: Racer ${props.G.scoreByPlayerID.indexOf(Math.max(...props.G.scoreByPlayerID))}`)
      )
      .then(() => delay(ANIMATION_DELAY_IN_MS * 1.3))
      .then(() =>
        setMessage(
          `And the winner is: Racer ${props.G.scoreByPlayerID.indexOf(Math.max(...props.G.scoreByPlayerID))} !`
        )
      )
      .then(() => delay(ANIMATION_DELAY_IN_MS))
      .then(() => {
        console.log('stop showing score');
        props.moves.doneShowingScore();
      });
  }, [props.G.scoreUpdatesByRound, props.G.scoreByPlayerID]);

  return (
    <>
      <StyledTitle>Scoreboard</StyledTitle>
      <ScoreBoard scores={scores}></ScoreBoard>
      <Headline text={message} />
    </>
  );
};

export default ScoringPhaseBoard;
