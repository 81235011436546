import React from 'react';
import { DraftItemDriverType, DraftItemType, DraftItemVehicleType } from '@magicyard/wreckless-game/src/Types';
import styled from 'styled-components';

interface DraftItemProps {
  item: DraftItemType;
}

const StyledDraftItem = styled.div`
  width: 500px;
  height: 140px;
  border: 1px solid #000;
  font-size: 20px;
`;

const StyledName = styled.div`
  margin-left: 10px;
  font-size: 1.2em;
  font-weight: bold;
`;

const StyledExtraInfo = styled.div`
  margin-left: 10px;
  font-size: 1.2em;
  font-weight: bold;
`;

const StyledItemDescription = styled.div`
  margin-left: 10px;
`;

const DraftItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
`;

const DraftItem = ({ item }: DraftItemProps) => {
  return (
    <StyledDraftItem>
      <DraftItemHeader>
        <StyledName>{item.Name}</StyledName>
        {(item as DraftItemVehicleType).Life && (
          <StyledExtraInfo>Life: {(item as DraftItemVehicleType).Life}</StyledExtraInfo>
        )}
      </DraftItemHeader>
      <StyledItemDescription>{item.Power}</StyledItemDescription>
    </StyledDraftItem>
  );
};

export default DraftItem;
