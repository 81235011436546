import { INVALID_MOVE, Stage, TurnOrder } from 'boardgame.io/core';
import {
  addScore,
  dealDraftingSets,
  move,
  generateMap1,
  generateMap2,
  generateMap3,
  mapNames,
  objectKeys,
  wrapMoveWithOptions,
} from './utils';
import { VEHICLES_CARDS, DRIVERS_CARDS, HATS_CARDS, DriverCardID, TUTORIAL_HANDS } from './getData';
import { ActivePlayers } from 'boardgame.io/core';
import {
  GameMode,
  BetCard,
  BetsOnCard,
  GameState,
  PlayerData,
  Racer,
  TurnState,
  RaceTurnStates,
  GamePhase,
  ScoreUpdateCategory,
  Tile,
  CoolDown,
  PlayerInfo,
  DraftItemDriverType,
  DraftItemVehicleType,
  PossibleMove,
  Action,
  RollConfing,
  GameConfig,
  DraftItemHatType,
} from './Types';
import { EffectsPlugin } from 'bgio-effects/plugin';
import { config as effectsConfig, CtxWithEffects as Ctx } from './effects-config';
import { setShadowEffect, shadowEffect, shadowMove } from './cards/drivers/shadow';
import { applyLogCarReward, setLogCarEffect } from './cards/vehicles/logCar';
import { Game } from 'boardgame.io';

const BETCHECK_FUNCTIONS = {
  'to win': toWinBetCheck,
};

export const ACTIONS_FUNCTIONS = {
  gremlinEffect: gremlinEffect,
  outlawBandannaEffect: outlawBandannaEffect,
  setGremlinEffect: setGremlinEffect,
  fuzzyDiceEffect: fuzzyDiceEffect,
  rubberGirlEffect: rubberGirlEffect,
  setTrooperEffect: setTrooperEffect,
  goldenBackpackGoldEffect: goldenBackpackGoldEffect,
  goldenBackpackMovementEffect: goldenBackpackMovementEffect,
  trooperEffect: trooperEffect,
  magnet: magnet,
  wind: wind,
  hoverboardEffect: hoverboardEffect,
  bouquet: bouquet,
  scaredyCat: scaredyCat,
  nuclearCarCheck: nuclearCarCheck,
  addNuclearCarCheck: addNuclearCarCheck,
  magnetEffect: magnetEffect,
  setCheerleader: setCheerleader,
  cheerleaderEffect: cheerleaderEffect,
  smoke: smoke,
  stunByTheGuitar: stunByTheGuitar,
  stepByStep: stepByStep,
  setSpotlight: setSpotlight,
  removeSpotlight: removeSpotlight,
  redScarfEffect: redScarfEffect,
  superstitionCheck: superstitionCheck,
  patienceIsAVirtue: patienceIsAVirtue,
  elephantEffect: elephantEffect,
  spiderAttack: spiderAttack,
  spiderEffect: spiderEffect,
  spiderMove: spiderMove,
  elephantMove: elephantMove,
  fuzzyDiceMove: fuzzyDiceMove,
  rerollDieMove: rerollDieMove,
  pickALuckyNumber: pickALuckyNumber,
  pickAHuntSuperstition: pickAHuntSuperstition,
  pokerVisorCheck: pokerVisorCheck,
  fallingBehind: fallingBehind,
  moneyFromTheGraves: moneyFromTheGraves,
  infect: infect,
  infection: infection,
  setLogCarEffect: setLogCarEffect,
  applyLogCarReward: applyLogCarReward,
  luckCheck: luckCheck,
  panic: panic,
  bowTieEffect: bowTieEffect,
  masterOfDisguiseEffect: masterOfDisguiseEffect,
  setTrapLayer: setTrapLayer,
  onLandTrapLayerEffect: onLandTrapLayerEffect,
  onPassOverTrapLayerEffect: onPassOverTrapLayerEffect,
  propellerPassOver: propellerPassOver,
  overConfidence: overConfidence,
  fleetFooted: fleetFooted,
  soBig: soBig,
  freeze: freeze,
  fireBreath: fireBreath,
  bump: bump,
  duel: duel,
  jetCarMove: jetCarMove,
  jetCarEffect: jetCarEffect,
  duelCharge: duelCharge,
  tow: tow,
  setLeprechaunTrigger: setLeprechaunTrigger,
  maybeFeedTheLeprechaun: maybeFeedTheLeprechaun,
  setShadowEffect: setShadowEffect,
  shadowEffect: shadowEffect,
  towPlayer: towPlayer,
  addPorcupineCheck: addPorcupineCheck,
  porcupineCheck: porcupineCheck,
  scare: scare,
  swordDance: swordDance,
  bitPower: bitPower,
  thump: thump,
  plunder: plunder,
  shortcut: shortcut,
  experienced: experienced,
  experienceCharge: experienceCharge,
  plan: plan,
  regularAttack: regularAttack,
} as const;

type Bid = {
  playerID: any;
  tokens: any;
};

const RACE1_RESULT_REWARDS = {
  1: 10,
  2: 6,
  3: 4,
  4: 3,
  5: 2,
  6: 1,
  7: 0,
  8: 0,
  9: 0,
  10: 0,
};

const RACE2_RESULT_REWARDS = {
  1: 15,
  2: 9,
  3: 6,
  4: 4,
  5: 2,
  6: 1,
  7: 0,
  8: 0,
  9: 0,
  10: 0,
};

const RACE3_RESULT_REWARDS = {
  1: 20,
  2: 12,
  3: 8,
  4: 5,
  5: 3,
  6: 1,
  7: 0,
  8: 0,
  9: 0,
  10: 0,
};

export const NAMES_TO_COLORS = {
  purple: '#BC93FF' as const,
  orange: '#FFC793' as const,
  blue: '#93A4FF' as const,
  red: '#FF9F9F' as const,
  pink: '#FF93E7' as const,
  green: '#95FF93' as const,
};
export const COLORS_TO_NAMES = objectKeys(NAMES_TO_COLORS).reduce((acc, name) => {
  acc[NAMES_TO_COLORS[name]] = name;
  return acc;
}, {});

const ALL_COLORS = Object.values(NAMES_TO_COLORS);
export const WRECKLESS: Game<GameState, Ctx> = {
  name: 'wreckless',
  plugins: [EffectsPlugin(effectsConfig)],
  setup: (ctx: Ctx, setupData): GameState => {
    const players = setupData?.players;
    const colors = [...ALL_COLORS];

    if (players && players.length > 0) {
      const existingColors = players.map((player) => player.color);
      colors.filter((color) => existingColors.includes(color));
      players.forEach((player) => {
        player.color = colors.pop();
        player.colorName = COLORS_TO_NAMES[player.color];
      });
    }

    const vehiclesDeck = ctx.random.Shuffle(VEHICLES_CARDS);
    const driversDeck = ctx.random.Shuffle(DRIVERS_CARDS);
    const hatsDeck = ctx.random.Shuffle(HATS_CARDS);
    const draftingSets = dealDraftingSets(vehiclesDeck, driversDeck, hatsDeck, ctx.numPlayers);
    const endRaceRound = Array(3).fill(false);
    const map = [generateMap1(), generateMap2(), generateMap3()];
    const bidsByRoundBySetIndex: Array<Array<Bid>> = [
      Array(ctx.numPlayers).fill(null),
      Array(ctx.numPlayers).fill(null),
      Array(ctx.numPlayers).fill(null),
      Array(ctx.numPlayers).fill(null),
      Array(ctx.numPlayers).fill(null),
    ];
    const tokensByPlayerID = Array(ctx.numPlayers).fill(5);
    const betsTable: Array<BetsOnCard> = [];
    const scoreByPlayerID = Array(ctx.numPlayers).fill(0);
    const draftingIndex = 0;
    const defaultGameConfig: GameConfig = { gameMode: GameMode.FullGame, rollConfig: RollConfing.Auto, reRoll: false };
    const calculatedTurnOrder = [];
    const playedCards = Array.from({ length: ctx.numPlayers }, () => []);
    const hands = Array.from({ length: ctx.numPlayers }, () => []);
    const roundPlayerData: Array<Array<PlayerData>> = [
      Array.from({ length: ctx.numPlayers }),
      Array.from({ length: ctx.numPlayers }),
      Array.from({ length: ctx.numPlayers }),
    ];
    const roundBets: Array<Array<BetsOnCard>> = [[], [], []];
    const currentRacingRound = 0;
    const currentSetIndex = null;
    const numberOfCardsPerRound = 3;
    const placeInRace = 1;
    const scoreUpdatesByRound = [[], [], []];
    const raceResultRewardByRound = [RACE1_RESULT_REWARDS, RACE2_RESULT_REWARDS, RACE3_RESULT_REWARDS];

    return {
      mapsNames: mapNames,
      gameConfig: defaultGameConfig,
      playedCards,
      colors,
      players,
      currentSetIndex,
      draftingIndex,
      scoreByPlayerID,
      hands,
      roundPlayerData,
      currentRacingRound,
      bidsByRoundBySetIndex,
      tokensByPlayerID,
      draftingSets,
      betsTable,
      roundBets,
      calculatedTurnOrder,
      numberOfCardsPerRound,
      placeInRace,
      map,
      scoreUpdatesByRound,
      endRaceRound,
      raceResultRewardByRound,
      lastWarmUpRacingRound: -1,
    };
  },

  moves: {
    bid,
    selectGameType,
    setCurrentSetIndex,
    selectRacer: wrapMoveWithOptions(selectRacer, { ignoreStaleStateID: true }),
    bet: wrapMoveWithOptions(bet, { ignoreStaleStateID: true }),
    shadowMove,
    pickALuckyNumberMove,
    masterOfDisguiseMove,
    pickACrystalBallMove: pickACrystalBallMove,
    fleetFootedMove,
    rerollDieMove,
    shortcutMove,
    experienceChargeMove,
    planMove,
    jetCarMove,
    fuzzyDiceMove,
    elephantMove,
    spiderMove,
    swordDanceMove,
    rollDieMove,
    feedTheLeprechaunMove,
    rubberGirlMove,
    towMove,
    play: wrapMoveWithOptions(play, { client: false }),
    doneShowingScore,
  },
  phases: {
    [GamePhase.Menu]: {
      start: true,
      endIf: (G, ctx) => {
        // Check if any human playes around...
        const someHumanPlayers = G.players && G.players?.some((p) => p.isBot !== true);
        // If not, end the phase, meaning use preset gameConfig.
        return !someHumanPlayers;
      },
      next: (G) => {
        return G.gameConfig.gameMode === GameMode.Tutorial ? GamePhase.RacerSelect : GamePhase.Drafting;
      },
      moves: { selectGameType },
      turn: {
        // Only allow the first player to select game config
        activePlayers: { maxMoves: 1, currentPlayer: Stage.NULL },
      },
    },
    [GamePhase.Drafting]: {
      //onBegin: sendSyncMsg,
      endIf: (G: GameState) => G.draftingIndex > 4,
      next: GamePhase.RacerSelect,
      moves: { setCurrentSetIndex, bid },
    },
    [GamePhase.RacerSelect]: {
      onBegin: setTurnOrder,
      next: GamePhase.Racing,
      endIf: everybodySelectedARacer,
      moves: { selectRacer: wrapMoveWithOptions(selectRacer, { ignoreStaleStateID: true }) },
      turn: {
        activePlayers: ActivePlayers.ALL_ONCE,
      },
    },
    [GamePhase.Betting]: {
      onBegin: (G, ctx) => {
        console.log('================ Betting Phase onBegin ================');
        generateBetsTable(G, ctx);
      },
      next: GamePhase.Racing,
      endIf: everybodySelectedABet,
      onEnd: (G, ctx) => {
        updateBetsToRoundBets(G, ctx);
        console.log('================ Betting Phase onEnd ================');
      },
      moves: { bet: wrapMoveWithOptions(bet, { ignoreStaleStateID: true }) },
      turn: {
        activePlayers: ActivePlayers.ALL_ONCE,
      },
    },
    [GamePhase.Racing]: {
      onBegin: (G, ctx) => {
        console.log('================ Racing Phase Beginning ================');
        if (G.lastWarmUpRacingRound !== G.currentRacingRound) {
          G.lastWarmUpRacingRound = G.currentRacingRound;
          executeWarmUpFunctions(G, ctx);
        }
      },
      endIf: raceIsOver,
      onEnd: () => {
        console.log('================ Racing Phase Ending ================');
      },
      next: GamePhase.Scoring,
      turn: {
        order: {
          first: (G, ctx) => 0,
          next: calculateNextPlayerTurn,
          playOrder: (G, ctx) => G.calculatedTurnOrder,
        },

        onBegin: (G, ctx) => {
          console.log(
            `..............................Racing Phase ${G.currentRacingRound}: Starting A New Turn (Player ${ctx.currentPlayer})..............................`
          );
        },
        onMove(G, ctx) {
          console.log(`Player ${ctx.currentPlayer} onMove`);
        },
        onEnd: (G, ctx) => {
          console.log(`Turn ended for player ${ctx.currentPlayer}`);
          ctx.effects.endTurn();
        },
      },
      moves: {
        play: wrapMoveWithOptions(play, { client: false }),
        pickALuckyNumberMove,
        shadowMove,
        pickACrystalBallMove: pickACrystalBallMove,
        fleetFootedMove,
        shortcutMove,
        experienceChargeMove,
        rerollDieMove,
        planMove,
        elephantMove,
        spiderMove,
        fuzzyDiceMove,
        jetCarMove,
        towMove,
        swordDanceMove,
        feedTheLeprechaunMove,
        rubberGirlMove,
        masterOfDisguiseMove,
        rollDieMove,
      },
    },
    [GamePhase.Scoring]: {
      onBegin: scoringPhaseAnnouncement,
      endIf: (G) => G.endRaceRound[G.currentRacingRound],
      onEnd: setANewRound,
      next: GamePhase.RacerSelect,
      moves: { doneShowingScore },
    },
  },

  endIf: (G: GameState, ctx) => {
    if (G.currentRacingRound > 2) {
      logAndDisplay(ctx, 'Ending the game');
      console.log('-------- GAME OVER ---------');
      return true;
    }
    return false;
  },
};

//-------race select functions---------

function selectRacer(G: GameState, ctx: Ctx, driverCardIndex: number, vehicleCardIndex: number, hatCardIndex: number) {
  const playerHand = G.hands[+ctx.playerID];
  const driverCard = playerHand.at(driverCardIndex) as DraftItemDriverType;
  const vehicleCard = playerHand.at(vehicleCardIndex) as DraftItemVehicleType;
  const hatCard = playerHand.at(hatCardIndex) as DraftItemHatType;

  //assemble the racer
  const racer: Racer = {
    vehicle: vehicleCard,
    driver: driverCard,
    hat: hatCard,
  };

  //assemble turnState
  const turnState: TurnState = {
    state: RaceTurnStates.InitiateTurn,
    pendingActions: [],
    pendingMoves: [],
    startingPoint: null,
    dieResult: null,
    movementModifiers: [],
    movementPoints: 0,
  };

  // Save racer to the relevant race
  const playerData: PlayerData = {
    racer: racer,
    locationOnMap: 0,
    predictedLocationOnMap: 0,
    life: vehicleCard.Life,
    attackPower: driverCard.Attack,
    attackModifiers: [],
    isWrecked: false,
    isStunned: false,
    isFirstTurn: true,
    placeFinished: null,
    actions: [...(racer.driver.Actions || []), ...(racer.hat.Actions || []), ...(racer.vehicle.Actions || [])],
    actionAttributes: {},
    actionCooldowns: {},
    turnState: turnState,
    playerID: Number(ctx.playerID),
  };

  //adding cards powers to playerData.actions
  G.roundPlayerData[G.currentRacingRound][ctx.playerID] = playerData;

  // add selected cards to playedcards
  G.playedCards[ctx.playerID] = [...G.playedCards[ctx.playerID], ...[vehicleCard, driverCard, hatCard]];

  // remove selected cards from the players hand
  G.hands[ctx.playerID] = G.hands[ctx.playerID].filter((i) => i !== driverCard && i !== vehicleCard && i !== hatCard);
}

function everybodySelectedARacer(G: GameState, ctx: Ctx) {
  const numRacers = G.roundPlayerData[G.currentRacingRound].filter((playerData) => {
    return playerData && playerData.racer !== null;
  }).length;
  if (numRacers === ctx.numPlayers) {
    console.log('Everyone selected a racer');
    return true;
  }
  return false;
}

//---------game type phase functions---------
function selectGameType(G: GameState, ctx: Ctx, gameConfig: Partial<GameConfig>) {
  G.gameConfig = { ...G.gameConfig, ...gameConfig };
  console.log('game config selected: ' + gameConfig);
  if (gameConfig.gameMode === GameMode.Tutorial) {
    G.hands = TUTORIAL_HANDS;
  }
  ctx.events.endPhase();
}

//---------biding functions---------
function setCurrentSetIndex(G: GameState, ctx: Ctx, setIndex: number | null) {
  G.currentSetIndex = setIndex;
}

function bid(G: GameState, ctx: Ctx, tokens) {
  const { currentSetIndex: setIndex } = G;
  if (G.tokensByPlayerID[ctx.playerID] < tokens) {
    console.log("player doesn't have enough tokens to place the bid");
    return INVALID_MOVE;
  }
  const bid: Bid = {
    playerID: ctx.playerID,
    tokens: tokens,
  };

  const roundBidsBySetIndex = G.bidsByRoundBySetIndex[G.draftingIndex];
  const currentBid = roundBidsBySetIndex[setIndex];
  // if no one bid on the requested set yet
  if (currentBid == null) {
    console.log('no one bid on set number ' + setIndex + '... placing a bid');
    //set bit to bidding table
    setABid(G, ctx, setIndex, bid);
  } else {
    // if someone already bid on the requested set
    console.log('someone already placed a bid, checking which bid is higher');
    // if exsiting bid is higher
    if (currentBid.tokens >= bid.tokens) {
      console.log('current bid is higher, returning invalid move');
      return INVALID_MOVE;
    }
    // if suggested bid is higher
    console.log('suggested bid is higher, placing the bid and returning current bid to previous bidder');
    // save previous bidder id
    const previousBidderID = currentBid.playerID;
    // return tokens to previous bidder
    G.tokensByPlayerID[previousBidderID] = G.tokensByPlayerID[previousBidderID] + currentBid.tokens;
    console.log('player number' + previousBidderID + ' got his tokens back');
    // update bidsByRoundBySetIndex with the new bid
    setABid(G, ctx, setIndex, bid);
  }

  //checking if all players bid on a set
  const numOfBids = roundBidsBySetIndex.filter((bid) => bid !== null).length;

  if (numOfBids === ctx.numPlayers) {
    console.log('All players placed a bid, moving to the next round of bidding');

    const currentSet = G.draftingSets[G.draftingIndex];
    roundBidsBySetIndex.forEach((bid, setIndex) => {
      G.hands[bid.playerID] = [...G.hands[bid.playerID], ...currentSet[setIndex]];
    });

    G.draftingIndex++;
  }

  if (G.draftingIndex !== 5) {
    endBiddingTurn(G, ctx);
  }
}

function endBiddingTurn(G: GameState, ctx: Ctx) {
  console.log('ending biding turn..');
  const playerBidsTable = Array(ctx.numPlayers).fill(false);
  // There was length + 1 here
  for (let i = 0; i < ctx.numPlayers; i++) {
    if (G.bidsByRoundBySetIndex[G.draftingIndex][i] !== null) {
      playerBidsTable[G.bidsByRoundBySetIndex[G.draftingIndex][i].playerID] = true;
    }
  }
  for (let i = 0; i < ctx.numPlayers + 1; i++) {
    const turnRunner = (i + parseInt(ctx.playerID)) % ctx.numPlayers;
    if (playerBidsTable[turnRunner] == false) {
      console.log(`Setting player number ${turnRunner} to play next`);
      ctx.events.endTurn({ next: turnRunner.toString() });
      return;
    }
  }
}

function printCurrentActionStatus(G, ctx) {
  console.log('-------');
  console.log('Current action status:');
  for (let i = 0; i < ctx.numPlayers; i++) {
    console.log('Set ' + i + ':');
    console.log('cards are: ' + JSON.stringify(G.draftingSets[G.draftingIndex][i]));
    if (G.draftingIndex < 5 && G.bidsByRoundBySetIndex[G.draftingIndex][i] !== null) {
      console.log(
        'current bet on set ' +
          i +
          ': bidder = ' +
          G.bidsByRoundBySetIndex[G.draftingIndex][i].playerID +
          ', bid = ' +
          G.bidsByRoundBySetIndex[G.draftingIndex][i].tokens
      );
    } else {
      console.log('there is no bet on set ' + i);
    }
  }
  console.log('-------');
}

function setABid(G: GameState, ctx: Ctx, setIndex, bid) {
  if (!(Number.isInteger(setIndex) || setIndex < 0 || setIndex > 6)) {
    return INVALID_MOVE;
  }
  //set bit to bidding table
  G.bidsByRoundBySetIndex[G.draftingIndex][setIndex] = bid;
  // update player's tokens
  G.tokensByPlayerID[ctx.playerID] -= bid.tokens;
  //update playerBids table
  // ---remove to support func--- G.playerBidsTable[ctx.playerID] = true;
  setCurrentSetIndex(G, ctx, null);
  console.log(
    'player number ' +
      ctx.playerID +
      ' placed a bid of ' +
      bid.tokens +
      ' on set ' +
      setIndex +
      '. he now owns ' +
      G.tokensByPlayerID[ctx.playerID] +
      ' tokens'
  );
}

//---------betting functions---------

function bet(G: GameState, ctx: Ctx, cardIndex: number) {
  console.log(`Player ${ctx.playerID} bet on card ${cardIndex}`);
  G.betsTable[cardIndex].bettors.push(ctx.playerID);
}

function everybodySelectedABet(G: GameState, ctx: Ctx) {
  let betCounter = 0;
  const totalBets = G.betsTable.map((t) => t.bettors.length).reduce((a, b) => a + b, 0);
  for (let i = 0; i < G.betsTable.length; i++) {
    betCounter = betCounter + G.betsTable[i].bettors.length;
  }
  if (betCounter == ctx.numPlayers) {
    return true;
  }
  return false;
}

function updateBetsToRoundBets(G: GameState, ctx: Ctx) {
  G.roundBets[G.currentRacingRound] = G.roundBets[G.currentRacingRound].concat(G.betsTable);
  G.betsTable = [];
}

function generateBetsTable(G, ctx) {
  for (let i = 0; i < ctx.numPlayers; i++) {
    const bet: BetsOnCard = {
      betCard: generateBetCard(G, ctx, i),
      bettors: [],
    };
    G.betsTable.push(bet);
  }
}

function generateBetCard(G: GameState, ctx, playerID): BetCard {
  const rewardByRound = {
    0: 4 + Math.floor(ctx.random.Number() * 7),
    1: 7 + Math.floor(ctx.random.Number() * 9),
    2: 9 + Math.floor(ctx.random.Number() * 12),
  };

  const reward = rewardByRound[G.currentRacingRound];

  const betCard: BetCard = {
    type: 'to win',
    racer: playerID,
    addtionalRacer: null,
    reward: reward,
  };
  return betCard;
}

//---------racing functions---------

function setTurnOrder(G: GameState, ctx: Ctx) {
  //Currently we kept the initiative functionalty commeted out in case we would like to revert and reuse it;
  console.log('setting the play order for the round');
  G.calculatedTurnOrder = ctx.random.Shuffle(ctx.playOrder);
  logAndDisplay(ctx, 'Shuffled turn order is ' + JSON.stringify(G.calculatedTurnOrder));
  // const racerIntiativeByPlayerId = G.roundPlayerData[G.currentRacingRound].map((playerData: PlayerData, playerID) => {
  //   return { playerID, initiative: playerData.initiative };
  // });
  // racerIntiativeByPlayerId.sort((a, b) => a.initiative - b.initiative);
  // G.calculatedTurnOrder = racerIntiativeByPlayerId.map(({ playerID }) => `${playerID}`);
  // logAndDisplay(ctx, 'The race turn order is ' + JSON.stringify(G.calculatedTurnOrder));
  // logAndDisplay(ctx, 'Shuffled turn order is ' + JSON.stringify(G.calculatedTurnOrder));
}

function allPendingMovesCompleted(G: GameState): boolean {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  for (const playerData of playersData) {
    if (playerData.turnState.pendingMoves.length > 0) {
      return false;
    }
  }
  return true;
}

function updateTurnOrderAfterBetting(G, ctx) {
  console.log('old play order: ' + JSON.stringify(G.calculatedTurnOrder));
  console.log('updating the play order after betting, last player to play is ' + ctx.currentPlayer);
  const currentPlayerTurnOrder = G.calculatedTurnOrder.indexOf(ctx.currentPlayer);
  const newTurnOrder = G.calculatedTurnOrder
    .slice(currentPlayerTurnOrder + 1, G.calculatedTurnOrder.length)
    .concat(G.calculatedTurnOrder.slice(0, currentPlayerTurnOrder + 1));
  console.log('new play order: ' + JSON.stringify(newTurnOrder));
  G.calculatedTurnOrder = newTurnOrder;
  logAndDisplay(ctx, 'The updated race turn order is ' + JSON.stringify(G.calculatedTurnOrder));
}

function updateTurnOrder(G, ctx) {
  console.log('___Updating turn order function started___');
  console.log('play order: ' + JSON.stringify(G.calculatedTurnOrder));
  console.log('Updating the play order, last player to play is ' + ctx.currentPlayer);
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const newTurnOrder: Array<string> = [];
  for (let playerTurnIndex = 0; playerTurnIndex < G.calculatedTurnOrder.length; playerTurnIndex++) {
    if (
      !playersData[G.calculatedTurnOrder[playerTurnIndex]].isWrecked &&
      playersData[G.calculatedTurnOrder[playerTurnIndex]].placeFinished === null
    ) {
      newTurnOrder.push(G.calculatedTurnOrder[playerTurnIndex]);
    }
  }
  G.calculatedTurnOrder = newTurnOrder;
  console.log('New play order: ' + JSON.stringify(G.calculatedTurnOrder));
}

export function play(G: GameState, ctx: Ctx) {
  const playerData = G.roundPlayerData[G.currentRacingRound][+ctx.currentPlayer];
  console.log(
    `play() called, ctx.playerID: ${ctx.playerID}, currentPlayer: ${ctx.currentPlayer}, turnState: ${
      G.roundPlayerData[G.currentRacingRound][+ctx.playerID].turnState.state
    } , location: ${G.roundPlayerData[G.currentRacingRound][+ctx.playerID].locationOnMap}`
  );
  const currentPlayerInfo: PlayerInfo = G.players?.[+ctx.currentPlayer];

  if (playerData.life <= 0 || gotToTheFinishLine(G, ctx.currentPlayer) || playerData.isStunned) {
    if (playerData.isStunned) {
      ctx.effects.action({
        type: 'turnStart',
        turnsPlayerId: ctx.currentPlayer,
      });
      logAndDisplay(ctx, `${currentPlayerInfo.name} is stunned, skipping turn`);
      playerData.isStunned = false;
      ctx.effects.action({
        type: 'floatingAction',
        subtype: 'unstun',
        playerID: parseInt(ctx.currentPlayer),
        message: `clean stun`,
        affectedPlayerIDs: [ctx.currentPlayer],
        amount: 1,
      });
    } else {
      console.log('player ' + playerData.playerID + ' still in the turn order');
    }
  } else {
    ctx.effects.action({
      type: 'turnStart',
      turnsPlayerId: ctx.currentPlayer,
    });
    if (playerData.turnState.state === RaceTurnStates.InitiateTurn) {
      playerData.turnState.pendingActions = [...playerData.actions];
      playerData.turnState.pendingMoves = [];
      // This was done at the *end* of the turn to allow accumulating movementPoint/Modifiers during others' turn
      // playerData.turnState.movementPoints = 0;
      // playerData.turnState.movementModifiers = [];
      if (playerData.isFirstTurn === true) {
        playerData.isFirstTurn = false;
        playerData.turnState.state = RaceTurnStates.OnRoundBegin;
      } else {
        playerData.turnState.state = RaceTurnStates.OnTurnBegin;
      }
    }

    if (playerData.turnState.state === RaceTurnStates.OnRoundBegin) {
      playerData.turnState.startingPoint = playerData.locationOnMap;
      if (!executeStateActions(G, ctx, RaceTurnStates.OnRoundBegin, RaceTurnStates.PreRoll)) {
        return;
      }
    }

    if (!allPendingMovesCompleted(G)) {
      return;
    }

    if (playerData.turnState.state === RaceTurnStates.OnTurnBegin) {
      playerData.turnState.startingPoint = playerData.locationOnMap;
      if (!executeStateActions(G, ctx, RaceTurnStates.OnTurnBegin, RaceTurnStates.PreRoll)) {
        return;
      }
    }

    if (!allPendingMovesCompleted(G)) {
      return;
    }

    if (playerData.turnState.state === RaceTurnStates.PreRoll) {
      if (G.gameConfig.rollConfig === RollConfing.ManualRoll && !currentPlayerInfo.isBot) {
        logAndDisplay(ctx, 'Player ' + playerData.playerID + ' - Roll the die');
        ctx.effects.action({
          type: 'moveScreen',
          subtype: 'die_roll',
          playerID: parseInt(ctx.currentPlayer),
          message: currentPlayerInfo.name + ' - Roll the die',
          affectedPlayerIDs: [],
        });
        addPendingMove('rollDieMove', G, ctx);
      }
      playerData.turnState.state = RaceTurnStates.Roll;
    }

    if (!allPendingMovesCompleted(G)) {
      return;
    }

    if (playerData.turnState.state === RaceTurnStates.Roll) {
      setDieResults(ctx, playerData);
      playerData.turnState.state = RaceTurnStates.AfterRoll;
      if (G.gameConfig.reRoll) {
        logAndDisplay(ctx, 'Player ' + playerData.playerID + ' - would you like to pay 3 coins and reroll?');
        ctx.effects.action({
          type: 'moveScreen',
          subtype: 'roll_die',
          playerID: parseInt(ctx.currentPlayer),
          message: 'Player ' + playerData.playerID + ' - would you like to pay 3 coins and reroll?',
          affectedPlayerIDs: [],
        });
        if (G.scoreByPlayerID[playerData.playerID] > 2) {
          addPendingMove('rerollDieMove', G, ctx);
        }
      }
    }

    if (!allPendingMovesCompleted(G)) {
      return;
    }

    if (playerData.turnState.state == RaceTurnStates.AfterRoll) {
      if (!executeStateActions(G, ctx, RaceTurnStates.AfterRoll, RaceTurnStates.BeforeMoving)) {
        return;
      }
      playerData.predictedLocationOnMap = predictLandOnTile(G, ctx, playerData);
      updateDieAndMovement(G, ctx);
    }

    if (!allPendingMovesCompleted(G)) {
      return;
    }

    if (playerData.turnState.state === RaceTurnStates.BeforeMoving) {
      if (!executeStateActions(G, ctx, RaceTurnStates.BeforeMoving, RaceTurnStates.Moving)) {
        return;
      }
      playerData.predictedLocationOnMap = predictLandOnTile(G, ctx, playerData);
      updateFinalDieAndMovement(G, ctx);
    }

    if (!allPendingMovesCompleted(G)) {
      return;
    }

    if (playerData.turnState.state == RaceTurnStates.Moving) {
      console.log({ movementModifiers: JSON.stringify(playerData.turnState.movementModifiers) });
      while (canAffordMoveToNextTile(G, ctx, playerData) && !playerData.isWrecked && !playerData.isStunned) {
        console.log('Moving forward to tile number ' + (playerData.locationOnMap + 1));
        if (playerData.turnState.startingPoint !== playerData.locationOnMap) {
          checkCurrentTileAddOns(G, ctx, playerData, 'passOver');
          const passOverActions = playerData.turnState.pendingActions.filter((action) => action.type == 'passOver');
          while (passOverActions.length !== 0) {
            if (allPendingMovesCompleted(G)) {
              const action = passOverActions.pop();
              removePendingAction(action, playerData);
              ACTIONS_FUNCTIONS[action.name](G, ctx, undefined, undefined);
            } else {
              return;
            }
          }
          if (!allPendingMovesCompleted(G)) {
            return;
          }
          playerData.turnState.pendingActions = playerData.turnState.pendingActions.concat(
            playerData.actions.filter((action) => action.type === 'passOver')
          );
        }
        const nextTile = G.map[G.currentRacingRound][playerData.locationOnMap + 1];
        const nextTileCost = getTileMovementCost(G, ctx, playerData, nextTile);
        playerData.turnState.movementPoints = playerData.turnState.movementPoints - nextTileCost;
        move(G, ctx, playerData, playerData.locationOnMap + 1);
        const currentTile = getCurrentTile(G, playerData);
        if (currentTile.Type === 'daily_double') {
          logAndDisplay(ctx, 'Welcome to the daily double! Time to bet!');
          currentTile.Type = 'daily_double_done';
          updateTurnOrderAfterBetting(G, ctx);
          ctx.events.setPhase(GamePhase.Betting);
          logAndDisplay(ctx, 'Moving the the betting phase');
        }
        if (currentTile.Type === 'bump') {
          logAndDisplay(ctx, 'Player ' + ctx.currentPlayer + ' got bumped');
          ctx.effects.action({
            type: 'floatingAction',
            subtype: 'movement',
            playerID: parseInt(ctx.playerID),
            message: `bump!`,
            affectedPlayerIDs: [ctx.playerID],
            amount: -1,
          });
        }
      }
      playerData.turnState.state = RaceTurnStates.OnLand;
    }

    if (!allPendingMovesCompleted(G)) {
      return;
    }

    if (playerData.turnState.state === RaceTurnStates.OnLand) {
      if (playerData.turnState.startingPoint !== playerData.locationOnMap) {
        console.log('Player landed on tile ' + playerData.locationOnMap);
        if (!gotToTheFinishLine(G, ctx.currentPlayer)) {
          checkCurrentTileAddOns(G, ctx, playerData, RaceTurnStates.OnLand);

          const onLandActions = playerData.turnState.pendingActions.filter((action) => action.type == 'onLand');
          while (onLandActions.length !== 0) {
            if (allPendingMovesCompleted(G)) {
              const action = onLandActions.pop();
              removePendingAction(action, playerData);
              console.log('executing onLand actions');
              ACTIONS_FUNCTIONS[action.name](G, ctx, undefined, undefined);
            } else {
              return;
            }
          }
          const currentTile = getCurrentTile(G, playerData);
          if (currentTile.Type === 'damage') {
            logAndDisplay(ctx, 'Player ' + ctx.currentPlayer + ' took 1 dmg from tile');
            subtractLife(G, ctx, playerData, currentTile.Damage);
          }
          if (currentTile.Type === 'money_bag') {
            logAndDisplay(ctx, 'Player ' + ctx.currentPlayer + ' got 8 gold!');
            addScore(G, ctx, ctx.currentPlayer, 8, ScoreUpdateCategory.Race, {
              reason: 'player ' + +ctx.currentPlayer + ' landed on a money_bag tile and got 8 gold!',
            });
            currentTile.Type = 'plain';
          }
          if (currentTile.Type === 'coin') {
            logAndDisplay(ctx, 'Player ' + ctx.currentPlayer + ' got 2 gold!');
            addScore(G, ctx, ctx.currentPlayer, 2, ScoreUpdateCategory.Race, {
              reason: 'player ' + +ctx.currentPlayer + ' landed on a coin tile and got 2 gold!',
            });
          }
          if (shareATile(G, ctx)) {
            attack(G, ctx, ctx.currentPlayer, shareATileWith(G, ctx));
          }
        }
      }
      console.log(
        `LOCATION PREDICTION: predicted: ${playerData.predictedLocationOnMap}, actual: ${playerData.locationOnMap}`
      );
      if (playerData.predictedLocationOnMap !== playerData.locationOnMap) {
        console.error('LOCATION PREDICTION FAILED');
      }
      playerData.turnState.state = RaceTurnStates.OnTurnEnd;
    }

    if (!allPendingMovesCompleted(G)) {
      return;
    }

    if (!executeStateActions(G, ctx, RaceTurnStates.OnTurnEnd, RaceTurnStates.InitiateTurn)) {
      return;
    }
  }
  console.log('ending turn for player', ctx.currentPlayer);
  updateCoolDowns(G, ctx);
  playerData.turnState.state = RaceTurnStates.InitiateTurn;

  playerData.turnState.movementModifiers = [];
  playerData.turnState.movementPoints = 0;
  ctx.events.endTurn();
}

export function logAndDisplay(ctx: Ctx, message: string) {
  console.log(message);
  ctx.effects.text({ message });
}

function updateCoolDowns(G: GameState, ctx: Ctx): void {
  console.log(' updating cooldowns');
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  Object.keys(playerData.actionCooldowns).forEach((key) => {
    playerData.actionCooldowns[key].currentCoolDown = Math.max(0, playerData.actionCooldowns[key].currentCoolDown - 1);
    console.log(key + ' cooldown: ' + playerData.actionCooldowns[key].currentCoolDown);
  });
}

function gotToTheFinishLine(G: GameState, playerID: string | number) {
  return G.roundPlayerData[G.currentRacingRound][+playerID].locationOnMap + 1 === G.map[G.currentRacingRound].length;
}

function roll(ctx: Ctx) {
  logAndDisplay(ctx, 'Rolling a die...');
  const dieResult = ctx.random.D6();
  logAndDisplay(ctx, "It's a " + dieResult + '!');

  return dieResult;
}

function updateDieAndMovement(G: GameState, ctx: Ctx) {
  ctx.effects.action({
    type: 'diceRoll',
    rollerId: ctx.currentPlayer,
    message: `It's a ${G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].turnState.dieResult}`,
    predictedLocationOnMap: G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].predictedLocationOnMap,
    finalDice: G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].turnState.dieResult,
    movementPoints: G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].turnState.movementPoints,
    movementModifiers: JSON.parse(
      JSON.stringify(G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].turnState.movementModifiers)
    ),
  });
}

function updateFinalDieAndMovement(G: GameState, ctx: Ctx) {
  ctx.effects.action({
    type: 'finalDie',
    rollerId: ctx.currentPlayer,
    message: `It's a ${G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].turnState.dieResult}`,
    predictedLocationOnMap: G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].predictedLocationOnMap,
    finalDice: G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].turnState.dieResult,
    movementPoints: G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].turnState.movementPoints,
    movementModifiers: JSON.parse(
      JSON.stringify(G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].turnState.movementModifiers)
    ),
  });
}

function canAffordMoveToNextTile(G: GameState, ctx: Ctx, playerData: PlayerData) {
  const nextTile = G.map[G.currentRacingRound][playerData.locationOnMap + 1];
  return nextTile !== undefined && playerData.turnState.movementPoints > 0;
}

function raceIsOver(G: GameState, ctx: Ctx) {
  const numberOfRacersThatCompletedTheRace = G.roundPlayerData[G.currentRacingRound].filter((playerData) =>
    gotToTheFinishLine(G, playerData.playerID)
  ).length;

  const numberOfRacersThatGotWrecked = G.roundPlayerData[G.currentRacingRound].filter(
    (playerData) => playerData.isWrecked
  ).length;
  if (
    numberOfRacersThatCompletedTheRace > 3 ||
    numberOfRacersThatCompletedTheRace + numberOfRacersThatGotWrecked === G.roundPlayerData[G.currentRacingRound].length
  ) {
    return true;
  }
  return false;
}

function executeOnRoundEndActions(G: GameState, ctx: Ctx) {
  if (raceIsOver(G, ctx)) {
    for (let playerID = 0; playerID < G.roundPlayerData[G.currentRacingRound].length; playerID++) {
      const playerData = G.roundPlayerData[G.currentRacingRound][playerID];
      const actions = playerData.turnState.pendingActions.filter((action) => action.type == RaceTurnStates.OnRoundEnd);
      while (actions.length !== 0) {
        const action = actions.pop();
        //remember that there is no current player on state OnRoundEnd //ctx.currentPlayer == undefined
        removePendingAction(action, playerData);
        ACTIONS_FUNCTIONS[action.name](G, ctx, playerID, undefined);
      }
    }
  }
}

function executeStateActions(G: GameState, ctx: Ctx, turnState: RaceTurnStates, nextState: RaceTurnStates): boolean {
  console.log(`executeStateActions for turn state: ${turnState}`);
  const playerData = G.roundPlayerData[G.currentRacingRound][+ctx.currentPlayer];
  if (playerData.turnState.state !== turnState) {
    throw new Error(
      `Tried to executeStateActions with wrong turn state. Expected ${turnState}, got ${playerData.turnState.state}.`
    );
  }
  const noPriorityActions = playerData.turnState.pendingActions.filter(
    (action) => action.type == turnState && (action.priority === undefined || action.priority === null)
  );
  const highPriorityActions = playerData.turnState.pendingActions.filter(
    (action) => action.type == turnState && action.priority === 1
  );
  const lowPriorityActions = playerData.turnState.pendingActions.filter(
    (action) => action.type == turnState && action.priority === 0
  );

  const pendingActions = [...lowPriorityActions, ...noPriorityActions, ...highPriorityActions];

  while (pendingActions.length > 0) {
    if (allPendingMovesCompleted(G)) {
      const pendingAction = pendingActions.pop();
      removePendingAction(pendingAction, playerData);
      //remember that there is no current player on state OnRoundEnd //ctx.currentPlayer == undefined
      if (!playerData.isWrecked) {
        ACTIONS_FUNCTIONS[pendingAction.name](G, ctx, undefined, undefined);
      }
    } else {
      return false;
    }
  }

  if (!allPendingMovesCompleted(G)) {
    return false;
  }
  playerData.turnState.state = nextState;
  return true;
}

//-------Scoring functions---------

function scoringPhaseAnnouncement(G: GameState, ctx: Ctx) {
  console.log('~~~~~ Scoring time ~~~~~');
  executeOnRoundEndActions(G, ctx);
  scoreByBetting(G, ctx);
}

function doneShowingScore(G: GameState, ctx: Ctx) {
  G.endRaceRound[G.currentRacingRound] = true;
}

function scoreByBetting(G: GameState, ctx: Ctx) {
  console.log('Score by bets');
  for (let i = 0; i < G.roundBets[G.currentRacingRound].length; i++) {
    const betsOnCard = G.roundBets[G.currentRacingRound][i];
    for (const bettorPlayerID of betsOnCard.bettors) {
      if (betsOnCard.betCard.racer == getFirstPlace(G)) {
        console.log('player ' + bettorPlayerID + ' got it right!, +$' + betsOnCard.betCard.reward);
        addScore(G, ctx, bettorPlayerID.toString(), betsOnCard.betCard.reward, ScoreUpdateCategory.Bet, {
          reason: 'player ' + bettorPlayerID + ' got it right!, +$' + betsOnCard.betCard.reward,
        });
      }
      if (betsOnCard.betCard.racer == getSecondPlace(G)) {
        const weightedBetReward = Math.floor(betsOnCard.betCard.reward / 2);
        console.log('player ' + bettorPlayerID + ' got it kinda right!, +$' + weightedBetReward);
        addScore(G, ctx, bettorPlayerID, weightedBetReward, ScoreUpdateCategory.Bet, {
          reason: 'player ' + bettorPlayerID + ' got it kinda right!, +$' + weightedBetReward,
        });
      }
    }
  }
}

function sendSyncMsg(G: GameState, ctx: Ctx) {
  ctx.effects.action({
    type: 'syncMessage',
  });
}

function setANewRound(G, ctx) {
  G.currentRacingRound++;
  G.placeInRace = 1;
  // ctx.effects.action({
  //   type: 'phaseEnded',
  //   phaseName: 'scoring',
  // });
}

function predictLandOnTile(G: GameState, ctx: Ctx, playerData: PlayerData) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const mapLength = G.map[G.currentRacingRound].length;
  // let landOnTilePrediction = Math.min(playerData.turnState.movementPoints + playerData.locationOnMap, mapLength);
  let remainingMovementPoints = playerData.turnState.movementPoints; //landOnTilePrediction - playerData.locationOnMap;
  let nextTile = playerData.locationOnMap + 1;
  let nextTileMovementCost = getTileMovementCost(G, ctx, playerData, G.map[G.currentRacingRound][nextTile]);
  if (playerData.turnState.movementPoints < 1) {
    return playerData.locationOnMap;
  }
  while (remainingMovementPoints > nextTileMovementCost && nextTile < mapLength - 1) {
    if (
      playerData.racer.hat.ID === 'propeller_hat' &&
      playersAtLocation(playersData, nextTile - 1).length > 0 &&
      nextTile - 1 !== playerData.turnState.startingPoint
    ) {
      remainingMovementPoints = remainingMovementPoints + G.map[G.currentRacingRound][nextTile - 1].MovementCost;
    }
    remainingMovementPoints = remainingMovementPoints - nextTileMovementCost;
    nextTile++;
    nextTileMovementCost = getTileMovementCost(G, ctx, playerData, G.map[G.currentRacingRound][nextTile]);
  }
  return nextTile;
}

//-------Cards functions---------

function infection(G, ctx) {
  logAndDisplay(ctx, 'cough cough.. -1 life');
  subtractLife(G, ctx, G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer], 1);
}

function infect(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  for (let i = 0; i < playersData.length; i++) {
    //for all the racers who share location with the racer that owns the hat
    if (!isCurrentPlayer(i, ctx) && playersData[i].locationOnMap == playersData[ctx.currentPlayer].locationOnMap) {
      logAndDisplay(ctx, 'Plague...');
      // if got bad luck and not infected already
      if (playersData[i].actions.find((action) => action.name === 'infection') === undefined) {
        logAndDisplay(ctx, 'player ' + i + ' got infected');
        ctx.effects.action({
          type: 'floatingAction',
          subtype: 'plague_doctor_hat',
          playerID: parseInt(ctx.currentPlayer),
          message: `plague!`,
          affectedPlayerIDs: [i.toString()],
          amount: 1,
        });
        ctx.effects.action({
          type: 'actionScreen',
          subtype: 'plague_doctor_hat',
          playerID: parseInt(ctx.currentPlayer),
          message: 'plague!',
          affectedPlayerIDs: [i.toString()],
        });
        playersData[i].actions.push({ name: 'infection', type: 'onTurnBegin' });
      }
    }
  }
}

function addPorcupineCheck(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  for (let i = 0; i < playersData.length; i++) {
    //add procupine check for all racers
    if (!isCurrentPlayer(i, ctx)) {
      playersData[i].actions.push({ name: 'porcupineCheck', type: 'onLand' });
    }
  }
}

function goldenBackpackGoldEffect(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound][+ctx.currentPlayer];
  addScore(G, ctx, playersData.playerID.toString(), 3, ScoreUpdateCategory.Card, { cardId: 'golden_backpack' });
}

function addNuclearCarCheck(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  console.log('adding nuclear check to all racers');
  for (let i = 0; i < playersData.length; i++) {
    //add nuclear check for all racers
    if (!isCurrentPlayer(i, ctx)) {
      playersData[i].actions.push({ name: 'nuclearCarCheck', type: 'passOver' });
    }
  }
}

function porcupineCheck(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  for (let i = 0; i < playersData.length; i++) {
    if (
      !isCurrentPlayer(i, ctx) &&
      playersData[i].racer.vehicle.Name == 'Porcupine' &&
      playersData[i].locationOnMap === playersData[ctx.currentPlayer].locationOnMap
    ) {
      subtractLife(G, ctx, playersData[ctx.currentPlayer], playersData[i].attackPower);
      logAndDisplay(ctx, 'player ' + ctx.currentPlayer + ' got porcupined');
    }
  }
}

function nuclearCarCheck(G: GameState, ctx: Ctx) {
  console.log('nuclear attack check');
  const playersData = G.roundPlayerData[G.currentRacingRound];
  for (let i = 0; i < playersData.length; i++) {
    if (
      !isCurrentPlayer(i, ctx) &&
      playersData[i].racer.vehicle.ID === 'nuclear_car' &&
      playersData[i].locationOnMap === playersData[ctx.currentPlayer].locationOnMap
    ) {
      logAndDisplay(ctx, 'Nuclear Attack!');
      attack(G, ctx, i.toString(), [ctx.currentPlayer]);
    }
  }
}

function soBig(G: GameState, ctx: Ctx, attackerID: string, targetsIDs: Array<string>) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  ctx.effects.action({
    type: 'actionScreen',
    subtype: 'mecha',
    playerID: parseInt(ctx.currentPlayer),
    message: 'So Big! push back 1',
    affectedPlayerIDs: targetsIDs,
  });
  for (let i = 0; i < targetsIDs.length; i++) {
    //for all the racers who placed where the monster Truck landed
    if (!isCurrentPlayer(targetsIDs[i], ctx)) {
      move(G, ctx, playersData[targetsIDs[i]], playersData[targetsIDs[i]].locationOnMap - 1);
    }
  }
}

function freeze(G: GameState, ctx: Ctx, attackerID: string, targetsIDs: Array<string>) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  ctx.effects.action({
    type: 'actionScreen',
    subtype: 'freezeRay',
    playerID: parseInt(ctx.currentPlayer),
    message: 'Freeze!',
    affectedPlayerIDs: targetsIDs,
  });
  for (let i = 0; i < targetsIDs.length; i++) {
    if (!isCurrentPlayer(targetsIDs[i], ctx)) {
      playersData[parseInt(targetsIDs[i])].isStunned = true;
      ctx.effects.action({
        type: 'floatingAction',
        subtype: 'stun',
        playerID: parseInt(ctx.currentPlayer),
        message: `Freeze!`,
        affectedPlayerIDs: targetsIDs,
        amount: 1,
      });
    }
  }
}

function outlawBandannaEffect(G: GameState, ctx: Ctx) {
  console.log('activite outlaw bandanna effect');
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  ctx.effects.action({
    type: 'actionScreen',
    subtype: 'outlaw_bandanna',
    playerID: parseInt(ctx.currentPlayer),
    message: 'DOUBLE DAMAGE!',
    affectedPlayerIDs: [],
  });
  updateAttackPower(ctx, playerData);
}

function updateAttackPower(ctx: Ctx, playerData: PlayerData) {
  console.log('old attack power: ' + playerData.attackPower);
  let newCalculatedAttackPower: number = playerData.racer.driver.Attack;
  playerData.attackModifiers.forEach((modifier) => (newCalculatedAttackPower += modifier.amount));
  if (playerData.racer.hat.ID === 'outlaw_bandanna') {
    newCalculatedAttackPower = 2 * newCalculatedAttackPower;
  }
  playerData.attackPower = newCalculatedAttackPower;
  ctx.effects.action({
    type: 'floatingAction',
    subtype: 'players_bar_attack_update',
    playerID: playerData.playerID,
    message: `Attack Update!`,
    affectedPlayerIDs: [],
    amount: playerData.attackPower,
  });
  console.log('new attack power: ' + playerData.attackPower);
}

function subtractLife(G: GameState, ctx: Ctx, targetPlayerData: PlayerData, amountOfLifeToSubtract: number) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  if (targetPlayerData.placeFinished == null && !targetPlayerData.isWrecked) {
    targetPlayerData.life = Math.max(targetPlayerData.life - amountOfLifeToSubtract, 0);
    logAndDisplay(ctx, 'player ' + targetPlayerData.playerID + ' got ' + amountOfLifeToSubtract + ' dmg');
    ctx.effects.action({
      type: 'floatingAction',
      subtype: 'life',
      playerID: parseInt(ctx.currentPlayer),
      message: '',
      affectedPlayerIDs: [targetPlayerData.playerID.toString()],
      amount: -amountOfLifeToSubtract,
    });
    if (targetPlayerData.life <= 0) {
      logAndDisplay(ctx, 'player ' + targetPlayerData.playerID + ' Wrecked');
      ctx.effects.action({
        type: 'floatingAction',
        subtype: 'wrecked',
        playerID: targetPlayerData.playerID,
        message: 'player ' + targetPlayerData.playerID + ' Wrecked',
        affectedPlayerIDs: [targetPlayerData.playerID.toString()],
        amount: 1,
      });
      if (targetPlayerData.racer.hat.ID === 'funny_candles') {
        logAndDisplay(ctx, 'Funny Candles - BOOM!!!');
        ctx.effects.action({
          type: 'actionScreen',
          subtype: 'funny_candles',
          playerID: targetPlayerData.playerID,
          message: `BOOM!!!`,
          affectedPlayerIDs: [],
        });
        for (let i = 0; i < playersData.length; i++) {
          if (
            targetPlayerData.playerID !== playersData[i].playerID &&
            playersData[i].locationOnMap === targetPlayerData.locationOnMap
          ) {
            subtractLife(G, ctx, playersData[i], 10);
          }
          if (
            targetPlayerData.playerID !== playersData[i].playerID &&
            Math.abs(playersData[i].locationOnMap - targetPlayerData.locationOnMap) === 1
          ) {
            subtractLife(G, ctx, playersData[i], 5);
          }
        }
      }
      targetPlayerData.isWrecked = true;
    }
  }
}

function bowTieEffect(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  for (let i = 0; i < playersData.length; i++) {
    if (playersData[i].locationOnMap > playersData[ctx.currentPlayer].locationOnMap) {
      return;
    }
  }
  logAndDisplay(ctx, 'Bow Tie - player ' + ctx.currentPlayer + ' is in the lead, +2 points!');
  addScore(G, ctx, ctx.currentPlayer, 2, ScoreUpdateCategory.Card, { cardId: 'bow_tie' });
}

function overConfidence(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const currentPlayerData = playersData[+ctx.currentPlayer];
  let numberOfPlayersBehind = 0;
  for (let i = 0; i < playersData.length; i++) {
    if (!isCurrentPlayer(i, ctx) && playersData[i].locationOnMap < currentPlayerData.locationOnMap) {
      numberOfPlayersBehind = numberOfPlayersBehind + 1;
    }
  }
  if (numberOfPlayersBehind + 1 === playersData.length) {
    if ('overConfidence' in currentPlayerData.actionAttributes) {
      if (!currentPlayerData.actionAttributes['overConfidence']) {
        currentPlayerData.actionAttributes['overConfidence'] = true;
        ctx.effects.action({
          type: 'actionScreen',
          subtype: 'hareLead',
          playerID: parseInt(ctx.currentPlayer),
          message: `In the lead, - 2 movement!`,
          affectedPlayerIDs: [],
        });
      }
    }

    currentPlayerData.turnState.movementPoints = currentPlayerData.turnState.movementPoints - 2;
    currentPlayerData.turnState.movementModifiers.push({ cardID: 'hare', type: 'movement', amount: -2 });
  } else {
    if ('overConfidence' in currentPlayerData.actionAttributes) {
      if (currentPlayerData.actionAttributes['overConfidence']) {
        currentPlayerData.actionAttributes['overConfidence'] = false;
        ctx.effects.action({
          type: 'actionScreen',
          subtype: 'hareBehind',
          playerID: parseInt(ctx.currentPlayer),
          message: `Is behind, + 2 movement!`,
          affectedPlayerIDs: [],
        });
      }
    } else {
      currentPlayerData.actionAttributes['overConfidence'] = false;
      ctx.effects.action({
        type: 'actionScreen',
        subtype: 'hareBehind',
        playerID: parseInt(ctx.currentPlayer),
        message: `Is behind, + 2 movement!`,
        affectedPlayerIDs: [],
      });
    }
    currentPlayerData.turnState.movementPoints = currentPlayerData.turnState.movementPoints + 2;
    currentPlayerData.turnState.movementModifiers.push({ cardID: 'hare', type: 'movement', amount: 2 });
  }
}

function panic(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  let numberOfPlayersWreck = 0;
  for (let i = 0; i < playersData.length; i++) {
    if (!isCurrentPlayer(i, ctx) && playersData[i].isWrecked) {
      numberOfPlayersWreck = numberOfPlayersWreck + 4;
    }
  }
  if (numberOfPlayersWreck > 0) {
    logAndDisplay(ctx, 'Ambulance - ' + numberOfPlayersWreck + ' racers got wrecked, lets go lets go');
    playersData[ctx.currentPlayer].turnState.movementPoints =
      playersData[ctx.currentPlayer].turnState.movementPoints + numberOfPlayersWreck;
    playersData[ctx.currentPlayer].turnState.movementModifiers.push({
      cardID: 'ambulance',
      type: 'movement',
      amount: numberOfPlayersWreck,
    });
  }
}

function scaredyCat(G: GameState, ctx: Ctx) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  if (playerData.life <= Math.floor(playerData.racer.vehicle.Life / 2)) {
    logAndDisplay(ctx, 'Scaredy Cat - Meow! +2 movement');
    playerData.turnState.movementPoints = playerData.turnState.movementPoints + 2;
    playerData.turnState.movementModifiers.push({ cardID: 'pet_cat', type: 'movement', amount: 2 });
  }
}

function setSpotlight(G: GameState, ctx: Ctx) {
  const currentPlayerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  if (currentPlayerData.placeFinished) {
    return;
  }
  const currentTile = getCurrentTile(G, currentPlayerData);

  currentTile.activeAddOns.push({
    name: 'spotlight',
    by: currentPlayerData.playerID,
    cost: 1,
    actions: [],
  });
  ctx.effects.action({
    type: 'tileAction',
    subtype: 'spotlightAdd',
    playerID: parseInt(ctx.currentPlayer),
    message: `light`,
    affectedTile: currentTile.id,
  });
  logAndDisplay(ctx, `racer ${currentPlayerData.playerID} set a spotlight on tile ${currentTile.id}!`);
}

function removeSpotlight(G: GameState, ctx: Ctx): void {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const currentPlayerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  const currentTile = getCurrentTile(G, currentPlayerData);

  logAndDisplay(ctx, `racer ${currentPlayerData.playerID} removed a spotlight on tile ${currentTile.id}!`);
  ctx.effects.action({
    type: 'tileAction',
    subtype: 'spotlightRemove',
    playerID: parseInt(ctx.currentPlayer),
    message: `no light`,
    affectedTile: currentTile.id,
  });
  currentTile.activeAddOns = currentTile.activeAddOns.filter((addOn) => addOn.name !== 'spotlight');
}

function propellerPassOver(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  let movementBonusProvided: boolean = false;
  for (let i = 0; i < playersData.length; i++) {
    //for all the racers who share location with the racer that owns the hat
    if (
      !isCurrentPlayer(i, ctx) &&
      !movementBonusProvided &&
      playersData[i].locationOnMap == playersData[ctx.currentPlayer].locationOnMap
    ) {
      playersData[ctx.currentPlayer].turnState.movementPoints +=
        G.map[G.currentRacingRound][playersData[ctx.currentPlayer].locationOnMap].MovementCost;
      logAndDisplay(ctx, 'Propeller Hat - flying by, passing this tile didnt cost any movement');
      movementBonusProvided = true;
      ctx.effects.action({
        type: 'floatingAction',
        subtype: 'movement',
        playerID: parseInt(ctx.playerID),
        message: `flying by night with mountain dew`,
        affectedPlayerIDs: [ctx.playerID],
        amount: +1,
      });
      return;
    }
  }
}

function tow(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  if (shareATile(G, ctx) && playersData[ctx.currentPlayer].locationOnMap !== 0) {
    console.log('Would you like to tow a player for 1 dmg?');
    ctx.effects.action({
      type: 'moveScreen',
      subtype: 'red_wagon',
      playerID: parseInt(ctx.currentPlayer),
      message: `Would you like to tow a player for 1 dmg?`,
      affectedPlayerIDs: [],
    });
    addPendingMove('towMove', G, ctx);
  }
}

function playersAtLocation(playersData: PlayerData[], location: number): PlayerData[] {
  return playersData.filter((player) => player.locationOnMap === location);
}

function shareATile(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  return playersAtLocation(playersData, playersData[ctx.currentPlayer].locationOnMap).length > 1;
}

function shareATileWith(G: GameState, ctx: Ctx): Array<string> {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const otherPlayerOnTile = playersAtLocation(playersData, playersData[ctx.currentPlayer].locationOnMap).filter(
    (player) => player.playerID !== +ctx.currentPlayer && !player.isWrecked
  );
  return otherPlayerOnTile.map((player) => player.playerID.toString());
}

function pickALuckyNumber(G: GameState, ctx: Ctx) {
  console.log('Lucky Hat - adding pickALuckyNumberMove to pending moves');
  ctx.effects.action({
    type: 'moveScreen',
    subtype: 'leaf_clover',
    playerID: parseInt(ctx.currentPlayer),
    message: `Pick a lucky number?`,
    affectedPlayerIDs: [],
  });
  addPendingMove('pickALuckyNumberMove', G, ctx);
}

function magnet(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  console.log('Magnet Truck - adding magnetEffect to all players');
  for (let i = 0; i < playersData.length; i++) {
    if (!isCurrentPlayer(i, ctx)) {
      playersData[i].actions.push({ name: 'magnetEffect', type: 'afterRoll' });
    }
  }
}

function setTrooperEffect(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  for (const playerData of playersData) {
    if (playerData.racer.driver.ID !== 'trooper') {
      console.log(`Adding trooper effect to player ${playerData.playerID}`);
      playerData.actions.push({ name: 'trooperEffect', type: RaceTurnStates.OnLand, priority: 1 });
    }
  }
}

function trooperEffect(G: GameState, ctx: Ctx) {
  // ASSUME ONLY ONE Trooper IN THE RACE
  // If player move a 6+, they stunned.
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const playerData = playersData[+ctx.currentPlayer];
  const trooperData = playersData.find((p) => p.racer.driver.ID === 'trooper');

  if (
    playerData.locationOnMap - playerData.turnState.startingPoint > 5 &&
    trooperData.placeFinished === null &&
    !trooperData.isWrecked
  ) {
    playerData.isStunned = true;
    logAndDisplay(ctx, 'player ' + playerData.playerID + ' got stunned after moving 6+ spaces');
    ctx.effects.action({
      type: 'actionScreen',
      subtype: 'trooper',
      playerID: trooperData.playerID,
      message: 'Hold It Right There! Stunned after moving 6+ spaces',
      affectedPlayerIDs: [ctx.currentPlayer],
    });
    ctx.effects.action({
      type: 'floatingAction',
      subtype: 'stun',
      playerID: trooperData.playerID,
      message: `Stun!`,
      affectedPlayerIDs: [ctx.currentPlayer],
      amount: 1,
    });
  }
}

function setGremlinEffect(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  for (const playerData of playersData) {
    if (playerData.racer.driver.ID !== 'gremlin') {
      playerData.actions.push({ name: 'gremlinEffect', type: RaceTurnStates.BeforeMoving, priority: 0 });
    }
  }
}

function gremlinEffect(G: GameState, ctx: Ctx) {
  // ASSUME ONLY ONE GREMLIN IN THE RACE
  // If player rolls a 1, move Gremlin +1
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const playerData = playersData[+ctx.currentPlayer];
  const gremlinData = playersData.find((p) => p.racer.driver.Name === 'Gremlin');
  if (gremlinData === undefined) {
    console.error('No Gremlin found!!!');
    return;
  }

  if (
    playerData.turnState.dieResult === 1 &&
    gremlinData.placeFinished === null &&
    !gremlinData.isWrecked &&
    gremlinData.locationOnMap !== 0
  ) {
    logAndDisplay(ctx, 'Gremlin move +1 because 1 rolled');
    ctx.effects.action({
      type: 'actionScreen',
      subtype: 'gremlin',
      playerID: +ctx.currentPlayer,
      message: 'Gremlin move +1 because 1 rolled',
      affectedPlayerIDs: [],
    });
    move(G, ctx, gremlinData, gremlinData.locationOnMap + 1);
  }
}

function setCheerleader(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  console.log('Cheerleader effect - updating cheerleader state');
  for (let i = 0; i < playersData.length; i++) {
    if (!isCurrentPlayer(i, ctx)) {
      playersData[i].actions.push({ name: 'cheerleaderEffect', type: 'onTurnEnd' });
    }
  }
}

function stunByTheGuitar(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];

  const affectedPlayers = playersData.filter((player, indexPlayerID) => {
    return !isCurrentPlayer(indexPlayerID, ctx) && player.locationOnMap == playersData[ctx.currentPlayer].locationOnMap;
  });

  affectedPlayers.forEach((player) => (player.isStunned = true));

  const affectedPlayerIDs = affectedPlayers.map((player) => `${player.playerID}`);
  const message = `Players: ${affectedPlayerIDs.join(',')} got stunned!`;

  if (affectedPlayers.length > 0) {
    logAndDisplay(ctx, message);
    ctx.effects.action({
      type: 'floatingAction',
      subtype: 'stun',
      playerID: parseInt(ctx.currentPlayer),
      message: `Players: ${affectedPlayerIDs.join(',')} got stunned!`,
      affectedPlayerIDs: affectedPlayerIDs,
      amount: 1,
    });
  }
}

function patienceIsAVirtue(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  for (let i = 0; i < playersData.length; i++) {
    if (!isCurrentPlayer(i, ctx) && playersData[i].locationOnMap == playersData[ctx.currentPlayer].locationOnMap) {
      addScore(G, ctx, ctx.currentPlayer, 1, ScoreUpdateCategory.Card, { cardId: 'turtle_mount' });
    }
  }
}

function elephantEffect(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const playerData = playersData[ctx.currentPlayer];
  const targetsIDs = shareATileWith(G, ctx);
  if (targetsIDs.length > 0) {
    ctx.effects.action({
      type: 'moveScreen',
      subtype: 'elephant',
      playerID: parseInt(ctx.currentPlayer),
      message: `Would you like attack and stun a racer for 1 life?`,
      affectedPlayerIDs: targetsIDs,
    });
    logAndDisplay(ctx, 'Would you like to lose 1 life and attack and stun a racer?');
    addPendingMove('elephantMove', G, ctx);
  }
}

function spiderAttack(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const targetsIDs = shareATileWith(G, ctx);
  if (targetsIDs.length > 0) {
    ctx.effects.action({
      type: 'moveScreen',
      subtype: 'mechanical_spider',
      playerID: parseInt(ctx.currentPlayer),
      message: `Would you like to lose 1 life and reduce the speed of a racer by 1?`,
      affectedPlayerIDs: targetsIDs,
    });
    logAndDisplay(ctx, 'Would you like to lose 1 life and reduce the speed of a racer by 1?');
    addPendingMove('spiderMove', G, ctx);
  }
}

function magnetEffect(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  let magnetPlayerID;
  for (let i = 0; i < playersData.length; i++) {
    if (playersData[i].racer.vehicle.Name == 'UFO') {
      if (playersData[i].isWrecked) {
        return;
      }
      magnetPlayerID = i;
    }
  }
  if (playersData[ctx.currentPlayer].placeFinished === null) {
    if (playersData[magnetPlayerID].locationOnMap > playersData[ctx.currentPlayer].locationOnMap) {
      playersData[ctx.currentPlayer].turnState.movementPoints += 1;
      playersData[ctx.currentPlayer].turnState.movementModifiers.push({
        cardID: 'ufo',
        type: 'movement',
        amount: 1,
      });
    }
    if (playersData[magnetPlayerID].locationOnMap < playersData[ctx.currentPlayer].locationOnMap) {
      playersData[ctx.currentPlayer].turnState.movementPoints -= 1;
      playersData[ctx.currentPlayer].turnState.movementModifiers.push({
        cardID: 'ufo',
        type: 'movement',
        amount: -1,
      });
    }
  }
}

function cheerleaderEffect(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const currentPlayerIndex = Number(ctx.currentPlayer);
  let cheerLeaderPlayerData;
  const winnerPlayerIndex: number | undefined = getFirstPlace(G);
  const currentPlayerData = playersData[currentPlayerIndex];
  const winnerPlayerData = playersData[winnerPlayerIndex];
  const currentPlayerInfo: PlayerInfo = G.players?.[+ctx.currentPlayer];

  if (
    currentPlayerData.isWrecked ||
    !winnerPlayerIndex ||
    !gotToTheFinishLine(G, currentPlayerIndex) ||
    currentPlayerIndex !== winnerPlayerIndex
  ) {
    return;
  }

  const validPlayers = playersData.filter((playerData) => !playerData.isWrecked && !playerData.placeFinished);

  if (validPlayers.length === 0) {
    return;
  }

  const minLocationOnMap = Math.min(...validPlayers.map((player) => player.locationOnMap));

  for (const playerData of validPlayers) {
    if (playerData.racer.driver.Name === 'Cheerleader') {
      cheerLeaderPlayerData = playerData;

      if (minLocationOnMap === cheerLeaderPlayerData.locationOnMap) {
        ctx.effects.action({
          type: 'actionScreen',
          subtype: 'cheerleader',
          playerID: parseInt(ctx.currentPlayer),
          message: 'Go ' + currentPlayerInfo.name + '! Go ' + currentPlayerInfo.name + '! jumping to 2nd place',
          affectedPlayerIDs: [],
        });
        move(G, ctx, cheerLeaderPlayerData, winnerPlayerData.locationOnMap);
        cheerLeaderPlayerData.placeFinished = 2;
      }
    }
  }
}

function stepByStep(G: GameState, ctx: Ctx) {
  G.roundPlayerData[G.currentRacingRound][+ctx.currentPlayer].turnState.movementPoints -= 1;
  G.roundPlayerData[G.currentRacingRound][+ctx.currentPlayer].turnState.movementModifiers.push({
    cardID: 'turtle_mount',
    type: 'movement',
    amount: -1,
  });
}
function goldenBackpackMovementEffect(G: GameState, ctx: Ctx) {
  G.roundPlayerData[G.currentRacingRound][+ctx.currentPlayer].turnState.movementPoints -= 1;
  G.roundPlayerData[G.currentRacingRound][+ctx.currentPlayer].turnState.movementModifiers.push({
    cardID: 'golden_backpack',
    type: 'movement',
    amount: -1,
  });
}

function spiderEffect(G: GameState, ctx: Ctx) {
  logAndDisplay(ctx, 'player ' + ctx.playerID + ' is slowed by the spider');
  ctx.effects.action({
    type: 'floatingAction',
    subtype: 'movement',
    playerID: parseInt(ctx.currentPlayer),
    message: ``,
    affectedPlayerIDs: [],
    amount: -1,
  });
  G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].turnState.movementPoints -= 1;
  G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].turnState.movementModifiers.push({
    cardID: 'mechanical_spider',
    type: 'movement',
    amount: -1,
  });
}

function hoverboardEffect(G: GameState, ctx: Ctx) {
  logAndDisplay(ctx, 'hoverboard + 2 movement');
  ctx.effects.action({
    type: 'floatingAction',
    subtype: 'movement',
    playerID: parseInt(ctx.currentPlayer),
    message: ``,
    affectedPlayerIDs: [],
    amount: +2,
  });
  G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].turnState.movementPoints += 2;
  G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].turnState.movementModifiers.push({
    cardID: 'hover_board',
    type: 'movement',
    amount: +2,
  });
}

function luckCheck(G: GameState, ctx: Ctx) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  if (playerData.actionAttributes.luckyNumber == playerData.turnState.dieResult) {
    logAndDisplay(ctx, 'hell ya! you got another turn');
    ctx.effects.action({
      type: 'actionScreen',
      subtype: 'leaf_clover',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Another Turn!',
      affectedPlayerIDs: [],
    });
    ctx.events.endTurn({ next: ctx.playerID });
  }
}

function bitPower(G: GameState, ctx: Ctx) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  if (playerData.turnState.dieResult === 1) {
    ctx.effects.action({
      type: 'actionScreen',
      subtype: 'robot',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Bit Power! you got another turn + 1 movement permanent',
      affectedPlayerIDs: [],
    });
    if ('bitPower' in playerData.actionAttributes) {
      playerData.actionAttributes['bitPower']++;
    } else {
      playerData.actionAttributes['bitPower'] = 1;
    }
    ctx.events.endTurn({ next: ctx.playerID });
  }
  if ('bitPower' in playerData.actionAttributes) {
    playerData.turnState.movementPoints = playerData.turnState.movementPoints + playerData.actionAttributes['bitPower'];
    G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].turnState.movementModifiers.push({
      cardID: 'robot',
      type: 'movement',
      amount: playerData.actionAttributes['bitPower'],
    });
  }
}

function swordDance(G: GameState, ctx: Ctx) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  if (
    (playerData.turnState.dieResult == 3 || playerData.turnState.dieResult == 4) &&
    getTheLocationOfTheFirstPlayer(G, ctx) > -1
  ) {
    const racerAhead = getTheLocationOfTheFirstPlayer(G, ctx);
    ctx.effects.action({
      type: 'moveScreen',
      subtype: 'dancer',
      playerID: parseInt(ctx.currentPlayer),
      message: `Lose 1 life for relocate to the leader and attack?`,
      affectedPlayerIDs: [racerAhead.toString()],
    });
    addPendingMove('swordDanceMove', G, ctx);
  }
}

function swordDanceMove(G: GameState, ctx: Ctx, dance: boolean) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.playerID];
  if (dance) {
    const racerAhead = getTheLocationOfTheFirstPlayer(G, ctx);
    if (racerAhead > -1) {
      ctx.effects.action({
        type: 'moveAction',
        moveActionDecision: true,
        subtype: 'dancer',
        playerID: parseInt(ctx.currentPlayer),
        message: `Ballon kick`,
        affectedPlayerIDs: [racerAhead.toString()],
      });
      updateFinalDieAndMovement(G, ctx);
      move(G, ctx, playerData, G.roundPlayerData[G.currentRacingRound][racerAhead].locationOnMap);
      playerData.turnState.movementPoints = 0;
      subtractLife(G, ctx, playerData, 1);
      playerData.turnState.state = RaceTurnStates.OnLand;
      logAndDisplay(ctx, 'dancing forward to racer ' + racerAhead + ' and doing 1 dmg');
      subtractLife(G, ctx, G.roundPlayerData[G.currentRacingRound][racerAhead], 1);
    } else {
      return INVALID_MOVE;
    }
  } else {
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: false,
      subtype: 'dancer',
      playerID: parseInt(ctx.currentPlayer),
      message: `Ballon kick`,
      affectedPlayerIDs: [],
    });
  }
  removePendingMove('swordDanceMove', playerData);
  play(G, ctx);
}

function getTheLocationOfThePlayerAhead(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const playerLocation = playersData[ctx.currentPlayer].locationOnMap;
  let playerAheadLocation = G.map[G.currentRacingRound].length;
  let playerAhead = -1;
  let thereIsAPlayerAhead = false;
  for (let i = 0; i < playersData.length; i++) {
    if (
      playersData[i].locationOnMap < playerAheadLocation &&
      playerLocation < playersData[i].locationOnMap &&
      playersData[i].placeFinished === null
    ) {
      playerAheadLocation = playersData[i].locationOnMap;
      thereIsAPlayerAhead = true;
      playerAhead = i;
    }
  }
  return playerAhead;
}

function getTheLocationOfTheFirstPlayer(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const playerLocation = playersData[ctx.currentPlayer].locationOnMap;
  let firstPlayerLocation = -1;
  let firstPlayer = -1;
  for (let i = 0; i < playersData.length; i++) {
    if (
      playersData[i].locationOnMap > firstPlayerLocation &&
      playersData[i].locationOnMap > playerLocation &&
      playersData[i].placeFinished === null
    ) {
      firstPlayerLocation = playersData[i].locationOnMap;
      firstPlayer = i;
    }
  }
  return firstPlayer;
}

function pokerVisorCheck(G: GameState, ctx: Ctx, playerID: string) {
  logAndDisplay(ctx, 'Keychain  - checking if ' + playerID + ' got the bet right');
  for (let i = 0; i < G.roundBets[G.currentRacingRound].length; i++) {
    for (let j = 0; j < G.roundBets[G.currentRacingRound][i].bettors.length; j++) {
      if (playerID === G.roundBets[G.currentRacingRound][i].bettors[j]) {
        if (checkBet(G.roundBets[G.currentRacingRound][i].betCard, G)) {
          logAndDisplay(ctx, 'he got it right!  + $5!');
          addScore(G, ctx, playerID, 5, ScoreUpdateCategory.Card, { cardId: 'keychain' });
        }
      }
    }
  }
}

function bouquet(G: GameState, ctx: Ctx, playerID) {
  const playerData = G.roundPlayerData[G.currentRacingRound][playerID];
  logAndDisplay(ctx, 'Rose bouquet  - checking if ' + playerID + ' got 1st or 2nd');
  if (playerData.placeFinished === 1) {
    logAndDisplay(ctx, 'he got first place!  + $6!');
    addScore(G, ctx, playerID, 6, ScoreUpdateCategory.Card, { cardId: 'rose_bouquet' });
  }
  if (playerData.placeFinished === 2) {
    logAndDisplay(ctx, 'he got 2nd place  + $3!');
    addScore(G, ctx, playerID, 3, ScoreUpdateCategory.Card, { cardId: 'rose_bouquet' });
  }
}

function fallingBehind(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  logAndDisplay(ctx, 'Bindle - checking if falling behind');
  let numberOfPlayersBehind = 0;
  for (let i = 0; i < playersData.length; i++) {
    if (!isCurrentPlayer(i, ctx) && playersData[i].locationOnMap < playersData[ctx.currentPlayer].locationOnMap) {
      numberOfPlayersBehind = numberOfPlayersBehind + 1;
    }
  }
  if (numberOfPlayersBehind == 0) {
    logAndDisplay(ctx, 'Bindle - player ' + ctx.currentPlayer + ' is falling behind, +1 points!');
    addScore(G, ctx, ctx.currentPlayer, 1, ScoreUpdateCategory.Card, { cardId: 'bindle' });
  }
}

function redScarfEffect(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const playerData = playersData[+ctx.currentPlayer];
  logAndDisplay(ctx, 'Red Scarf - checking if falling behind');
  let numberOfPlayersBehind = 0;
  for (let i = 0; i < playersData.length; i++) {
    if (!isCurrentPlayer(i, ctx) && playersData[i].locationOnMap < playersData[ctx.currentPlayer].locationOnMap) {
      numberOfPlayersBehind = numberOfPlayersBehind + 1;
    }
  }
  if (numberOfPlayersBehind == 0) {
    logAndDisplay(ctx, 'Red Scarf - player ' + ctx.currentPlayer + ' is falling behind, +1 movement!');
    ctx.effects.action({
      type: 'actionScreen',
      subtype: 'red_scarf',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Falling behind, +1 movement!',
      affectedPlayerIDs: [],
    });
    if ('redScarf' in playerData.actionAttributes) {
      playerData.actionAttributes['redScarf']++;
    } else {
      playerData.actionAttributes['redScarf'] = 1;
    }
  }
  if ('redScarf' in playerData.actionAttributes) {
    playerData.turnState.movementPoints += playerData.actionAttributes['redScarf'];
    G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].turnState.movementModifiers.push({
      cardID: 'red_scarf',
      type: 'movement',
      amount: playerData.actionAttributes['redScarf'],
    });
  }
}

function moneyFromTheGraves(G, ctx, playerID) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  logAndDisplay(ctx, 'Shovel - getting money from the graves');

  for (let i = 0; i < ctx.numPlayers; i++) {
    if (playersData[i].isWrecked && i !== playerID) {
      logAndDisplay(ctx, 'player ' + i + ' got wrecked, +4 for player ' + playerID);
      addScore(G, ctx, playerID, 4, ScoreUpdateCategory.Card, { cardId: 'shovel' });
    }
  }
}

function fleetFooted(G: GameState, ctx: Ctx) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  if (playerData.turnState.dieResult === 1 || playerData.turnState.dieResult === 2) {
    ctx.effects.action({
      type: 'moveScreen',
      subtype: 'achilles',
      playerID: parseInt(ctx.currentPlayer),
      message: `Would you to lose 1 life for changing the die to 5?`,
      affectedPlayerIDs: [],
    });
    logAndDisplay(ctx, 'Would you to lose 1 life for changing the die to 5?');
    addPendingMove('fleetFootedMove', G, ctx);
  }
}

function experienced(G: GameState, ctx: Ctx) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  if ('experienced' in playerData.actionAttributes) {
    playerData.turnState.movementPoints += playerData.actionAttributes['experienced'];
    G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].turnState.movementModifiers.push({
      cardID: 'adventurer',
      type: 'movement',
      amount: playerData.actionAttributes['experienced'],
    });
    logAndDisplay(ctx, 'Adventurer - got extra ' + playerData.actionAttributes['experienced'] + ' to his movement');
  }
}

function experienceCharge(G: GameState, ctx: Ctx) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  if (!(playerData.turnState.dieResult === 1) && !(playerData.turnState.dieResult === 2)) {
    return;
  }
  ctx.effects.action({
    type: 'moveScreen',
    subtype: 'adventurer',
    playerID: parseInt(ctx.currentPlayer),
    message: `Would you like to skip your turn and gain + 2 movement?`,
    affectedPlayerIDs: [],
  });
  logAndDisplay(ctx, 'Adventurer - would you like to skip your turn and gain + 2 movement?');
  addPendingMove('experienceChargeMove', G, ctx);
}

function duelCharge(G: GameState, ctx: Ctx) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  if (playerData.turnState.dieResult == 1 || playerData.turnState.dieResult == 2) {
    playerData.attackModifiers.push({
      cardID: 'duelist',
      amount: 1,
      type: 'attackPower',
      reason: `Holds anger!`,
    });
    logAndDisplay(ctx, 'Duelist - Holds anger!');
    ctx.effects.action({
      type: 'actionScreen',
      subtype: 'duelist_charge',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Holds anger! +1 attack power',
      affectedPlayerIDs: [],
    });
    updateAttackPower(ctx, playerData);
  }
}

function fuzzyDiceEffect(G: GameState, ctx: Ctx) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  if (playerData.turnState.dieResult !== 3 && playerData.turnState.dieResult !== 4) {
    return;
  }
  ctx.effects.action({
    type: 'moveScreen',
    subtype: 'fuzzy_dice',
    playerID: parseInt(ctx.currentPlayer),
    message: `Loss 1 life to change your roll -+1?`,
    affectedPlayerIDs: [],
  });
  logAndDisplay(ctx, 'fuzzy_dice - Would you like to change your roll?');
  addPendingMove('fuzzyDiceMove', G, ctx);
}

function shortcut(G: GameState, ctx: Ctx) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  if (
    (playerData.turnState.dieResult !== 2 && playerData.turnState.dieResult !== 1) ||
    'map' in playerData.actionAttributes
  ) {
    return;
  }
  ctx.effects.action({
    type: 'moveScreen',
    subtype: 'map',
    playerID: parseInt(ctx.currentPlayer),
    message: `Lose 2 life to change your roll to 6 (one use only)?`,
    affectedPlayerIDs: [],
  });
  logAndDisplay(ctx, 'Map - Lose 2 life to gain to change your roll to 6?');
  addPendingMove('shortcutMove', G, ctx);
}

function jetCarEffect(G: GameState, ctx: Ctx) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  if (
    (playerData.turnState.dieResult !== 5 && playerData.turnState.dieResult !== 6) ||
    'jetCar' in playerData.actionAttributes
  ) {
    return;
  }
  ctx.effects.action({
    type: 'moveScreen',
    subtype: 'jet_car',
    playerID: parseInt(ctx.currentPlayer),
    message: `Lose 2 life to triple your die result, after use, -1 speed permanent? (Usable Once)?`,
    affectedPlayerIDs: [],
  });
  logAndDisplay(ctx, 'Lose 2 life to triple your die result, after use, -1 speed permanent? (Usable Once)');
  addPendingMove('jetCarMove', G, ctx);
}

function plan(G: GameState, ctx: Ctx) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  if (playerData.turnState.dieResult !== 6 && playerData.turnState.dieResult !== 5) {
    return;
  }
  ctx.effects.action({
    type: 'moveScreen',
    subtype: 'strategist',
    playerID: parseInt(ctx.currentPlayer),
    message: `Would you like to change your roll to a lower number for 1 dmg?`,
    affectedPlayerIDs: [],
  });
  logAndDisplay(ctx, 'Strategist - Would you like to change your roll for 1 dmg?');
  addPendingMove('planMove', G, ctx);
}

function pickAHuntSuperstition(G: GameState, ctx: Ctx) {
  ctx.effects.action({
    type: 'moveScreen',
    subtype: 'crystalBall',
    playerID: parseInt(ctx.currentPlayer),
    message: `What place will you finish?`,
    affectedPlayerIDs: [],
  });
  logAndDisplay(ctx, 'Hunt Hat - adding pickACrystalBallMove to pending moves');
  addPendingMove('pickACrystalBallMove', G, ctx);
}

function smoke(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  let racersBehindCounter = 0;
  logAndDisplay(ctx, 'Tooter - checking if need to push someone backward');

  for (let i = 0; i < playersData.length; i++) {
    if (playersData[i].locationOnMap + 1 == playersData[ctx.currentPlayer].locationOnMap && !playersData[i].isWrecked) {
      racersBehindCounter++;
      if (racersBehindCounter === 1) {
        ctx.effects.action({
          type: 'actionScreen',
          subtype: 'tooter',
          playerID: parseInt(ctx.currentPlayer),
          message: 'Smoke! Racers behind pushed back 1-3 spaces',
          affectedPlayerIDs: [],
        });
      }
      const steps = 1 + Math.floor(ctx.random.Number() * 3);
      logAndDisplay(ctx, 'Pushing Racer ' + i + ' backward ' + steps + ' steps');
      move(G, ctx, playersData[i], playersData[i].locationOnMap - steps);
    }
  }
}

function fireBreath(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const targetsIDs: Array<string> = [];
  for (let i = 0; i < playersData.length; i++) {
    if (
      playersData[i].locationOnMap == 1 + playersData[ctx.currentPlayer].locationOnMap ||
      playersData[i].locationOnMap == 2 + playersData[ctx.currentPlayer].locationOnMap
    ) {
      targetsIDs.push(i.toString());
    }
  }
  if (targetsIDs.length > 0) {
    ctx.effects.action({
      type: 'actionScreen',
      subtype: 'dragon',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Fire!',
      affectedPlayerIDs: targetsIDs,
    });
    for (let i = 0; i < targetsIDs.length; i++) {
      subtractLife(G, ctx, playersData[parseInt(targetsIDs[i])], playersData[ctx.currentPlayer].attackPower);
    }
  }
}

function scare(G: GameState, ctx: Ctx, attackerID: string, targetsIDs: Array<string>) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  ctx.effects.action({
    type: 'actionScreen',
    subtype: 'ghost',
    playerID: parseInt(ctx.currentPlayer),
    message: 'Scare!',
    affectedPlayerIDs: targetsIDs,
  });
  for (let i = 0; i < targetsIDs.length; i++) {
    playersData[parseInt(targetsIDs[i])].isStunned = true;
    subtractLife(G, ctx, playersData[targetsIDs[i]], playersData[attackerID].attackPower);
  }
  ctx.effects.action({
    type: 'floatingAction',
    subtype: 'stun',
    playerID: parseInt(ctx.currentPlayer),
    message: `Boo!, stun!`,
    affectedPlayerIDs: targetsIDs,
    amount: 1,
  });
}

function regularAttack(G: GameState, ctx: Ctx, attackerID: string, targetsIDs: Array<string>) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  for (let i = 0; i < targetsIDs.length; i++) {
    subtractLife(G, ctx, playersData[targetsIDs[i]], playersData[attackerID].attackPower);
  }
}

function thump(G: GameState, ctx: Ctx, attackerID: string, targetsIDs: Array<string>) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  ctx.effects.action({
    type: 'actionScreen',
    subtype: 'ogre',
    playerID: parseInt(attackerID),
    message: 'Thump!',
    affectedPlayerIDs: targetsIDs,
  });
  for (let i = 0; i < targetsIDs.length; i++) {
    playersData[parseInt(targetsIDs[i])].isStunned = true;
    subtractLife(G, ctx, playersData[targetsIDs[i]], playersData[attackerID].attackPower);
    const steps = 1 + Math.floor(ctx.random.Number() * 3);
    move(G, ctx, playersData[targetsIDs[i]], playersData[targetsIDs[i]].locationOnMap - steps);
  }
}

function wind(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
  if (playerData.turnState.dieResult !== 1) {
    return;
  }

  const targetPlayers = playersData.filter(
    (p) => !isCurrentPlayer(p.playerID, ctx) && !p.placeFinished && !p.isWrecked
  );
  const targetsIDs = targetPlayers.map((p) => `${p.playerID}`);

  ctx.effects.action({
    type: 'actionScreen',
    subtype: 'wind_god',
    playerID: parseInt(ctx.currentPlayer),
    message: 'Wind',
    affectedPlayerIDs: targetsIDs,
  });
  logAndDisplay(ctx, 'Wind! all other racers pushed 4 spaces back');

  targetPlayers.forEach((p) => {
    if (!p.placeFinished) {
      move(G, ctx, p, Math.max(p.locationOnMap - 4, 0));
    }
  });
}

function attack(G: GameState, ctx: Ctx, attackerID: string, targetsIDs: Array<string>) {
  const attackerData = G.roundPlayerData[G.currentRacingRound][+attackerID];

  //make sure you do not attack at the finish line
  if (gotToTheFinishLine(G, attackerID)) {
    return;
  }

  //filtering attack actions
  let attackActions = attackerData.turnState.pendingActions.filter(
    (action) => action.type == 'attackAction' && (!action.priority || action.priority === 0)
  );
  const highPriorityActions = attackerData.turnState.pendingActions.filter(
    (action) => action.type == 'attackAction' && action.priority === 1
  );
  attackActions = [...highPriorityActions, ...attackActions];

  //execute attack actions
  while (attackActions.length !== 0) {
    if (allPendingMovesCompleted(G)) {
      const action = attackActions.pop();
      removePendingAction(action, attackerData);
      if (!attackerData.isWrecked && !attackerData.isStunned) {
        console.log(`executing attack action: ${action.name}`);
        ACTIONS_FUNCTIONS[action.name](G, ctx, attackerID, targetsIDs);
        if (attackActions.length === 0) {
          ctx.effects.action({
            type: 'floatingAction',
            subtype: 'attack',
            playerID: parseInt(ctx.currentPlayer),
            message: `Attack!`,
            affectedPlayerIDs: targetsIDs,
            amount: attackerData.attackPower,
          });
        }
      }
    } else {
      return;
    }
  }

  attackerData.turnState.pendingActions = attackerData.turnState.pendingActions.concat(
    attackerData.actions.filter((action) => action.type === 'attackAction')
  );
}

function plunder(G: GameState, ctx: Ctx, attackerID: string, targetsIDs: Array<string>) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  ctx.effects.action({
    type: 'actionScreen',
    subtype: 'pirate',
    playerID: parseInt(ctx.currentPlayer),
    message: 'Plunder!',
    affectedPlayerIDs: targetsIDs,
  });
  for (let i = 0; i < targetsIDs.length; i++) {
    if (
      !isCurrentPlayer(targetsIDs[i], ctx) &&
      playersData[ctx.currentPlayer].placeFinished === null &&
      playersData[ctx.currentPlayer].locationOnMap !== 0 &&
      !playersData[i].isWrecked
    ) {
      subtractLife(G, ctx, playersData[targetsIDs[i]], playersData[attackerID].attackPower);
      if (G.scoreByPlayerID[targetsIDs[i]] > 0) {
        addScore(G, ctx, targetsIDs[i].toString(), -1, ScoreUpdateCategory.Card, { cardId: 'pirate' });
        addScore(G, ctx, ctx.currentPlayer, 1, ScoreUpdateCategory.Card, { cardId: 'pirate' });
      }
    }
  }
}

export function getCurrentTile(G: GameState, playerData: PlayerData): Tile {
  return G.map[G.currentRacingRound][playerData.locationOnMap];
}

export function getTileMovementCost(G: GameState, ctx: Ctx, playerData: PlayerData, tile: Tile) {
  const addOnCosts = tile.activeAddOns
    // Your own addOns don't affect you
    .filter((activeAddOn) => activeAddOn.by !== playerData.playerID)
    // This is just sum()
    .reduce((totalCost, addOn) => totalCost + addOn.cost, 0);

  return tile.MovementCost + addOnCosts;
}

export function getPlayerByDriverID(playersData: Array<PlayerData>, driverID: DriverCardID): PlayerData | undefined {
  return playersData.find((playerData) => playerData.racer.driver.ID === driverID);
}

function executeWarmUpFunctions(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  for (const playerData of playersData) {
    const warmUpUpActions = playerData.actions.filter((action) => action.type == RaceTurnStates.WarmUp);

    while (warmUpUpActions.length > 0) {
      const action = warmUpUpActions.pop();
      ACTIONS_FUNCTIONS[action.name](G, ctx, undefined, undefined);
    }
  }
}

function setDieResults(ctx: Ctx, playerData: PlayerData) {
  playerData.turnState.dieResult = roll(ctx);
  playerData.turnState.movementPoints += playerData.turnState.dieResult;
}

function setLeprechaunTrigger(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const leprechaunPlayer = getPlayerByDriverID(playersData, 'leprechaun');

  for (const playerData of playersData) {
    if (playerData.playerID !== leprechaunPlayer.playerID) {
      console.log(`Adding leprechaun effect to player ${playerData.playerID}`);
      playerData.actions.push({ name: 'maybeFeedTheLeprechaun', type: RaceTurnStates.BeforeMoving, priority: 0 });
    }
  }
}

function maybeFeedTheLeprechaun(G: GameState, ctx: Ctx) {
  // ASSUME ONLY ONE LEPRECHAUN IN THE RACE
  // If player rolls a 1, offer them to "feed the leprechaun"
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const currentPlayerData = playersData[+ctx.currentPlayer];
  const leprechaunData = getPlayerByDriverID(playersData, 'leprechaun');
  if (leprechaunData === undefined) {
    console.error('No Leprechaun found!!!');
    return;
  }

  if (currentPlayerData.turnState.dieResult === 1 && leprechaunData.placeFinished === null) {
    addPendingMove('feedTheLeprechaunMove', G, ctx);
    logAndDisplay(ctx, `Would you like to feed the Leprechaun? (You'll re-roll and he'll move +3 next turn)`);
    ctx.effects.action({
      type: 'moveScreen',
      subtype: 'leprechaun',
      playerID: +ctx.currentPlayer,
      message: `Would you like to feed the Leprechaun? (You'll re-roll and he'll move +3 next turn)`,
      affectedPlayerIDs: [`${leprechaunData.playerID}`],
    });
  }
  return;
}

function rubberGirlEffect(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const currentPlayerData = playersData[+ctx.currentPlayer];
  if (currentPlayerData.turnState.dieResult !== 1 && currentPlayerData.turnState.dieResult !== 2) {
    return;
  }
  addPendingMove('rubberGirlMove', G, ctx);
  logAndDisplay(ctx, `Would you like to lose 1 life for a reroll?`);
  ctx.effects.action({
    type: 'moveScreen',
    subtype: 'rubber_girl',
    playerID: +ctx.currentPlayer,
    message: `Would you like to lose 1 life for a reroll?`,
    affectedPlayerIDs: [],
  });
}

function feedTheLeprechaunMove(G: GameState, ctx: Ctx, doFeed: boolean) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const currentPlayerData = playersData[+ctx.currentPlayer];
  const leprechaunData = getPlayerByDriverID(playersData, 'leprechaun');

  if (doFeed) {
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: true,
      subtype: 'leprechaun',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Reroll! Leprechaun got + 3 movement for his next turn',
      affectedPlayerIDs: [],
    });
    // Set the player to Roll state so they will re-roll
    currentPlayerData.turnState.state = RaceTurnStates.Roll;
    leprechaunData.turnState.movementPoints = leprechaunData.turnState.movementPoints + 3;
    leprechaunData.turnState.movementModifiers.push({
      cardID: 'leprechaun',
      amount: 3,
      type: 'movement',
      reason: `Player ${currentPlayerData.playerID} fed the leprechaun`,
    });
  } else {
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: false,
      subtype: 'leprechaun',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Nope, maybe next time',
      affectedPlayerIDs: [],
    });
  }

  removePendingMove('feedTheLeprechaunMove', currentPlayerData);
  play(G, ctx);
}

function rubberGirlMove(G: GameState, ctx: Ctx, reroll: boolean) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const currentPlayerData = playersData[+ctx.currentPlayer];

  if (reroll) {
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: true,
      subtype: 'rubber_girl',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Reroll!',
      affectedPlayerIDs: [],
    });
    // Set the player to Roll state so they will re-roll
    currentPlayerData.turnState.state = RaceTurnStates.Roll;
    subtractLife(G, ctx, currentPlayerData, 1);
  } else {
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: false,
      subtype: 'rubber_girl',
      playerID: +ctx.currentPlayer,
      message: 'Nope',
      affectedPlayerIDs: [],
    });
  }
  removePendingMove('rubberGirlMove', currentPlayerData);
  play(G, ctx);
}

function masterOfDisguiseEffect(G: GameState, ctx: Ctx) {
  const currentPlayerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];

  if (
    (currentPlayerData.turnState.dieResult !== 3 && currentPlayerData.turnState.dieResult !== 4) ||
    currentPlayerData.isWrecked ||
    !!currentPlayerData.placeFinished ||
    currentPlayerData.isStunned
  ) {
    return;
  }
  console.log('Would you like to switch a placer with another racer?');
  ctx.effects.action({
    type: 'moveScreen',
    subtype: 'master_of_disguise',
    playerID: parseInt(ctx.currentPlayer),
    message: `Would you like to lose 1 life and switch a place with another racer?`,
    affectedPlayerIDs: [],
  });
  addPendingMove('masterOfDisguiseMove', G, ctx);
}

function masterOfDisguiseMove(G: GameState, ctx: Ctx, playerId: number) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const currentPlayerData = playersData[ctx.currentPlayer];

  //decided not to use power
  if (playerId < 0) {
    logAndDisplay(ctx, `Master of disguise didn't use his power!`);
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: false,
      subtype: 'master_of_disguise',
      playerID: parseInt(ctx.currentPlayer),
      message: "Master of disguise didn't use his power!",
      affectedPlayerIDs: [],
    });
  } else {
    const tradeWithPlayer = playersData[playerId];
    const moveTo = tradeWithPlayer.locationOnMap;

    if (tradeWithPlayer.placeFinished !== null) {
      return INVALID_MOVE;
    } else {
      ctx.effects.action({
        type: 'moveAction',
        moveActionDecision: false,
        subtype: 'master_of_disguise',
        playerID: parseInt(ctx.currentPlayer),
        message: 'Master of disguise switching places!',
        affectedPlayerIDs: [playerId.toString()],
      });
      subtractLife(G, ctx, currentPlayerData, 1);
      move(G, ctx, tradeWithPlayer, currentPlayerData.locationOnMap);
      move(G, ctx, currentPlayerData, moveTo);
    }
  }
  removePendingMove('masterOfDisguiseMove', currentPlayerData);
  play(G, ctx);
}

function setTrapLayer(G: GameState, ctx: Ctx): void {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const currentPlayerData = playersData[ctx.currentPlayer];

  if (currentPlayerData.placeFinished) {
    return;
  }
  const currentTile = getCurrentTile(G, currentPlayerData);

  currentTile.activeAddOns.push({
    name: 'trapLayer',
    by: currentPlayerData.playerID,
    cost: 0,
    actions: [
      // { name: 'onPassOverTrapLayerEffect', type: 'passOver' },
      {
        name: 'onLandTrapLayerEffect',
        type: 'onLand',
      },
    ],
  });
  ctx.effects.action({
    type: 'tileAction',
    subtype: 'trapLayerAdd',
    playerID: parseInt(ctx.currentPlayer),
    message: `Land a trap`,
    affectedTile: currentTile.id,
  });
  // updateTileSummaryCost(G, currentTile);
  logAndDisplay(ctx, `racer ${currentPlayerData.playerID} is adding a trap layer on tile ${currentTile.id}!`);
}

function onLandTrapLayerEffect(G: GameState, ctx: Ctx): void {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const currentPlayerData = playersData[ctx.currentPlayer];
  const currentTile = getCurrentTile(G, currentPlayerData);

  logAndDisplay(ctx, `racer ${currentPlayerData.playerID} landed on a trap and get 2 damage!`);
  ctx.effects.action({
    type: 'tileAction',
    subtype: 'trapLayerRemove',
    playerID: parseInt(ctx.currentPlayer),
    message: `Land on a trap`,
    affectedTile: currentTile.id,
  });
  subtractLife(G, ctx, currentPlayerData, 2);
  currentTile.activeAddOns = currentTile.activeAddOns.filter(
    (addOn) => addOn.by !== Number(ctx.currentPlayer) && addOn.name !== 'trapLayer'
  );
}

function onPassOverTrapLayerEffect(G: GameState, ctx: Ctx): void {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const currentPlayerData = playersData[ctx.currentPlayer];
  logAndDisplay(ctx, `racer ${currentPlayerData.playerID} landed on a trap and get 1 bump!`);
}

function checkCurrentTileAddOns(G: GameState, ctx: Ctx, currentPlayerData: PlayerData, currentState: string): void {
  const currentTile = getCurrentTile(G, currentPlayerData);
  if (currentTile === undefined || currentTile === null) {
    console.error(`currentTile is undefined! playerData: ${JSON.stringify(currentPlayerData)}`);
    return;
  }
  const { activeAddOns } = currentTile;
  const { state: currentRaceState } = currentPlayerData.turnState;

  if (!activeAddOns || activeAddOns.length === 0) {
    return;
  }

  const allAddOnActions = activeAddOns
    .filter((activeAddOn) => activeAddOn.by !== currentPlayerData.playerID)
    .flatMap((activeAddOn) => activeAddOn.actions.filter((action) => action.type === currentState));

  currentPlayerData.turnState.pendingActions = [...allAddOnActions, ...currentPlayerData.turnState.pendingActions];
  logAndDisplay(
    ctx,
    `racer ${currentPlayerData.playerID} ${
      currentRaceState === RaceTurnStates.OnLand ? 'land on' : 'passes through'
    } the trap layer!`
  );
}

function bump(G: GameState, ctx: Ctx, times = 1) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  let targetsIDs: Array<string>;
  if (shareATile(G, ctx) && !gotToTheFinishLine(G, ctx.currentPlayer)) {
    logAndDisplay(ctx, 'Bump X' + times + '!');
    targetsIDs = shareATileWith(G, ctx);
    ctx.effects.action({
      type: 'actionScreen',
      subtype: 'bumperCar',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Bump X' + times + '!',
      affectedPlayerIDs: targetsIDs,
    });
    for (let i = 0; i < targetsIDs.length; i++) {
      if (
        playersData[parseInt(targetsIDs[i])].placeFinished === null &&
        playersData[parseInt(targetsIDs[i])].locationOnMap !== 0 &&
        !gotToTheFinishLine(G, targetsIDs[i])
      ) {
        subtractLife(G, ctx, playersData[targetsIDs[i]], playersData[ctx.currentPlayer].attackPower);
        if (ctx.random.Number() < 0.5) {
          move(G, ctx, playersData[targetsIDs[i]], playersData[parseInt(targetsIDs[i])].locationOnMap + 1);
        } else {
          move(G, ctx, playersData[targetsIDs[i]], playersData[parseInt(targetsIDs[i])].locationOnMap - 1);
        }
      }
    }
    if (
      playersData[ctx.currentPlayer].placeFinished === null &&
      playersData[ctx.currentPlayer].locationOnMap !== 0 &&
      !gotToTheFinishLine(G, ctx.currentPlayer)
    ) {
      move(G, ctx, playersData[ctx.currentPlayer], playersData[ctx.currentPlayer].locationOnMap + 1);
    }
    bump(G, ctx, times + 1);
  }
}

function duel(G: GameState, ctx: Ctx, attackerID: string, targetsIDs: Array<string>) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  let currentPlayerData = playersData[+ctx.currentPlayer];
  ctx.effects.action({
    type: 'actionScreen',
    subtype: 'duel',
    playerID: parseInt(ctx.currentPlayer),
    message: 'Charge!',
    affectedPlayerIDs: targetsIDs,
  });
  for (let i = 0; i < targetsIDs.length; i++) {
    subtractLife(G, ctx, playersData[parseInt(targetsIDs[i])], currentPlayerData.attackPower);
  }
  currentPlayerData.attackModifiers = currentPlayerData.attackModifiers.filter(
    (modifier) => modifier.cardID !== 'duelist'
  );
  updateAttackPower(ctx, currentPlayerData);
}

function superstitionCheck(G: GameState, ctx: Ctx, playerID: string) {
  logAndDisplay(ctx, 'Hunt Hat - checking if the hunt got lucky');
  const playerData = G.roundPlayerData[G.currentRacingRound][playerID];
  if (playerData.actionAttributes.superstitionNumber === playerData.placeFinished) {
    logAndDisplay(ctx, 'hell ya! +10 points!!!');
    addScore(G, ctx, playerID, 10, ScoreUpdateCategory.Card, { cardId: 'crystal_ball' });
  }
}

function checkBet(betCard: BetCard, G: GameState) {
  return BETCHECK_FUNCTIONS[betCard.type](betCard, G);
}

function toWinBetCheck(betCard: BetCard, G: GameState) {
  return betCard.racer == getFirstPlace(G);
}

function getFirstPlace(G: GameState): number {
  let firstPlaceIndex;
  const playersData = G.roundPlayerData?.[G.currentRacingRound];

  if (!playersData) {
    return;
  }
  for (let i = 0; i < playersData.length; i++) {
    if (playersData[i].placeFinished === 1) {
      firstPlaceIndex = i;
    }
  }
  return firstPlaceIndex;
}

function getSecondPlace(G: GameState) {
  let secondPlaceIndex;
  const playersData = G.roundPlayerData?.[G.currentRacingRound];

  if (!playersData) {
    return;
  }
  for (let i = 0; i < playersData.length; i++) {
    if (playersData[i].placeFinished === 2) {
      secondPlaceIndex = i;
    }
  }
  return secondPlaceIndex;
}

function getRacerPlace(raceResult, playerID) {
  return raceResult[playerID];
}

function isCurrentPlayer(playerID, ctx: Ctx) {
  return Number(playerID) === Number(ctx.currentPlayer);
}

function rollDieMove(G: GameState, ctx: Ctx) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.playerID];
  ctx.effects.action({
    type: 'moveAction',
    moveActionDecision: true,
    subtype: 'die_roll',
    playerID: parseInt(ctx.currentPlayer),
    message: 'Rolling',
    affectedPlayerIDs: [],
  });
  logAndDisplay(ctx, 'Rolling');
  removePendingMove('rollDieMove', playerData);
  play(G, ctx);
}

function rerollDieMove(G: GameState, ctx: Ctx, roll: boolean) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.playerID];
  if (roll) {
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: true,
      subtype: 'die_roll',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Rolling',
      affectedPlayerIDs: [],
    });
    logAndDisplay(ctx, 'Rolling');
    setDieResults(ctx, playerData);
    addScore(G, ctx, ctx.playerID, -3, ScoreUpdateCategory.Card, { cardId: 'die_roll' });
  } else {
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: false,
      subtype: 'die_roll',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Nope',
      affectedPlayerIDs: [],
    });
    logAndDisplay(ctx, 'Nope');
  }
  removePendingMove('rerollDieMove', playerData);
  play(G, ctx);
}

//-------Cards moves---------

function pickALuckyNumberMove(G: GameState, ctx, LuckyNumber: number) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.playerID];
  ctx.effects.action({
    type: 'moveAction',
    moveActionDecision: true,
    subtype: 'leaf_clover',
    playerID: parseInt(ctx.currentPlayer),
    message: 'Lucky number: ' + LuckyNumber.toString(),
    affectedPlayerIDs: [],
  });
  playerData.actions.push({ name: 'luckCheck', type: RaceTurnStates.OnTurnEnd, priority: 0 });
  playerData.turnState.pendingActions.push({ name: 'luckCheck', type: RaceTurnStates.OnTurnEnd, priority: 0 });
  playerData.actionAttributes['luckyNumber'] = LuckyNumber;
  logAndDisplay(ctx, 'Rolling' + LuckyNumber.toString());
  removePendingMove('pickALuckyNumberMove', playerData);
  play(G, ctx);
}

function pickACrystalBallMove(G: GameState, ctx, LuckyNumber: number) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.playerID];
  ctx.effects.action({
    type: 'moveAction',
    moveActionDecision: true,
    subtype: 'crystalBallSelected',
    playerID: parseInt(ctx.currentPlayer),
    message: 'No one knows what the future holds',
    affectedPlayerIDs: [],
  });
  playerData.actions.push({ name: 'superstitionCheck', type: RaceTurnStates.OnRoundEnd });
  playerData.actionAttributes['superstitionNumber'] = LuckyNumber;
  logAndDisplay(ctx, 'gambled on - ' + LuckyNumber.toString());
  removePendingMove('pickACrystalBallMove', playerData);
  play(G, ctx);
}

function fleetFootedMove(G: GameState, ctx, moveFour: boolean) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.playerID];
  if (moveFour) {
    playerData.turnState.movementPoints = playerData.turnState.movementPoints + 4 - playerData.turnState.dieResult;
    playerData.turnState.dieResult = 5;
    logAndDisplay(ctx, 'Changed die to 5');
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: true,
      subtype: 'achilles',
      playerID: parseInt(ctx.currentPlayer),
      message: 'die changed to 5',
      affectedPlayerIDs: [],
    });
    subtractLife(G, ctx, playerData, 1);
  } else {
    logAndDisplay(ctx, 'decided not to change the die');
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: false,
      subtype: 'achilles',
      playerID: parseInt(ctx.currentPlayer),
      message: 'decided not to change the die',
      affectedPlayerIDs: [],
    });
  }
  removePendingMove('fleetFootedMove', playerData);
  play(G, ctx);
}

function shortcutMove(G: GameState, ctx, moveSix: boolean) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.playerID];
  if (moveSix) {
    logAndDisplay(ctx, 'Map - Moving 6');
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: true,
      subtype: 'map',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Die changed to 6',
      affectedPlayerIDs: [],
    });
    playerData.turnState.movementPoints = playerData.turnState.movementPoints + 6 - playerData.turnState.dieResult;
    playerData.turnState.dieResult = 6;
    playerData.actionAttributes['map'] = true;
    subtractLife(G, ctx, playerData, 2);
  } else {
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: false,
      subtype: 'map',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Nothing changed',
      affectedPlayerIDs: [],
    });
  }
  removePendingMove('shortcutMove', playerData);
  play(G, ctx);
}

function jetCarMove(G: GameState, ctx, moveSix: boolean) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.playerID];
  if (moveSix) {
    logAndDisplay(ctx, 'Jet Car - die tripled');
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: true,
      subtype: 'jet_car',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Die tripled!',
      affectedPlayerIDs: [],
    });
    playerData.turnState.movementPoints = playerData.turnState.movementPoints + playerData.turnState.dieResult * 2;
    playerData.turnState.dieResult = playerData.turnState.dieResult * 3;
    playerData.actionAttributes['jetCar'] = true;
    subtractLife(G, ctx, playerData, 2);
  } else {
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: false,
      subtype: 'jet_car',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Nothing changed',
      affectedPlayerIDs: [],
    });
  }
  removePendingMove('jetCarMove', playerData);
  play(G, ctx);
}

function elephantMove(G: GameState, ctx, playerToStun: number) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.playerID];
  const effectedPlayerData = G.roundPlayerData[G.currentRacingRound][playerToStun];
  if (playerToStun > -1) {
    if (
      playerData.locationOnMap !== G.roundPlayerData[G.currentRacingRound][playerToStun].locationOnMap ||
      G.roundPlayerData[G.currentRacingRound][playerToStun].isWrecked
    ) {
      return INVALID_MOVE;
    }
    logAndDisplay(ctx, 'Elephant stunned player: ' + playerToStun);
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: true,
      subtype: 'elephant',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Trumpet!',
      affectedPlayerIDs: [playerToStun.toString()],
    });
    ctx.effects.action({
      type: 'floatingAction',
      subtype: 'stun',
      playerID: parseInt(ctx.currentPlayer),
      message: `Stun!`,
      affectedPlayerIDs: [playerToStun.toString()],
      amount: 1,
    });
    effectedPlayerData.isStunned = true;
    subtractLife(G, ctx, playerData, 1);
    subtractLife(G, ctx, effectedPlayerData, playerData.attackPower);
  } else {
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: false,
      subtype: 'elephant',
      playerID: parseInt(ctx.currentPlayer),
      message: "Didn't stun any player",
      affectedPlayerIDs: [],
    });
  }
  removePendingMove('elephantMove', playerData);
  play(G, ctx);
}

function spiderMove(G: GameState, ctx, playerToSlow: number) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.playerID];
  const effectedPlayerData = G.roundPlayerData[G.currentRacingRound][playerToSlow];
  if (playerToSlow > -1) {
    if (
      playerData.locationOnMap !== G.roundPlayerData[G.currentRacingRound][playerToSlow].locationOnMap ||
      G.roundPlayerData[G.currentRacingRound][playerToSlow].isWrecked
    ) {
      return INVALID_MOVE;
    }
    logAndDisplay(ctx, 'Spider slowed player: ' + playerToSlow);
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: true,
      subtype: 'mechanical_spider',
      playerID: parseInt(ctx.playerID),
      message: 'Slow!',
      affectedPlayerIDs: [playerToSlow.toString()],
    });
    ctx.effects.action({
      type: 'floatingAction',
      subtype: 'movement',
      playerID: parseInt(ctx.playerID),
      message: `Slow!`,
      affectedPlayerIDs: [playerToSlow.toString()],
      amount: -1,
    });
    subtractLife(G, ctx, playerData, 1);
    effectedPlayerData.actions.push({ name: 'spiderEffect', type: RaceTurnStates.AfterRoll });
  } else {
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: false,
      subtype: 'mechanical_spider ',
      playerID: parseInt(ctx.currentPlayer),
      message: "Didn't slow any player",
      affectedPlayerIDs: [],
    });
  }
  removePendingMove('spiderMove', playerData);
  play(G, ctx);
}

function planMove(G: GameState, ctx: Ctx, newDie: number) {
  const playerData = G.roundPlayerData[G.currentRacingRound][+ctx.playerID];
  if (newDie > -1) {
    logAndDisplay(ctx, 'Strategist - changed roll to: ' + newDie);
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: true,
      subtype: 'plan',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Die changed to ' + newDie + '!',
      affectedPlayerIDs: [],
    });
    playerData.turnState.movementPoints = playerData.turnState.movementPoints + newDie - playerData.turnState.dieResult;
    playerData.turnState.dieResult = newDie;
    subtractLife(G, ctx, playerData, 1);
  } else {
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: false,
      subtype: 'plan',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Nothing changed',
      affectedPlayerIDs: [],
    });
  }
  removePendingMove('planMove', playerData);
  play(G, ctx);
}

function fuzzyDiceMove(G: GameState, ctx: Ctx, newDie: number) {
  const playerData = G.roundPlayerData[G.currentRacingRound][+ctx.playerID];
  if (newDie > -1) {
    logAndDisplay(ctx, 'fuzzy Dice - changed roll to: ' + newDie);
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: true,
      subtype: 'fuzzy_dice',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Die changed to ' + newDie + '!',
      affectedPlayerIDs: [],
    });
    playerData.turnState.movementPoints = playerData.turnState.movementPoints + newDie - playerData.turnState.dieResult;
    playerData.turnState.dieResult = newDie;
    subtractLife(G, ctx, playerData, 1);
  } else {
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: false,
      subtype: 'fuzzy_dice',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Nothing changed',
      affectedPlayerIDs: [],
    });
  }
  removePendingMove('fuzzyDiceMove', playerData);
  play(G, ctx);
}

function experienceChargeMove(G: GameState, ctx, gainExperience: boolean) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.playerID];
  if (gainExperience) {
    logAndDisplay(ctx, 'Adventerer - gaining experince');
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: true,
      subtype: 'adventurer',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Gain experience!',
      affectedPlayerIDs: [],
    });
    if (!('experienced' in playerData.actionAttributes)) {
      playerData.actionAttributes['experienced'] = 0;
    }
    playerData.actionAttributes['experienced'] = playerData.actionAttributes['experienced'] + 2;
    removePendingMove('experienceChargeMove', playerData);
    updateFinalDieAndMovement(G, ctx);
    ctx.events.endTurn();
    return;
  } else {
    logAndDisplay(ctx, 'Adventerer - gaining experince');
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: false,
      subtype: 'adventurer',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Lets go lets go',
      affectedPlayerIDs: [],
    });
    removePendingMove('experienceChargeMove', playerData);
    play(G, ctx);
  }
}

function towMove(G: GameState, ctx, playerToTow: number) {
  const playerData = G.roundPlayerData[G.currentRacingRound][ctx.playerID];
  if (playerToTow > -1) {
    if (
      playerData.locationOnMap !== G.roundPlayerData[G.currentRacingRound][playerToTow].locationOnMap ||
      G.roundPlayerData[G.currentRacingRound][playerToTow].isWrecked
    ) {
      return INVALID_MOVE;
    }
    logAndDisplay(ctx, 'player ' + ctx.playerID + ' decided to tow player ' + playerToTow);
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: true,
      subtype: 'red_wagon',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Towing...',
      affectedPlayerIDs: [playerToTow.toString()],
    });
    playerData.turnState.pendingActions.push({ name: 'towPlayer', type: RaceTurnStates.OnLand });
    playerData.actionAttributes['playerToTow'] = playerToTow;
    subtractLife(G, ctx, playerData, 1);
  } else {
    ctx.effects.action({
      type: 'moveAction',
      moveActionDecision: false,
      subtype: 'red_wagon',
      playerID: parseInt(ctx.currentPlayer),
      message: 'Towing...',
      affectedPlayerIDs: [playerToTow.toString()],
    });
    logAndDisplay(ctx, 'player ' + ctx.playerID + ' decided not to tow anyone');
  }
  removePendingMove('towMove', playerData);
  play(G, ctx);
}

function towPlayer(G: GameState, ctx: Ctx) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const playerToTow = playersData[ctx.currentPlayer].actionAttributes['playerToTow'];
  logAndDisplay(ctx, 'towing...');
  move(G, ctx, playersData[playerToTow], playersData[ctx.currentPlayer].locationOnMap);
  playersData[playerToTow].isStunned = true;
}

export function removePendingMove(moveName: PossibleMove, playerData: PlayerData) {
  console.log('removing move: ' + moveName + ', from: ' + JSON.stringify(playerData.turnState.pendingMoves));
  const index = playerData.turnState.pendingMoves.indexOf(moveName);
  if (index > -1) {
    playerData.turnState.pendingMoves.splice(index, 1);
  }
}

function removePendingAction(action: Action, playerData: PlayerData) {
  console.log('removing action: ' + action.name + ', from: ' + JSON.stringify(playerData.turnState.pendingActions));
  const index = playerData.turnState.pendingActions.indexOf(action);
  if (index > -1) {
    playerData.turnState.pendingActions.splice(index, 1);
  }
}

export function addPendingMove(moveName: PossibleMove, G: GameState, ctx: Ctx, playerID?: number) {
  const playersData = G.roundPlayerData[G.currentRacingRound];
  const addToPlayerId = playerID || +ctx.currentPlayer;
  const addToPlayer = playersData[addToPlayerId];

  addToPlayer.turnState.pendingMoves.push(moveName);

  console.log(moveName + ' added to pending moves');
  console.log(JSON.stringify(addToPlayer.turnState.pendingMoves));
}
export function calculateNextPlayerTurn(G: GameState, ctx: Ctx) {
  console.log('================ Turn Order Calculation ================');
  console.log('playOrder: ' + JSON.stringify(G.calculatedTurnOrder));
  console.log('Current player: ' + ctx.currentPlayer);
  const playersData = G.roundPlayerData[G.currentRacingRound];
  for (let i = 1; i < ctx.numPlayers + 1; i++) {
    let nextPlayerOrderPos = (ctx.playOrderPos + i) % ctx.numPlayers;
    let nextPlayerID = parseInt(G.calculatedTurnOrder[nextPlayerOrderPos]);
    if (!playersData[nextPlayerID].isWrecked && !playersData[nextPlayerID].placeFinished) {
      console.log('Next player: ' + nextPlayerID);
      return nextPlayerOrderPos;
    } else {
      console.log(nextPlayerID + ' is wrecked or finish the race, looking for a next player');
    }
  }
  console.log('Couldnt find next player!!');
  return ctx.playOrderPos;
}

//-------------------Cooldown support, currently out of the game-------------------------------//
// function experienced(G: GameState, ctx: Ctx) {
//   const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
//   if ('experienced' in playerData.actionAttributes) {
//     playerData.turnState.movementPoints += playerData.actionAttributes['experienced'];
//     G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer].turnState.movementModifiers.push({
//       cardName: 'adventurer',
//       type: 'movement',
//       amount: playerData.actionAttributes['experienced'],
//     });
//     logAndDisplay(ctx, 'Adventurer - got extra ' + playerData.actionAttributes['experienced'] + ' to his movement');
//   }
// }

// function experienceCharge(G: GameState, ctx: Ctx) {
//   const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
//   if ('adventurer' in playerData.actionCooldowns) {
//     if (playerData.actionCooldowns['adventurer'].currentCoolDown !== 0) {
//       return;
//     }
//   }
//   ctx.effects.action({
//     type: 'moveScreen',
//     subtype: 'adventurer',
//     playerID: parseInt(ctx.currentPlayer),
//     message: `Would you like to skip your turn and gain + 2 movement?`,
//     affectedPlayerIDs: [],
//   });
//   logAndDisplay(ctx, 'Adventurer - would you like to skip your turn and gain + 2 movement?');
//   addPendingMove('experienceChargeMove', G, ctx);
// }
// function masterOfDisguiseEffect(G: GameState, ctx: Ctx) {
//   const currentPlayerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];

//   if ('master_of_disguise' in currentPlayerData.actionCooldowns) {
//     if (currentPlayerData.actionCooldowns['master_of_disguise'].currentCoolDown !== 0) {
//       return;
//     }
//   }

//   if (currentPlayerData.isWrecked || !!currentPlayerData.placeFinished || currentPlayerData.isStunned) {
//     return;
//   }
//   console.log('Would you like to switch a placer with another racer?');
//   ctx.effects.action({
//     type: 'moveScreen',
//     subtype: 'master_of_disguise',
//     playerID: parseInt(ctx.currentPlayer),
//     message: `Would you like to switch a placer with another racer?`,
//     affectedPlayerIDs: [],
//   });
//   addPendingMove('masterOfDisguiseMove', G, ctx);
// }

// function masterOfDisguiseMove(G: GameState, ctx: Ctx, playerId: number) {
//   const playersData = G.roundPlayerData[G.currentRacingRound];
//   const currentPlayerData = playersData[ctx.currentPlayer];

//   //decided not to use power
//   if (playerId < 0) {
//     logAndDisplay(ctx, `Master of disguise didn't use his power!`);
//     ctx.effects.action({
//       type: 'moveAction',
//       moveActionDecision: false,
//       subtype: 'master_of_disguise',
//       playerID: parseInt(ctx.currentPlayer),
//       message: "Master of disguise didn't use his power!",
//       affectedPlayerIDs: [],
//     });
//   } else {
//     const tradeWithPlayer = playersData[playerId];
//     const moveTo = tradeWithPlayer.locationOnMap;

//     if (tradeWithPlayer.placeFinished !== null) {
//       return INVALID_MOVE;
//     } else {
//       ctx.effects.action({
//         type: 'moveAction',
//         moveActionDecision: false,
//         subtype: 'master_of_disguise',
//         playerID: parseInt(ctx.currentPlayer),
//         message: 'Master of disguise switching places!',
//         affectedPlayerIDs: [playerId.toString()],
//       });
//       move(G, ctx, tradeWithPlayer, currentPlayerData.locationOnMap);
//       move(G, ctx, currentPlayerData, moveTo);

//       if (!('master_of_disguise' in currentPlayerData.actionCooldowns)) {
//         const cooldown: CoolDown = {
//           currentCoolDown: 3,
//           maxCoolDown: 3,
//         };
//         currentPlayerData.actionCooldowns['master_of_disguise'] = cooldown;
//       }
//       currentPlayerData.actionCooldowns['master_of_disguise'].currentCoolDown = 3;
//     }
//   }
//    removePendingMove('masterOfDisguiseMove', currentPlayerData);
//    play(G, ctx);
//  }
// function planMove(G: GameState, ctx: Ctx, newDie: number) {
//   const playerData = G.roundPlayerData[G.currentRacingRound][+ctx.playerID];
//   if (newDie > -1) {
//     logAndDisplay(ctx, 'Strategist - changed roll to: ' + newDie);
//     ctx.effects.action({
//       type: 'moveAction',
//       moveActionDecision: true,
//       subtype: 'plan',
//       playerID: parseInt(ctx.currentPlayer),
//       message: 'Die changed to ' + newDie + '!',
//       affectedPlayerIDs: [],
//     });
//     playerData.turnState.movementPoints = playerData.turnState.movementPoints + newDie - playerData.turnState.dieResult;
//     playerData.turnState.dieResult = newDie;
//     if (!('strategist' in playerData.actionCooldowns)) {
//       const cooldown: CoolDown = {
//         currentCoolDown: 3,
//         maxCoolDown: 3,
//       };
//       playerData.actionCooldowns['strategist'] = cooldown;
//     }
//     playerData.actionCooldowns['strategist'].currentCoolDown = 3;
//   } else {
//     ctx.effects.action({
//       type: 'moveAction',
//       moveActionDecision: false,
//       subtype: 'plan',
//       playerID: parseInt(ctx.currentPlayer),
//       message: 'Nothing changed',
//       affectedPlayerIDs: [],
//     });
//   }
//   removePendingMove('planMove', playerData);
//   play(G, ctx);
// }
// function plan(G: GameState, ctx: Ctx) {
//   const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
//   if ('strategist' in playerData.actionCooldowns) {
//     if (playerData.actionCooldowns['strategist'].currentCoolDown !== 0 || playerData.turnState.dieResult === 1) {
//       return;
//     }
//   }
//   ctx.effects.action({
//     type: 'moveScreen',
//     subtype: 'strategist',
//     playerID: parseInt(ctx.currentPlayer),
//     message: `Would you like to change your roll to a lower number?`,
//     affectedPlayerIDs: [],
//   });
//   logAndDisplay(ctx, 'Strategist - Would you like to change your roll?');
//   addPendingMove('planMove', G, ctx);
// }
// function tow(G: GameState, ctx: Ctx) {
//   const playersData = G.roundPlayerData[G.currentRacingRound];
//   if (shareATile(G, ctx) && playersData[ctx.currentPlayer].locationOnMap !== 0) {
//     if ('red_wagon' in playersData[ctx.currentPlayer].actionCooldowns) {
//       if (playersData[ctx.currentPlayer].actionCooldowns['red_wagon'].currentCoolDown !== 0) {
//         return;
//       }
//     }
//     addPendingMove('towMove', G, ctx);
//   }
// }
// function towMove(G: GameState, ctx, playerToTow: number) {
//   const playerData = G.roundPlayerData[G.currentRacingRound][ctx.playerID];
//   if (playerToTow > -1) {
//     if (playerData.locationOnMap !== G.roundPlayerData[G.currentRacingRound][playerToTow].locationOnMap) {
//       return INVALID_MOVE;
//     }
//     if (!('red_wagon' in playerData.actionCooldowns)) {
//       const cooldown: CoolDown = {
//         currentCoolDown: 3,
//         maxCoolDown: 3,
//       };
//       playerData.actionCooldowns['red_wagon'] = cooldown;
//     }
//     playerData.actionCooldowns['red_wagon'].currentCoolDown = 3;
//     ctx.effects.action({
//       type: 'actionScreen',
//       subtype: 'towTruck',
//       playerID: parseInt(ctx.currentPlayer),
//       message: 'Towing...',
//       affectedPlayerIDs: [playerToTow.toString()],
//     });
//     playerData.turnState.pendingActions.push({ name: 'towPlayer', type: RaceTurnStates.OnLand });
//     playerData.actionAttributes['playerToTow'] = playerToTow;
//   } else {
//     logAndDisplay(ctx, 'player ' + ctx.playerID + ' decided not to tow anyone');
//   }
//   removePendingMove('towMove', playerData);
//   play(G, ctx);
// }
// function elephantEffect(G: GameState, ctx: Ctx) {
//   const playersData = G.roundPlayerData[G.currentRacingRound];
//   const playerData = playersData[ctx.currentPlayer];
//   const targetsIDs = shareATileWith(G, ctx);
//   if (targetsIDs.length > 0) {
//     for (let i = 0; i < targetsIDs.length; i++) {
//       subtractLife(G, ctx, playersData[targetsIDs[i]], 1);
//     }
//     if ('elephant' in playerData.actionCooldowns) {
//       if (playerData.actionCooldowns['elephant'].currentCoolDown !== 0) {
//         return;
//       }
//     }
//     ctx.effects.action({
//       type: 'moveScreen',
//       subtype: 'elephant',
//       playerID: parseInt(ctx.currentPlayer),
//       message: `Trumpet: would you like to stun a racer?`,
//       affectedPlayerIDs: targetsIDs,
//     });
//     logAndDisplay(ctx, 'Trumpet: would you like to  stun a racer?');
//     addPendingMove('elephantMove', G, ctx);
//   }
// }
// function elephantMove(G: GameState, ctx, playerToStun: number) {
//   const playerData = G.roundPlayerData[G.currentRacingRound][ctx.playerID];
//   const effectedPlayerData = G.roundPlayerData[G.currentRacingRound][playerToStun];
//   if (playerToStun > -1) {
//     logAndDisplay(ctx, 'Elephant stunned player: ' + playerToStun);
//     ctx.effects.action({
//       type: 'moveAction',
//       moveActionDecision: true,
//       subtype: 'elephant',
//       playerID: parseInt(ctx.currentPlayer),
//       message: 'Stun!',
//       affectedPlayerIDs: [playerToStun.toString()],
//     });
//     ctx.effects.action({
//       type: 'floatingAction',
//       subtype: 'stun',
//       playerID: parseInt(ctx.currentPlayer),
//       message: `Stun!`,
//       affectedPlayerIDs: [playerToStun.toString()],
//       amount: 1,
//     });
//     effectedPlayerData.isStunned = true;
//     if (!('elephant' in playerData.actionCooldowns)) {
//       const cooldown: CoolDown = {
//         currentCoolDown: 3,
//         maxCoolDown: 3,
//       };
//       playerData.actionCooldowns['elephant'] = cooldown;
//     }
//     playerData.actionCooldowns['elephant'].currentCoolDown = 3;
//   } else {
//     ctx.effects.action({
//       type: 'moveAction',
//       moveActionDecision: false,
//       subtype: 'elephant',
//       playerID: parseInt(ctx.currentPlayer),
//       message: "Didn't stun any player",
//       affectedPlayerIDs: [],
//     });
//   }
//   removePendingMove('elephantMove', playerData);
//   play(G, ctx);
// }
// function shortcut(G: GameState, ctx: Ctx) {
//   const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
//   if ('map' in playerData.actionCooldowns) {
//     if (playerData.actionCooldowns['map'].currentCoolDown !== 0 || playerData.turnState.dieResult === 6) {
//       return;
//     }
//   }
//   ctx.effects.action({
//     type: 'moveScreen',
//     subtype: 'map',
//     playerID: parseInt(ctx.currentPlayer),
//     message: `Would you like to change your roll to 6?`,
//     affectedPlayerIDs: [],
//   });
//   logAndDisplay(ctx, 'Map - Would you like to change your roll to 6?');
//   addPendingMove('shortcutMove', G, ctx);
// }
// function shortcutMove(G: GameState, ctx, moveSix: boolean) {
//   const playerData = G.roundPlayerData[G.currentRacingRound][ctx.playerID];
//   if (moveSix) {
//     logAndDisplay(ctx, 'Map - Moving 6');
//     ctx.effects.action({
//       type: 'moveAction',
//       moveActionDecision: true,
//       subtype: 'map',
//       playerID: parseInt(ctx.currentPlayer),
//       message: 'Die changed to 6',
//       affectedPlayerIDs: [],
//     });
//     playerData.turnState.movementPoints = playerData.turnState.movementPoints + 6 - playerData.turnState.dieResult;
//     playerData.turnState.dieResult = 6;
//     if (!('map' in playerData.actionCooldowns)) {
//       const cooldown: CoolDown = {
//         currentCoolDown: 3,
//         maxCoolDown: 3,
//       };
//       playerData.actionCooldowns['map'] = cooldown;
//     }
//     playerData.actionCooldowns['map'].currentCoolDown = 3;
//   } else {
//     ctx.effects.action({
//       type: 'moveAction',
//       moveActionDecision: false,
//       subtype: 'map',
//       playerID: parseInt(ctx.currentPlayer),
//       message: 'Nothing changed',
//       affectedPlayerIDs: [],
//     });
//   }
//   removePendingMove('shortcutMove', playerData);
//   play(G, ctx);
// }

// function fuzzyDiceMove(G: GameState, ctx: Ctx, newDie: number) {
//   const playerData = G.roundPlayerData[G.currentRacingRound][+ctx.playerID];
//   if (newDie > -1) {
//     logAndDisplay(ctx, 'fuzzy Dice - changed roll to: ' + newDie);
//     ctx.effects.action({
//       type: 'moveAction',
//       moveActionDecision: true,
//       subtype: 'fuzzy_dice',
//       playerID: parseInt(ctx.currentPlayer),
//       message: 'Die changed to ' + newDie + '!',
//       affectedPlayerIDs: [],
//     });
//     playerData.turnState.movementPoints = playerData.turnState.movementPoints + newDie - playerData.turnState.dieResult;
//     playerData.turnState.dieResult = newDie;
//     if (!('fuzzy_dice' in playerData.actionCooldowns)) {
//       const cooldown: CoolDown = {
//         currentCoolDown: 3,
//         maxCoolDown: 3,
//       };
//       playerData.actionCooldowns['fuzzy_dice'] = cooldown;
//     }
//     playerData.actionCooldowns['fuzzy_dice'].currentCoolDown = 3;
//   } else {
//     ctx.effects.action({
//       type: 'moveAction',
//       moveActionDecision: false,
//       subtype: 'fuzzy_dice',
//       playerID: parseInt(ctx.currentPlayer),
//       message: 'Nothing changed',
//       affectedPlayerIDs: [],
//     });
//   }
//   removePendingMove('fuzzyDiceMove', playerData);
//   play(G, ctx);
// }
// function fuzzyDiceEffect(G: GameState, ctx: Ctx) {
//   const playerData = G.roundPlayerData[G.currentRacingRound][ctx.currentPlayer];
//   if ('fuzzy_dice' in playerData.actionCooldowns) {
//     if (playerData.actionCooldowns['fuzzy_dice'].currentCoolDown !== 0) {
//       return;
//     }
//   }
//   ctx.effects.action({
//     type: 'moveScreen',
//     subtype: 'fuzzy_dice',
//     playerID: parseInt(ctx.currentPlayer),
//     message: `Would you like to change your roll?`,
//     affectedPlayerIDs: [],
//   });
//   logAndDisplay(ctx, 'fuzzy_dice - Would you like to change your roll?');
//   addPendingMove('fuzzyDiceMove', G, ctx);
// }
