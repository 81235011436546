import React, { CSSProperties, ReactChild } from 'react';
import { NAMES_TO_COLORS } from '@magicyard/wreckless-game/src/Game';
import blueBanner from './assets/header-container-blue.png';
import greenBanner from './assets/header-container-green.png';
import orangeBanner from './assets/header-container-orange.png';
import pinkBanner from './assets/header-container-pink.png';
import purpleBanner from './assets/header-container-purple.png';
import redBanner from './assets/header-container-red.png';
import './Banner.css';

const ColorWithSecondary: Record<keyof typeof NAMES_TO_COLORS, { primary: string; secondary: string; accent: string }> =
  {
    blue: { primary: '#a4b3ff', secondary: '#c2cbff', accent: '#000d52' },
    green: { primary: '#79e176', secondary: '#8ffb8f', accent: '#023600' },
    orange: { primary: '#e3ab78', secondary: '#fcc38f', accent: '#5d2e00' },
    pink: { primary: '#f88ce0', secondary: '#ffb0ed', accent: '#5a0046' },
    purple: { primary: '#c5a2ff', secondary: '#d8c1ff', accent: '#35008d' },
    red: { primary: '#f99a99', secondary: '#ffbabb', accent: '#600100' },
  };

const getTextShadow = (accent: string) =>
  `0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}, 0 0 2px ${accent}`;

export const TitleBanner = ({
  colorName,
  children,
}: {
  colorName: keyof typeof NAMES_TO_COLORS;
  children?: ReactChild;
}) => {
  const { primary, secondary, accent } = ColorWithSecondary[colorName];
  return (
    <div style={{ textShadow: getTextShadow(accent), backgroundColor: primary }} className={'title-banner_root'}>
      <div className={'title-banner_line title-banner_line-top'} style={{ backgroundColor: secondary }}></div>
      {children}
      <div className={'title-banner_line title-banner_line-bottom'} style={{ backgroundColor: secondary }}></div>
    </div>
  );
};

export const colorToBanner: Record<keyof typeof NAMES_TO_COLORS, string> = {
  blue: blueBanner,
  green: greenBanner,
  orange: orangeBanner,
  pink: pinkBanner,
  purple: purpleBanner,
  red: redBanner,
};
export const Banner = ({
  colorName,
  children,
  partial,
  style,
  size,
}: {
  colorName: keyof typeof NAMES_TO_COLORS;
  children?: ReactChild;
  partial?: boolean;
  style?: CSSProperties;
  size?: 'sm' | 'md';
}) => {
  const { accent } = ColorWithSecondary[colorName];
  return (
    <div
      className={`banner_root banner_root-${size ?? 'md'} ${partial === true ? 'banner_partial' : ''}`}
      style={{ backgroundImage: `url(${colorToBanner[colorName]})`, ...style }}
    >
      {partial === true ? (
        <div
          className={'banner_partial-text'}
          style={{
            textShadow: getTextShadow(accent),
          }}
        >
          {children}
        </div>
      ) : (
        <div style={{ textShadow: getTextShadow(accent) }} className={'banner_body'}>
          {children}
        </div>
      )}
    </div>
  );
};
