import React from 'react';

import { WrecklessBoardProps } from '@magicyard/wreckless-game/src/Types';

const MenuBoard = (props: WrecklessBoardProps) => {
  return (
    <>
      <h1>Select game mode</h1>
    </>
  );
};

export default MenuBoard;
