import React from 'react';
import { DraftItemType, WrecklessBoardProps } from '@magicyard/wreckless-game/src/Types';
import DraftItem from './DraftItem';
import styled from 'styled-components';

const FlexContainer = styled.div`
  display: inline-flex;
`;

const VerticalFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const FlexItem = styled.div`
  margin-right: 10px;
  margin-left: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const TitleItem = styled.div`
  padding: 20px;
  font-size: 40px;
  width: 100%;
`;

const DraftingSetFlexItem = styled(FlexItem)``;

const Bid = ({ bid }) => {
  return (
    <>
      {bid && (
        <FlexContainer style={{ justifyContent: 'space-between' }}>
          <VerticalFlexContainer>
            <FlexItem>Player </FlexItem>
            <FlexItem>{bid.playerID}</FlexItem>
          </VerticalFlexContainer>
          <VerticalFlexContainer>
            <FlexItem>Tokens</FlexItem>
            <FlexItem>{bid.tokens}</FlexItem>
          </VerticalFlexContainer>
        </FlexContainer>
      )}
    </>
  );
};

const DraftingSet = ({ draftingSet, bid }) => {
  return (
    <FlexContainer style={{ borderStyle: 'solid', padding: '5px', marginBottom: '5px' }}>
      {draftingSet.map((item, i) => (
        <DraftingSetFlexItem key={`item-${i}`}>
          <DraftItem item={item} />
        </DraftingSetFlexItem>
      ))}
      <FlexItem>
        <Bid bid={bid} />
      </FlexItem>
    </FlexContainer>
  );
};

const HandTitle = styled.div`
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
`;

const StyledHand = styled.div`
  border-style: solid;
  margin: 5px;
  padding: 5px;
  width: 565px;
  height: 130px;
`;

const HandCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const HandCard = styled.div`
  font-size: 20px;
  border-style: solid;
  border-width: 1px;
  width: 180px;
  white-space: nowrap;
  margin: 2px;
  padding-left: 2px;
`;

const HandTitleItem = styled.div`
  font-size: 20px;
`;

interface HandProps {
  hand: Array<DraftItemType>;
  playerID: number;
  remainingTokens: number;
}

const Hand = ({ hand, playerID, remainingTokens }: HandProps) => {
  return (
    <StyledHand>
      <HandTitle>
        <HandTitleItem>Player {playerID}</HandTitleItem>
        <HandTitleItem>Tokens: {remainingTokens}</HandTitleItem>
      </HandTitle>
      <HandCardsContainer>
        {hand.map((card, i) => (
          <div key={`card-${playerID}-${i}`}>
            <HandCard>{card.Name}</HandCard>
          </div>
        ))}
      </HandCardsContainer>
    </StyledHand>
  );
};

const DraftingPhaseBoard = (props: WrecklessBoardProps) => {
  return (
    <>
      <TitleContainer>
        <TitleItem>Player {props.ctx.currentPlayer}, place your bid</TitleItem>
        <TitleItem>
          Drafting {props.G.draftingIndex + 1} / {props.G.draftingSets.length}
        </TitleItem>
      </TitleContainer>
      <FlexContainer>
        <FlexItem>
          {props.G.draftingSets[props.G.draftingIndex].map((draftingSet, i) => (
            <DraftingSet
              key={`set-${i}`}
              draftingSet={draftingSet}
              bid={props.G.bidsByRoundBySetIndex[props.G.draftingIndex][i]}
            />
          ))}
        </FlexItem>
        <FlexItem>
          {props.G.hands.map((hand, i) => (
            <Hand key={`hand-${i}`} hand={hand} playerID={i} remainingTokens={props.G.tokensByPlayerID[i]} />
          ))}
        </FlexItem>
      </FlexContainer>
    </>
  );
};

export default DraftingPhaseBoard;
