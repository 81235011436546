import {
  CombinedYard,
  ControllerDto,
  controllerDtoToProfile,
  Profile,
  Reaction,
  VoiceChatState,
  Yard,
  YardDto,
  yardDtoToYard,
  YardWithGame,
  YardWithQueue,
} from '../lib/api';
import { assertNever } from '@magicyard/utils/typeUtils';

export interface NavigationStateNoYard {
  type: 'no_yard';
  state: InitialLoading | IntroState | ProfileState | YardLoadingState | InvalidYardState;
}

export interface NavigationStateWithYard {
  state: YardState | GameLoadingState | GameState | YardAndDisplayState | OnlineQueueState;
  type: 'yard';
}

export type ValidYardState = NavigationStateNoYard | NavigationStateWithYard;

export const navigationStateToYardState = (navigationState: NavigationState): ValidYardState => {
  switch (navigationState.navigation) {
    case 'initial':
    case 'invalid_yard':
      return { type: 'no_yard', state: navigationState };
    case 'queue':
    case 'yard':
    case 'loading_game':
    case 'game':
    case 'yard_display':
      return { type: 'yard', state: navigationState };
    default:
      assertNever(navigationState);
  }
};

export interface NavigationStateNoController {
  type: 'no_controller';
  state: InitialLoading | IntroState | ProfileState | YardLoadingState;
}

export type StatesWithController =
  | YardState
  | GameLoadingState
  | GameState
  | YardAndDisplayState
  | InvalidYardState
  | OnlineQueueState;

export interface NavigationStateWithController {
  state: StatesWithController;
  type: 'controller';
}

export type ControllerState = NavigationStateNoController | NavigationStateWithController;
export type NavigationState =
  | InitialLoading
  | YardState
  | GameLoadingState
  | GameState
  | InvalidYardState
  | YardAndDisplayState
  | OnlineQueueState;

export interface InitialLoadingDisplay {
  type: 'display';
  profile: Profile;
  displayId: string;
}

export interface InitialLoadingGame {
  type: 'game';
  profile: Profile;
  yardId: string | null;
  displayId: string;
  gameStartArgs: unknown;
}

export interface BaseController {
  profile: Profile;
}

export interface GameController extends BaseController {
  gameStartArgs: unknown;
  displayId: string;
  yard: YardWithGame;
}

export interface YardController extends BaseController {
  yard: CombinedYard;
}

export interface YardWithDisplayController extends BaseController {
  displayId: string;
  yard: YardWithGame | Yard;
}

export interface LoadingGameController extends BaseController {
  displayId: string;
  yard: YardWithGame;
}

export interface YardWithQueueController extends BaseController {
  displayId: string;
  yard: YardWithQueue;
}

export interface InvalidYardController {
  profile: Profile;
}

export interface InitialLoading {
  navigation: 'initial';
}

export interface IntroState {
  navigation: 'intro';
}

export interface ProfileState {
  navigation: 'profile';
}

export interface YardLoadingState {
  navigation: 'loading_yard';
}

export interface YardState {
  navigation: 'yard';
  controller: YardController;
}

export interface YardAndDisplayState {
  navigation: 'yard_display';
  controller: YardWithDisplayController;
}

export interface GameLoadingState {
  navigation: 'loading_game';
  controller: LoadingGameController;
}

export interface GameState {
  navigation: 'game';
  controller: GameController;
}

export interface InvalidYardState {
  navigation: 'invalid_yard';
  controller: InvalidYardController;
}

export interface ProfileResult {
  name: string;
  avatarUrl: string;
}

export interface OnlineQueueState {
  navigation: 'queue';
  controller: YardWithQueueController;
}

export interface JoinVoiceResponse {
  grab: () => void;
  setMute: (mute: boolean) => void;
  leave: () => Promise<void>;
}

interface MicState {
  join: () => Promise<JoinVoiceResponse>;
  speakers: VoiceChatState['speakers'];
}

export interface Communication {
  voice: { micState: MicState | null } | null;
  sendReaction: (text: string) => void;
  receiveReaction: (handler: (data: Reaction) => void) => () => void;
}

/**
 * Configuration with game event callbacks which update the state
 * @typeParam T - the type of the state the callbacks update (by returning it)
 */
export interface StateConfig<T> {
  /**
   * Always gets called first.
   * Will figure the next move and act accordingly.
   * If this is the first time the user enters will call onIntro.
   */
  onInitialLoading: () => T;
  /**
   * Display the Yard screen
   * @param controller
   * @param onSubmitOnline User wants to play online. gameId must match server gameId.
   * @param onSubmitLocal User wants to play locally. gameId must match server gameId.
   */
  onYard: (params: {
    controller: YardController;
    onDisplayScanned: (qrCodeUrlResult: string) => void;
    onRoomCodeEntered: (code: string) => void;
    onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => Promise<void>;
    communication: Communication;
    isReconnecting: boolean;
  }) => T;

  /**
   * The user has a yard and is connected to a display.
   * @param controller
   * @param onSubmitOnline Start an online game
   * @param onSubmitLocal Start a local game
   * @param onProfileUpdate The local profile will update immediately but the remote only when the promise resolves.
   */

  onYardWithDisplay: (params: {
    controller: YardWithDisplayController;
    onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => Promise<void>;
    onSubmitOnline: (extras?: any) => void;
    onSubmitLocal: (extras?: any) => void;
    communication: Communication;
    isReconnecting: boolean;
  }) => T;
  /**
   * Will call onGame
   */
  onGameLoading: (params: {
    controller: YardWithDisplayController;
    communication: Communication;
    isReconnecting: boolean;
    onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => Promise<void>;
  }) => T;
  /**
   * Display your game
   * @param controller
   * @param onGameEnd Will call onYard
   */
  onGame: (params: {
    controller: GameController;
    onGameEnd: () => void;
    communication: Communication;
    onStartAgain: (extras?: any) => void;
    onStartAgainOnline: (extras?: any) => void;
    isReconnecting: boolean;
    onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => Promise<void>;
  }) => T;
  /**
   * Called when in an invalid state
   * @param controller
   */
  onInvalidYard: (params: {
    controller: InvalidYardController;
    onDisplayScanned: (qrCodeUrlResult: string) => void;
    onDisplayCodeEntered: (code: string) => void;
    isReconnecting: boolean;
    onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => Promise<void>;
  }) => T;
  /**
   * Called when starting an online game
   * @param controller
   * @param toggleMic
   */
  onOnlineQueue: (params: {
    controller: YardWithQueueController;
    communication: Communication;
    onLeaveQueue: () => void;
    isReconnecting: boolean;
    onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => Promise<void>;
  }) => T;
}

export type ConnectionState = 'reconnecting' | 'connected' | null;

export const getIsReconnecting = (state: ConnectionState) => {
  return state === 'reconnecting';
};

export const navigationStateToControllerState = (navState: NavigationState): ControllerState => {
  switch (navState.navigation) {
    case 'initial':
      return { type: 'no_controller', state: navState };
    case 'queue':
    case 'yard_display':
    case 'yard':
    case 'loading_game':
    case 'game':
    case 'invalid_yard':
      return { type: 'controller', state: navState };
    default:
      assertNever(navState);
  }
};

export const getDisplayIdFromControllerState = (controllerState: ControllerState): string | null => {
  switch (controllerState.type) {
    case 'no_controller':
      return null;
    case 'controller':
      switch (controllerState.state.navigation) {
        case 'invalid_yard':
        case 'yard':
          return null;
        case 'queue':
        case 'loading_game':
        case 'yard_display':
        case 'game':
          return controllerState.state.controller.displayId;
        default:
          return assertNever(controllerState.state);
      }
    default:
      return assertNever(controllerState);
  }
};

export const getGameStartArgsFromControllerState = (controllerState: ControllerState): unknown | null => {
  switch (controllerState.type) {
    case 'no_controller':
      return null;
    case 'controller':
      switch (controllerState.state.navigation) {
        case 'queue':
        case 'invalid_yard':
        case 'yard':
        case 'loading_game':
        case 'yard_display':
          return null;
        case 'game':
          return controllerState.state.controller.gameStartArgs;
        default:
          return assertNever(controllerState.state);
      }
    default:
      return assertNever(controllerState);
  }
};

export const getYardFromControllerState = (controllerState: ControllerState) => {
  switch (controllerState.type) {
    case 'no_controller':
      return null;
    case 'controller':
      switch (controllerState.state.navigation) {
        case 'invalid_yard':
          return null;
        case 'queue':
        case 'yard':
        case 'loading_game':
        case 'yard_display':
        case 'game':
          return controllerState.state.controller.yard;
        default:
          return assertNever(controllerState.state);
      }
    default:
      return assertNever(controllerState);
  }
};

export const getYardIdFromControllerState = (controllerState: ControllerState): string | null => {
  switch (controllerState.type) {
    case 'no_controller':
      return null;
    case 'controller':
      switch (controllerState.state.navigation) {
        case 'queue':
        case 'yard':
        case 'loading_game':
        case 'yard_display':
        case 'game':
          return controllerState.state.controller.yard.id;
        case 'invalid_yard':
          return null;
        default:
          return assertNever(controllerState.state);
      }
    default:
      return assertNever(controllerState);
  }
};

export const controllerDtoToYardController = (yard: YardDto, controller: ControllerDto): YardController => {
  return {
    profile: controllerDtoToProfile(controller),
    yard: yardDtoToYard(yard),
  };
};

export const getMicState = (
  navigationState: NavigationState
): { voiceChatState: VoiceChatState | null; speakerData: { id: string; muted: boolean } | undefined } | null => {
  const yardState = navigationStateToYardState(navigationState);
  switch (yardState.type) {
    case 'no_yard':
      return null;
    case 'yard': {
      const voiceChatState = yardState.state.controller.yard.voiceChatState;
      const x =
        voiceChatState === null
          ? undefined
          : voiceChatState.speakers.find((x) => x.id === yardState.state.controller.profile.id);
      return { voiceChatState: yardState.state.controller.yard.voiceChatState, speakerData: x };
    }
    default:
      return assertNever(yardState);
  }
};
