import React, { useState } from 'react';
import { MoveEffect, WrecklessBoardProps, TextEffect } from '@magicyard/wreckless-game/src/Types';
import { useEffectListener } from 'bgio-effects/react';
import styled from 'styled-components';

type PlayerLocations = Array<Array<number>>;

const StyledTile = styled.div`
  width: 200px;
  height: 100px;
  border-style: solid;
  padding: 5px;
  margin-bottom: 5px;
`;

const Border = styled.div`
  border-style: solid;
  border-color: ${(props) => (props.isCurrentPlayer ? 'red' : 'black')};
`;

const Center = styled.div`
  text-align: center;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const FlexContainerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FlexItem = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
`;

const SidebarItem = styled(FlexItem)`
  width: 170px;
`;

export const VerticalFlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const VerticalFlexItem = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
`;

interface TileProps {
  players: Array<number>;
  currentPlayer: number;
  type: string;
  activeAddons: string;
  playersInfo: Array<PlayerInfo>;
}

const FlexItemPlayer = styled.div`
  margin-right: 1px;
  margin-left: 1px;
  text-align: center;
  padding-left: 2px;
  padding-right: 2px;
  border-style: solid;
  border-width: 2px;
  font-size: 40px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${(props) => (props.isCurrentPlayer ? 'red' : 'black')};
`;

const Addon = ({ text }) => {
  return <div>{text}</div>;
};

const Tile = ({ players, currentPlayer, type, activeAddons, playersInfo }: TileProps) => {
  return (
    <StyledTile>
      {type}
      <Addon text={activeAddons} />
      <FlexContainer>
        {players.map((player: number) => {
          return (
            <FlexItemPlayer
              key={`tile-player-${player}`}
              isCurrentPlayer={player === currentPlayer}
              backgroundColor={playersInfo[player].color}
            >
              {playersInfo[player].name}
            </FlexItemPlayer>
          );
        })}
      </FlexContainer>
    </StyledTile>
  );
};

const DEFAULT_TILES_PER_ROW = 6;

const calcPlayerLocations = (props: WrecklessBoardProps): PlayerLocations => {
  const currentRound = props.G.currentRacingRound;
  const totalTiles = props.G.map[currentRound].length;
  const playerLocations: PlayerLocations = Array(totalTiles)
    .fill([])
    .map(() => Array(0).fill(0));

  for (let [playerID, playerData] of props.G.roundPlayerData[currentRound].entries()) {
    const location = playerData.locationOnMap;
    playerLocations[location].push(Number(playerID));
  }
  return playerLocations;
};

interface TileSummary {
  name: string;
  addOns: Array<string>;
}

interface PlayerInfo {
  color?: string;
  name?: string;
}

interface RaceTrackProps {
  totalTiles: number;
  tilesPerRow: number;
  map: Array<TileSummary>;
  playerLocations: Array<Array<number>>;
  currentPlayer: number;
  playersInfo: Array<PlayerInfo>;
}

const RaceTrack = ({ totalTiles, tilesPerRow, playersInfo, playerLocations, currentPlayer, map }: RaceTrackProps) => {
  const rowCount = Math.ceil(totalTiles / tilesPerRow);

  return (
    <>
      {Array(rowCount)
        .fill(0)
        .map((_, row) => (
          <div key={`tile-${row}`}>
            <FlexContainer>
              {Array(tilesPerRow)
                .fill(0)
                .filter((_, col) => row * tilesPerRow + col < playerLocations.length)
                .map((_, col) => (
                  <div key={`tile-${row}-${col}`}>
                    <FlexItem>
                      <Tile
                        playersInfo={playersInfo}
                        players={playerLocations[row * tilesPerRow + col]}
                        currentPlayer={currentPlayer}
                        type={map[row * tilesPerRow + col].name}
                        activeAddons={map[row * tilesPerRow + col].addOns.join(`,`)}
                      />
                    </FlexItem>
                  </div>
                ))}
            </FlexContainer>
          </div>
        ))}
    </>
  );
};

export const StyledHeadline = styled.h1`
  margin-bottom: 0em;
  margin-left: 5em;
`;

const Headline = ({ text }) => {
  return <StyledHeadline>{text}</StyledHeadline>;
};

const SidePanel = (props: WrecklessBoardProps) => {
  const { roundPlayerData, currentRacingRound } = props.G;
  const currentPlayer = Number(props.ctx.currentPlayer);
  const { players } = props.G;
  return (
    <div>
      <Border>
        <FlexContainerWrap>
          {roundPlayerData[currentRacingRound].map((playerData, i) => {
            return (
              <div key={`player-data-${i}`}>
                <FlexItem>
                  <Border isCurrentPlayer={playerData.playerID === currentPlayer}>
                    <VerticalFlexContainer>
                      <VerticalFlexItem>
                        <Center>{`Racer ${playerData.playerID} - ${players[playerData.playerID].name}`}</Center>
                      </VerticalFlexItem>
                      <SidebarItem>
                        <Center>
                          $ {props.G.scoreByPlayerID[playerData.playerID]} / {playerData.life} HP
                        </Center>
                      </SidebarItem>
                      <VerticalFlexItem>
                        {playerData.racer.driver.Name}
                        <br />
                        {playerData.racer.vehicle.Name}
                        <br />
                        {playerData.racer.hat.Name}
                      </VerticalFlexItem>
                    </VerticalFlexContainer>
                  </Border>
                </FlexItem>
              </div>
            );
          })}
        </FlexContainerWrap>
      </Border>
    </div>
  );
};

const RacingPhaseBoard = (props: WrecklessBoardProps) => {
  const currentRound = props.G.currentRacingRound;
  const totalTiles = props.G.map[currentRound].length;
  const { players: playersInfo } = props.G;

  const [text, setText] = useState<string>('Starting race...');
  const [playerLocations, setPlayerLocations] = useState<PlayerLocations>(calcPlayerLocations(props));

  useEffectListener(
    'move',
    (effect: MoveEffect, boardProps: WrecklessBoardProps) => {
      setPlayerLocations((oldLocations) => {
        const newLocations: PlayerLocations = Array(totalTiles)
          .fill([])
          .map(() => Array(0).fill(0));

        for (let i in playerLocations) {
          newLocations[i] = oldLocations[i].filter((p) => p !== Number(effect.playerID));
        }
        newLocations[effect.to].push(Number(effect.playerID));
        return newLocations;
      });
    },
    []
  );

  useEffectListener(
    'text',
    (effect: TextEffect, boardProps: WrecklessBoardProps) => {
      setText(effect.message);
    },
    []
  );

  useEffectListener(
    'endTurn',
    (effect, boardProps: WrecklessBoardProps) => {
      setPlayerLocations(calcPlayerLocations(boardProps));
    },
    []
  );

  return (
    <>
      <Headline text={text} />
      <FlexContainer>
        <FlexItem>
          <RaceTrack
            totalTiles={totalTiles}
            tilesPerRow={DEFAULT_TILES_PER_ROW}
            playerLocations={playerLocations}
            playersInfo={playersInfo}
            currentPlayer={Number(props.ctx.currentPlayer)}
            map={props.G.map[props.G.currentRacingRound].map((tile): TileSummary => {
              return {
                name: tile.Type,
                addOns: tile.activeAddOns.map((activeAddOn) => {
                  return `${activeAddOn.name} - by ${activeAddOn.by}`;
                }),
              };
            })}
          />
        </FlexItem>
        <FlexItem>
          <SidePanel {...props} />;
        </FlexItem>
      </FlexContainer>
    </>
  );
};

export default RacingPhaseBoard;
