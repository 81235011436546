export const getControllerUrlConnectDisplay = (displayId: string) =>
  `${PLATFORM_CONTROLLER_URL}?displayId=${displayId}`;

// TODO: invite link should be universal (Openable on display and not coupled to controller)
export const getYardInviteLink = (yardId: string) => `${PLATFORM_CONTROLLER_URL}?yardId=${yardId}`;

export function assertNever(value: never, noThrow?: boolean): never {
  if (noThrow) {
    return value;
  }

  throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
}

export function assert(condition: unknown, message: string): asserts condition {
  if (!condition) throw new Error(message);
}

export function assertIsDefined<T>(val: T | undefined | null, message?: string): asserts val is T {
  return assert(val !== undefined && val !== null, message ?? 'Expected value to be defined');
}

export const random = (min: number, max: number) => {
  return min + Math.random() * (max - min);
};
export const randomInt = (min: number, max: number) => {
  return Math.round(random(min, max));
};
export const randomChoice = <T>(choices: readonly T[]): T => {
  return choices[randomInt(0, choices.length - 1)];
};

export const resolveURL = (envVar: string | undefined, required: boolean = false, name: string): string | undefined => {
  if (required && envVar === undefined) {
    throw new Error(`Missing required env var ${name}`);
  }
  const url =
    envVar === undefined || `${envVar || ''}`.startsWith('http') ? envVar : new URL(envVar, window.origin).href;
  return url; //.replace(/\/+$/, ''); // This breaks for urls that need the trailing slash
};

const PLATFORM_SERVER_URL = resolveURL(
  process.env.REACT_APP_PLATFORM_SERVER_URL,
  true,
  'REACT_APP_PLATFORM_SERVER_URL'
);
const PLATFORM_CONTROLLER_URL = resolveURL(
  process.env.REACT_APP_PLATFORM_CONTROLLER_URL,
  false,
  'REACT_APP_PLATFORM_CONTROLLER_URL'
);
const PLATFORM_DISPLAY_URL = resolveURL(
  process.env.REACT_APP_PLATFORM_DISPLAY_URL,
  false,
  'REACT_APP_PLATFORM_DISPLAY_URL'
);
const GAME_START_SERVER_URL = resolveURL(
  process.env.REACT_APP_GAME_START_SERVER_URL,
  false,
  'REACT_APP_GAME_START_SERVER_URL'
);
const GAME_START_CONTROLLER_URL = resolveURL(
  process.env.REACT_APP_GAME_START_CONTROLLER_URL,
  false,
  'REACT_APP_GAME_START_CONTROLLER_URL'
);
const GAME_START_DISPLAY_URL = resolveURL(
  process.env.REACT_APP_GAME_START_DISPLAY_URL,
  false,
  'REACT_APP_GAME_START_DISPLAY_URL'
);
export const CONFIG = {
  PLATFORM_SERVER_URL,
  PLATFORM_CONTROLLER_URL,
  PLATFORM_DISPLAY_URL,
  GAME_START_SERVER_URL,
  GAME_START_CONTROLLER_URL,
  GAME_START_DISPLAY_URL,
  MGY_ENV: process.env.REACT_APP_MGY_ENV,
};
