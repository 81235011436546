import { LongFormMove, Move } from 'boardgame.io';
import { CtxWithEffects } from './effects-config';
import { AddScoreData, GameState, PlayerData, ScoreUpdateCategory, Tile } from './Types';

export const createGameDeck = (vehiclesDeck, driversDeck, hatsDeck, numberOfPlayers, numberOfRounds) => {
  const gameDeck = [];
  for (let i = 0; i < numberOfRounds * numberOfPlayers; i++) {
    gameDeck.push(driversDeck.pop());
    gameDeck.push(hatsDeck.pop());
    gameDeck.push(vehiclesDeck.pop());
  }
  return gameDeck;
};

export const mapNames = ['map1', 'map2', 'map3'];
export const dealDraftingSets = (vehiclesDeck, driversDeck, hatsDeck, numPlayers) => {
  const draftingSets = Array.from({ length: 5 }, () => []);
  for (let i = 0; i < numPlayers; i++) {
    draftingSets[0].push([driversDeck.pop(), hatsDeck.pop()]);
  }
  for (let i = 0; i < numPlayers; i++) {
    draftingSets[1].push([driversDeck.pop(), vehiclesDeck.pop()]);
  }
  for (let i = 0; i < numPlayers; i++) {
    draftingSets[2].push([vehiclesDeck.pop()]);
  }
  for (let i = 0; i < numPlayers; i++) {
    draftingSets[3].push([hatsDeck.pop(), hatsDeck.pop()]);
  }
  for (let i = 0; i < numPlayers; i++) {
    draftingSets[4].push([driversDeck.pop(), vehiclesDeck.pop()]);
  }
  return draftingSets;
};

export const generateMap1 = (): Tile[] => {
  const mapLength = 19;
  const Map1: Omit<Tile, 'id'>[] = [];
  for (let i = 0; i < Math.floor(mapLength / 2); i++) {
    if (Math.random() < 0.05) {
      Map1.push({ Type: 'bump', MovementCost: 2, Damage: 0, activeAddOns: [] });
    } else {
      if (Math.random() < 0.0) {
        Map1.push({ Type: 'damage', MovementCost: 1, Damage: 1, activeAddOns: [] });
      } else {
        if (Math.random() < 0.03) {
          Map1.push({ Type: 'coin', MovementCost: 1, Damage: 1, activeAddOns: [] });
        } else {
          if (Math.random() < 0.02) {
            Map1.push({ Type: 'money_bag', MovementCost: 1, Damage: 1, activeAddOns: [] });
          } else {
            Map1.push({ Type: 'plain', MovementCost: 1, Damage: 0, activeAddOns: [] });
          }
        }
      }
    }
  }
  for (let i = 0; i < Math.ceil(mapLength / 2); i++) {
    if (Math.random() < 0.1) {
      Map1.push({ Type: 'bump', MovementCost: 2, Damage: 0, activeAddOns: [] });
    } else {
      if (Math.random() < 0.0) {
        Map1.push({ Type: 'damage', MovementCost: 1, Damage: 1, activeAddOns: [] });
      } else {
        if (Math.random() < 0.03) {
          Map1.push({ Type: 'coin', MovementCost: 1, Damage: 1, activeAddOns: [] });
        } else {
          if (Math.random() < 0.02) {
            Map1.push({ Type: 'money_bag', MovementCost: 1, Damage: 1, activeAddOns: [] });
          } else {
            Map1.push({ Type: 'plain', MovementCost: 1, Damage: 0, activeAddOns: [] });
          }
        }
      }
    }
  }
  Map1[11] = { Type: 'daily_double', MovementCost: 1, Damage: 0, activeAddOns: [] };
  Map1[0] = { Type: 'start', MovementCost: 1, Damage: 0, activeAddOns: [] };
  Map1.push({ Type: 'finish', MovementCost: 1, Damage: 0, activeAddOns: [] });
  return Map1.map((tile, index) => {
    return { ...tile, id: index };
  });
};

export const generateMap2 = (): Tile[] => {
  const mapLength = 25;
  const Map2: Omit<Tile, 'id'>[] = [];
  for (let i = 0; i < Math.floor(mapLength / 2); i++) {
    if (Math.random() < 0.1) {
      Map2.push({ Type: 'bump', MovementCost: 2, Damage: 0, activeAddOns: [] });
    } else {
      if (Math.random() < 0.05) {
        Map2.push({ Type: 'damage', MovementCost: 1, Damage: 1, activeAddOns: [] });
      } else {
        if (Math.random() < 0.03) {
          Map2.push({ Type: 'coin', MovementCost: 1, Damage: 1, activeAddOns: [] });
        } else {
          if (Math.random() < 0.02) {
            Map2.push({ Type: 'money_bag', MovementCost: 1, Damage: 1, activeAddOns: [] });
          } else {
            Map2.push({ Type: 'plain', MovementCost: 1, Damage: 0, activeAddOns: [] });
          }
        }
      }
    }
  }
  for (let i = 0; i < Math.ceil(mapLength / 2); i++) {
    if (Math.random() < 0.15) {
      Map2.push({ Type: 'bump', MovementCost: 2, Damage: 0, activeAddOns: [] });
    } else {
      if (Math.random() < 0.1) {
        Map2.push({ Type: 'damage', MovementCost: 1, Damage: 1, activeAddOns: [] });
      } else {
        if (Math.random() < 0.04) {
          Map2.push({ Type: 'coin', MovementCost: 1, Damage: 1, activeAddOns: [] });
        } else {
          if (Math.random() < 0.02) {
            Map2.push({ Type: 'money_bag', MovementCost: 1, Damage: 1, activeAddOns: [] });
          } else {
            Map2.push({ Type: 'plain', MovementCost: 1, Damage: 0, activeAddOns: [] });
          }
        }
      }
    }
  }
  Map2[15] = { Type: 'daily_double', MovementCost: 1, Damage: 0, activeAddOns: [] };
  Map2[0] = { Type: 'start', MovementCost: 1, Damage: 0, activeAddOns: [] };
  Map2.push({ Type: 'finish', MovementCost: 1, Damage: 0, activeAddOns: [] });
  return Map2.map((tile, index) => {
    return { ...tile, id: index };
  });
};

export const generateMap3 = (): Tile[] => {
  const mapLength = 31;
  const Map3: Omit<Tile, 'id'>[] = [];
  for (let i = 0; i < Math.floor(mapLength / 2); i++) {
    if (Math.random() < 0.1) {
      Map3.push({ Type: 'bump', MovementCost: 2, Damage: 0, activeAddOns: [] });
    } else {
      if (Math.random() < 0.1) {
        Map3.push({ Type: 'damage', MovementCost: 1, Damage: 1, activeAddOns: [] });
      } else {
        if (Math.random() < 0.05) {
          Map3.push({ Type: 'coin', MovementCost: 1, Damage: 1, activeAddOns: [] });
        } else {
          if (Math.random() < 0.02) {
            Map3.push({ Type: 'money_bag', MovementCost: 1, Damage: 1, activeAddOns: [] });
          } else {
            Map3.push({ Type: 'plain', MovementCost: 1, Damage: 0, activeAddOns: [] });
          }
        }
      }
    }
  }
  for (let i = 0; i < Math.ceil(mapLength / 2); i++) {
    if (Math.random() < 0.15) {
      Map3.push({ Type: 'bump', MovementCost: 2, Damage: 0, activeAddOns: [] });
    } else {
      if (Math.random() < 0.15) {
        Map3.push({ Type: 'damage', MovementCost: 1, Damage: 1, activeAddOns: [] });
      } else {
        if (Math.random() < 0.05) {
          Map3.push({ Type: 'coin', MovementCost: 1, Damage: 1, activeAddOns: [] });
        } else {
          if (Math.random() < 0.02) {
            Map3.push({ Type: 'money_bag', MovementCost: 1, Damage: 1, activeAddOns: [] });
          } else {
            Map3.push({ Type: 'plain', MovementCost: 1, Damage: 0, activeAddOns: [] });
          }
        }
      }
    }
  }
  Map3[14] = { Type: 'daily_double', MovementCost: 1, Damage: 0, activeAddOns: [] };
  Map3[22] = { Type: 'daily_double', MovementCost: 1, Damage: 0, activeAddOns: [] };
  Map3[0] = { Type: 'start', MovementCost: 1, Damage: 0, activeAddOns: [] };
  Map3.push({ Type: 'finish', MovementCost: 1, Damage: 0, activeAddOns: [] });
  return Map3.map((tile, index) => {
    return { ...tile, id: index };
  });
};

export const dealDraftingSetsForTest = (vehiclesDeck, driversDeck, hatsDeck, numPlayers) => {
  const draftingSets = Array.from({ length: 3 }, () => []);
  for (let i = 0; i < numPlayers; i++) {
    draftingSets[0].push([hatsDeck.pop()]);
  }
  for (let i = 0; i < numPlayers; i++) {
    draftingSets[1].push([driversDeck.pop()]);
  }
  for (let i = 0; i < numPlayers; i++) {
    draftingSets[2].push([vehiclesDeck.pop()]);
  }
  return draftingSets;
};

export const move = (G: GameState, ctx: CtxWithEffects, playerData: PlayerData, newLocation: number) => {
  if (newLocation < 0) {
    newLocation = 0;
  }
  const finishLocation = G.map[G.currentRacingRound].length - 1;
  if (newLocation > finishLocation) {
    newLocation = finishLocation;
  }
  if (playerData.locationOnMap === newLocation) {
    return;
  }
  const step = Math.sign(newLocation - playerData.locationOnMap);
  for (let i = playerData.locationOnMap; i !== newLocation; i += step) {
    ctx.effects.move({
      playerID: playerData.playerID,
      from: i,
      to: i + step,
    });
  }
  playerData.locationOnMap = newLocation;

  if (playerData.locationOnMap === finishLocation) {
    console.log(`Player ${ctx.currentPlayer} reached the finish line!`);
    playerData.placeFinished = G.placeInRace;
    scoreByRaceResult(G, ctx, playerData);
    G.placeInRace++;
  }
};

export const addScore = (
  G: GameState,
  ctx: CtxWithEffects,
  playerID: string,
  scoreDiff: number,
  category: ScoreUpdateCategory,
  data: AddScoreData = {}
): void => {
  G.scoreByPlayerID[playerID] = G.scoreByPlayerID[playerID] + scoreDiff;
  G.scoreUpdatesByRound[G.currentRacingRound].push({
    playerID,
    score: scoreDiff,
    reason: data.reason,
    cardId: data.cardId,
    category,
  });
  ctx.effects.action({
    type: 'floatingAction',
    subtype: 'coin',
    playerID: parseInt(ctx.currentPlayer),
    message: data.reason,
    affectedPlayerIDs: [playerID.toString()],
    amount: scoreDiff,
  });
};

export const objectKeys = <T extends Record<string, unknown>>(obj: T): Array<keyof T> => {
  return Object.keys(obj) as Array<keyof T>;
};

export function wrapMoveWithOptions(moveFn: any, options: any): LongFormMove {
  return {
    move: moveFn,
    ...options,
  };
}

export function scoreByRaceResult(G: GameState, ctx: CtxWithEffects, playerData: PlayerData) {
  const placeToString = {
    1: '1st',
    2: '2nd',
    3: '3rd',
    4: '4th',
    5: '5th',
    6: '6th',
  };
  console.log('Score by race result');
  const raceResultRewardByRound = G.raceResultRewardByRound[G.currentRacingRound];
  const racerPlace = playerData.placeFinished;
  if (racerPlace !== null) {
    const racerScore = raceResultRewardByRound[racerPlace];
    if (playerData.racer.vehicle.ID == 'low_rider') {
      addScore(G, ctx, playerData.playerID.toString(), racerScore, ScoreUpdateCategory.Card, { cardId: 'low_rider' });
    }
    console.log(`Player ${playerData.playerID}: Place: ${placeToString[racerPlace]}, Winings: $${racerScore}`);
    addScore(G, ctx, playerData.playerID.toString(), racerScore, ScoreUpdateCategory.Race, {
      reason: `Player ${playerData.playerID} got to ${placeToString[racerPlace]} place, +$${racerScore}`,
    });
  }
}
