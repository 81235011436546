import React, { useEffect, useRef, useState } from 'react';
import { NAMES_TO_COLORS } from '@magicyard/wreckless-game/src/Game';
import { usePlatformDisplay } from '@magicyard/shared/platform/hooks/usePlatformDisplay';
import { Banner } from '@magicyard/shared/components/Banner';
import { URL_SHORTENING_API } from '@magicyard/shared/platform/lib/urlShortener';
import { objectKeys } from '@magicyard/shared/utils';
import bg from './assets/background.png';
import useForceScale from '@magicyard/shared/platform/hooks/useForceScale';
import { getControllerUrlConnectDisplay } from '@magicyard/shared/platform/utils/utils';

interface UnityInstance {
  SetFullScreen: (fullscreen: 1 | 0) => void;
  SendMessage: (objectName: string, methodName: string, value: string) => void;
  Quit: () => Promise<void>;
}

const useUnityInstance = () => {
  const canvas = useRef<HTMLCanvasElement>(document.querySelector('#unity-canvas'));
  const [unityInstance, setUnityInstance] = useState<UnityInstance | null>(null);
  useForceScale('#unity-canvas');
  useEffect(() => {
    console.log('Will load Unity');
    (window as any)
      .createUnityInstance(canvas.current, {
        dataUrl: `${process.env.REACT_APP_WEBGL_FOLDER}/Build/WebGL.data`,
        frameworkUrl: `${process.env.REACT_APP_WEBGL_FOLDER}/Build/WebGL.framework.js`,
        codeUrl: `${process.env.REACT_APP_WEBGL_FOLDER}/Build/WebGL.wasm`,
        streamingAssetsUrl: `${process.env.REACT_APP_WEBGL_FOLDER}/StreamingAssets`,
        companyName: 'MagicYard',
        productName: 'Wreckless',
        productVersion: '1.0',
        matchWebGLToCanvasSize: false,
        devicePixelRatio: 1,
      })
      .then((unityInstance: UnityInstance) => {
        setUnityInstance(unityInstance);
      })
      .then(() => {
        console.log('Unity Loaded');
      });
  }, []);

  return { instance: unityInstance, canvas: canvas };
};

const App = () => {
  const unityInstance = useUnityInstance();
  const query = new URLSearchParams(window.location.search);

  const x = usePlatformDisplay(
    {
      onLoading() {
        return <div>Loading...</div>;
      },
      onQueue() {
        return undefined;
      },
      onYardAndDisplay({ yard, display }) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh' }}>
            {objectKeys(NAMES_TO_COLORS).map((key, i) => {
              const player = yard.controllers[i];
              return (
                <Banner
                  key={key}
                  colorName={key}
                  style={{ transform: `translateX(${player !== undefined ? '0' : '-80%'})`, fontSize: '5vh' }}
                >
                  {player?.profile.name}
                </Banner>
              );
            })}
            <div
              style={{
                position: 'absolute',
                right: '20vw',
                top: '23vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '5vh',
              }}
            >
              Scan the qr code!
              <div
                style={{
                  width: '40vh',
                  height: '40vh',
                  backgroundSize: 'contain',
                  backgroundImage: `url(${URL_SHORTENING_API}/qr?text=${encodeURIComponent(
                    getControllerUrlConnectDisplay(display.id)
                  )})`,
                }}
              />
              <div>wreckless.tv</div>
              <div>
                Room code: <span style={{ color: '#ffcd00' }}>{display.code}</span>
              </div>
            </div>
          </div>
        );
      },
      onLoadingGame() {
        return <div>Loading game..</div>;
      },
      onGame({ yard, gameStartArgs, display }) {
        const urlParams = new URLSearchParams(new URL((gameStartArgs as any).url).search);
        const matchID = urlParams.get('matchID') || process.env.REACT_APP_MATCH_ID || 'default';
        const serverURL = urlParams.get('serverURL') ?? undefined;
        const stringified = JSON.stringify({
          MatchID: matchID,
          ServerUrl: serverURL,
          Display: { id: display.id, code: display.code },
        });
        updateDisplayIdForTestSharing(display.id);

        if (unityInstance.instance === null) {
          return (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Loading...
            </div>
          );
        }

        unityInstance.instance.SendMessage('Game Igniter', 'ReceiveGameConnectionParamsExternal', stringified);
        unityInstance.canvas.current.style.opacity = '1';
        return <div></div>;
      },
    },
    { displayId: query.get('displayID') },
    'wreckless'
  );
  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        width: '100vw',
        height: '100vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        color: 'white',
      }}
    >
      {x}
    </div>
  );
};

const updateDisplayIdForTestSharing = (displayId: string) => {
  const newUrl = new URL(window.location.href);
  newUrl.searchParams.set('displayID', displayId);
  window.history.pushState(null, '', newUrl);
};

export default App;
