import type { BoardProps } from 'boardgame.io/react';
import { AllCardIds, DriverCardID } from './getData';
import { NAMES_TO_COLORS, ACTIONS_FUNCTIONS } from './Game';

export enum GamePhase {
  Drafting = 'drafting',
  Menu = 'menu',
  WarmUp = 'warmUp',
  RacerSelect = 'racerSelect',
  Racing = 'racing',
  Betting = 'betting',
  Scoring = 'scoring',
}

export interface Card {
  Type: string;
  Name: string;
  Life: string;
  Rarity: number;
  Power: string;
  Actions: Array<Action>;
}

export interface ActiveAddOn {
  name: string;
  by: number;
  actions: Array<Action>;
  cost: number;
}

export interface BidType {
  playerID: string;
  tokens: number;
}

export interface BetCard {
  type: any;
  racer: any;
  addtionalRacer: any;
  reward: any;
}

export interface BetsOnCard {
  betCard: BetCard;
  bettors: Array<string>;
}

export type Action = {
  name: keyof typeof ACTIONS_FUNCTIONS;
  type: string;
  priority?: number;
};

export type Modifier = {
  cardID: string;
  type: string;
  amount: number;
  reason?: string;
};

export interface Tile {
  Type: 'plain' | 'start' | 'finish' | 'bump' | 'damage' | 'coin' | 'money_bag' | 'daily_double' | 'daily_double_done';
  MovementCost: number;
  Damage: number;
  id: number;
  activeAddOns: Array<ActiveAddOn>;
}

export type Racer = {
  vehicle: DraftItemVehicleType;
  driver: DraftItemDriverType;
  hat: DraftItemHatType;
};

export type CoolDown = {
  currentCoolDown: number;
  maxCoolDown: number;
};

export enum RaceTurnStates {
  WarmUp = 'warmUp',
  InitiateTurn = 'initiateTurn',
  OnRoundBegin = 'onRoundBegin',
  OnTurnBegin = 'onTurnBegin',
  PreRoll = 'preRoll',
  Roll = 'roll',
  AfterRoll = 'afterRoll',
  BeforeMoving = 'beforeMoving',
  Moving = 'moving',
  PassOver = 'passOver',
  OnLand = 'onLand',
  Attack = 'attack',
  OnTurnEnd = 'onTurnEnd',
  OnRoundEnd = 'onRoundEnd',
  OnRaceOver = 'onRaceOver',
}

export enum GameMode {
  FullGame = 'fullGame',
  Tutorial = 'tutorial',
}

export enum RollConfing {
  Auto = 'auto',
  ManualRoll = 'manualRoll',
}

export type GameConfig = {
  gameMode: GameMode;
  rollConfig: RollConfing;
  reRoll: boolean;
};

export type PlayerData = {
  racer: Racer;
  locationOnMap: number;
  predictedLocationOnMap: number;
  life: number;
  attackPower: number;
  attackModifiers: Array<Modifier>;
  isFirstTurn: boolean;
  isWrecked: boolean;
  isStunned: boolean;
  placeFinished: number | null;
  actions: Array<Action>;
  actionAttributes: any;
  actionCooldowns: Record<string, CoolDown>;
  turnState: TurnState;
  playerID: number;
};

export type PossibleMove =
  | 'rerollDieMove'
  | 'rollDieMove'
  | 'pickALuckyNumberMove'
  | 'fuzzyDiceMove'
  | 'elephantMove'
  | 'pickACrystalBallMove'
  | 'fleetFootedMove'
  | 'shortcutMove'
  | 'jetCarMove'
  | 'experienceChargeMove'
  | 'planMove'
  | 'feedTheLeprechaunMove'
  | 'rubberGirlMove'
  | 'shadowMove'
  | 'towMove'
  | 'swordDanceMove'
  | 'spiderMove'
  | 'masterOfDisguiseMove';

export type TurnState = {
  state: string;
  pendingActions: Array<Action>;
  pendingMoves: Array<PossibleMove>;
  startingPoint: number;
  dieResult: number;
  movementModifiers: Array<Modifier>;
  movementPoints: number;
};

export interface PlayerInfo {
  name: string;
  avatarUrl: string;
  color: string;
  colorName: keyof typeof NAMES_TO_COLORS;
  isBot?: boolean;
}

export interface GameState {
  players: Array<PlayerInfo>;
  gameConfig: GameConfig;
  mapsNames: Array<string>;
  colors: Array<string>;
  draftingSets: Array<Array<Array<DraftItemSpecified>>>;
  draftingIndex: number;
  tokensByPlayerID: Array<number>;
  roundBets: Array<Array<BetsOnCard>>;
  bidsByRoundBySetIndex: Array<Array<BidType>>;
  betsTable: Array<BetsOnCard>;
  hands: Array<Array<DraftItemType>>;
  playedCards: Array<Array<DraftItemType>>;
  currentRacingRound: number;
  map: Array<Array<Tile>>;
  roundPlayerData: Array<Array<PlayerData>>;
  scoreByPlayerID: Array<number>;
  scoreUpdatesByRound: Array<Array<ScoreUpdate>>;
  endRaceRound: Array<boolean>;
  currentSetIndex: number | null;
  placeInRace: number | null;
  raceResultRewardByRound: Array<Record<number, number>>;
  calculatedTurnOrder: Array<any>;
  numberOfCardsPerRound: number;
  lastWarmUpRacingRound: number;
}

export type DraftItems = 'Driver' | 'Vehicle' | 'Hat';

export interface DraftItemType {
  Type: DraftItems;
  Name: string;
  Power: string;
  Power_Unity: string;
  Rarity: number;
  ID: AllCardIds;
  Actions: Action[];
}

export type DraftItemSpecified = DraftItemHatType | DraftItemDriverType | DraftItemVehicleType;

export interface DraftItemHatType extends DraftItemType {
  Type: 'Hat';
}

export interface DraftItemDriverType extends DraftItemType {
  Type: 'Driver';
  ID: DriverCardID;
  Attack: number;
}

export interface DraftItemVehicleType extends DraftItemType {
  Type: 'Vehicle';
  Life: number;
}

export interface WrecklessBoardProps extends BoardProps<GameState> {}

export interface MoveEffect {
  playerID: number;
  from: number;
  to: number;
}

export interface TextEffect {
  message: string;
}

export type ActionEffect =
  | ActionScreen
  | PhaseEnded
  | MoveScreen
  | MoveAction
  | DiceRoll
  | FinalDie
  | FloatingAction
  | TurnStart
  | SyncMessage
  | LifePointsUpdate
  | TileAction
  | CoinsUpdate;

//Dice Roll Action, show message for the players and also loop a dice animation
//For the given duration and after that pick the finalDice parameter as the chosen one
export interface DiceRollBase {
  type: 'diceRoll' | 'finalDie';
  rollerId: string;
  message: string;
  predictedLocationOnMap: number;
  finalDice: number;
  movementPoints: number;
  movementModifiers: Array<Modifier>;
}

export interface DiceRoll extends DiceRollBase {
  type: 'diceRoll';
}

export interface FinalDie extends DiceRollBase {
  type: 'finalDie';
}

//We will use these Sync actions as a way to update the player UI when its time to do so
export interface LifePointsUpdate {
  type: 'lifeUpdate';
  playerID: number;
  newValue: number;
}

//We will use these Sync actions as a way to update the player UI when its time to do so
export interface CoinsUpdate {
  type: 'coinsUpdate';
  playerId: number;
  newValue: number;
}

export interface TurnStart {
  type: 'turnStart';
  turnsPlayerId: string;
}

export interface SyncMessage {
  type: 'syncMessage';
}

export interface PhaseEnded {
  type: 'phaseEnded';
  phaseName: string;
}

//Screen Overlay - This would be used for actions such as stun, taking damage, etc
export interface ActionScreen {
  type: 'actionScreen';
  subtype: string;
  message: string;
  playerID: number;
  // If no affected players are passed, this means the action is for the player itself
  affectedPlayerIDs: Array<string>;
}

export interface MoveAction {
  type: 'moveAction';
  moveActionDecision: boolean;
  subtype: string;
  message: string;
  playerID: number;
  // If no affected players are passed, this means the action is for the player itself
  affectedPlayerIDs: Array<string>;
}

//Screen Overlay - After a controller action is made (eg. Who do you want to tow ?)
export interface MoveScreen {
  type: 'moveScreen';
  subtype: string;
  message: string;
  playerID: number;
  //If no affected players are passed, this means the action is for the player itself
  affectedPlayerIDs: Array<string>;
}

//This would be a pop up over the players in the racing phase
export interface FloatingAction {
  type: 'floatingAction';
  subtype: string;
  playerID: number;
  message: string;
  affectedPlayerIDs: Array<string>;
  amount: number;
}

//This would indicate actions/changes on a tile in the map
export interface TileAction {
  type: 'tileAction';
  subtype: string;
  playerID: number;
  message: string;
  affectedTile: number;
}

export interface ScoreUpdate {
  playerID: string;
  score: number;
  reason: string;
  category: ScoreUpdateCategory;
  cardId?: string;
}

export enum ScoreUpdateCategory {
  Race = 'Race',
  Bet = 'Bet',
  Card = 'Card',
}

export interface AddScoreData {
  cardId?: string;
  reason?: string;
}
